var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "callActivity-Item-v2-container itsSMS",
    class: _vm.call.sms_type == 'inbound' ? 'Left' : 'Right'
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_vm.call.sms_type == 'inbound' ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Recieved SMS',
      expression: "'Recieved SMS'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-message-icon",
      "height": "55.922",
      "width": "55.922"
    }
  }) : _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Send SMS',
      expression: "'Send SMS'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": "CallActivityV2-message-icon",
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_vm.call.sms_type == 'custom' ? _c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.user)))]) : _vm._e(), _vm.call.sms_type == 'inbound' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.user)))]), !_vm.getIsMobile ? _c('div', {
    staticClass: "via-text"
  }, [_vm._v("to "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.receiver)))])]) : _vm._e()] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.receiver)))]), !_vm.getIsMobile ? _c('div', {
    staticClass: "via-text"
  }, [_vm._v("via "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.user)))])]) : _vm._e()]], 2), _vm._m(0), _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.conditions.actions ? [_vm.type != 'todo' ? [_c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Audio call",
      "icon": "call-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('call', {
          type: 'sip',
          dialable: _vm.call.dialable
        });
      }
    }
  })], 1)] : _vm._e(), _vm.type != 'todo' ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callInfo-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Message info"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('info');
      }
    }
  })], 1) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.call.date, 'h:mm a', {
    unix: true
  })))]), _vm.conditions.assign && _vm.type != 'todo' ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('assign');
      }
    }
  }, [_vm._v("Assign")]) : _vm._e(), _vm._t("more", function () {
    return [_vm.conditions.actions ? _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "menu-class": "w-fit-content-imp"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "variant": "#393939",
              "font-scale": "1.4"
            }
          })];
        },
        proxy: true
      }], null, false, 764454405)
    }, [[_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('call', {
            type: 'sip',
            dialable: _vm.call.dialable
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call")])], 1)], _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('info');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Message info")])], 1)], 2) : _vm._e()];
  })], 2)])]), _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  }, [_vm.conditions.message ? _c('span', {
    staticClass: "dialer-call-prefix messageText"
  }, [_vm._v(_vm._s(_vm.call.body || ""))]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  })]);

}]

export { render, staticRenderFns }