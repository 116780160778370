var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_c('div', {
    class: _vm.$store.getters.getIsMobile ? 'mobileCallContainer updatedNow VideoCallVersion4' : `dialer-videoCall w-100 d-flex ${_vm.GET_LOCAL_SETTING_DASHBOARD.design.flip ? 'flex-row-reverse' : ''}`
  }, [_vm.$store.getters.getIsMobile && _vm.isIncomingCallBanner && !_vm.showCallsList ? _c('div', {
    staticClass: "mobile2ndIncommingCall-identification",
    on: {
      "click": function ($event) {
        _vm.showCallsList = true;
      }
    }
  }, [_vm._v(" Incoming Calls "), _c('b-icon', {
    attrs: {
      "icon": "circle-fill",
      "variant": "success"
    }
  })], 1) : _vm._e(), [_vm._l(_vm.$store.state.calls.videoCalls, function (videoCall, index) {
    return _c('JitsiVideoCall', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.activeCall && _vm.activeSession && _vm.activeSession.room == videoCall.room && (_vm.$store.getters.getIsMobile ? !_vm.showCallsList : true),
        expression: "activeCall && activeSession && activeSession.room==videoCall.room && ($store.getters.getIsMobile?!showCallsList:true)"
      }],
      key: videoCall.room,
      attrs: {
        "listIndex": index,
        "videoCall": videoCall
      },
      on: {
        "to-dashboard": function ($event) {
          _vm.$store.state.global_conditions.tab = 'homeCopy';
        },
        "calls-list": function ($event) {
          _vm.showCallsList = true;
        },
        "toggleIsPlay": function ($event) {
          return _vm.videoCallToggleIsPlay(videoCall);
        },
        "onEnd": function ($event) {
          return _vm.onCallEnd(videoCall);
        }
      }
    });
  }), !_vm.activeCall && _vm.activeSession && _vm.activeSession.room && (_vm.$store.getters.getIsMobile ? !_vm.showCallsList : true) ? _c('IncomingCalls', {
    attrs: {
      "data": _vm.activeSession,
      "isMobileMain": true
    },
    on: {
      "join": function ($event) {
        return _vm.joinRoomNow($event);
      },
      "calls-list": function ($event) {
        _vm.showCallsList = true;
      },
      "to-dashboard": function ($event) {
        _vm.$store.state.global_conditions.tab = 'homeCopy';
      }
    }
  }) : _vm._e(), _vm.activeSession && !_vm.$store.getters.getIsMobile && _vm.activeSession.is_sip == true ? _c('DialPanel', {
    style: `width:${_vm.GET_LOCAL_SETTING_DASHBOARD.design.width}%`,
    attrs: {
      "isCallScreen": true
    }
  }) : _vm._e(), _vm.activeSession && (_vm.$store.getters.getIsMobile ? !_vm.showCallsList : true) && _vm.activeSession.is_sip == true ? _c('SipSessions', {
    style: `width:calc(99% - ${_vm.GET_LOCAL_SETTING_DASHBOARD.design.width}%)`,
    attrs: {
      "session": _vm.activeSession,
      "phones": _vm.$store.state.sip.phone
    },
    on: {
      "to-dashboard": function ($event) {
        _vm.$store.state.global_conditions.tab = 'homeCopy';
      },
      "calls-list": function ($event) {
        _vm.showCallsList = true;
      }
    }
  }) : _vm._e()], _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showCallsList && _vm.$store.getters.getIsMobile,
      expression: "showCallsList && $store.getters.getIsMobile"
    }],
    staticClass: "current-calls-container"
  }, [_c('div', {
    staticClass: "current-calls-top"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.3"
    },
    on: {
      "click": function ($event) {
        _vm.showCallsList = false;
      }
    }
  }), _c('h6', {
    staticClass: "current-calls-heading"
  }, [_vm._v("Current Calls")])], 1), _c('div', {
    staticClass: "current-calls-container-inner"
  }, [_vm._l(_vm.sipsessions, function (session) {
    return _c('SipSessionCallsList', {
      key: session.uuid,
      attrs: {
        "session": session,
        "phones": _vm.phones
      },
      on: {
        "call-play": function ($event) {
          _vm.playCall(session);
          _vm.showCallsList = false;
        },
        "call-list-hide": function ($event) {
          _vm.showCallsList = false;
        },
        "on-play": function ($event) {
          _vm.showCallsList = false;
        }
      }
    });
  }), _vm.$store.getters.getIsMobile ? _vm._l(_vm.$store.state.calls.incoming_calls, function (incoming_call) {
    return _c('IncomingCalls', {
      key: incoming_call.room,
      attrs: {
        "data": incoming_call
      },
      on: {
        "call-play": function ($event) {
          _vm.$store.state.calls.active_incoming_call = incoming_call.room;
          _vm.showCallsList = false;
        },
        "join": function ($event) {
          return _vm.joinRoomNow($event);
        },
        "on-play": function ($event) {
          _vm.showCallsList = false;
        }
      }
    });
  }) : _vm._e(), _vm._l(_vm.$store.state.calls.videoCalls, function (videoCall) {
    return _c('JitsiCalls', {
      key: videoCall.room,
      attrs: {
        "videoCall": videoCall
      },
      on: {
        "call-play": function ($event) {
          _vm.playCall(videoCall);
          _vm.showCallsList = false;
        },
        "on-play": function ($event) {
          _vm.showCallsList = false;
        }
      }
    });
  })], 2)])], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }