<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-integration-products">
      <div class="settingsFirstSection">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">My integrations</h2>
          </div>
          <div>
            <button 
              :disabled="!getUserPermissions.integrations"
              v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
              class="ml-auto newDoneButton"
            >
              <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <span>Submit app</span>
              </span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            My integrations
            <b-icon 
              icon="info-circle-fill" 
              @click="$modal.show('KbInfoModal',{ kb: 'integrations' })" 
              class="cursor_pointer infoKbIcon md-mar-left" 
            />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <button 
                :disabled="!getUserPermissions.integrations"
                v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
                class="ml-auto newDoneButton"
              >
                <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <span>Submit app</span>
                </span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Manage and configure your integrations with third party applications like your favorite CRMs.</span>
      </div>
      <div class="TabsDesignWithIcon-container mt-20px">
        <div 
          class="TabsDesignWithIcon-item mwd-200px" 
          v-for="tab in tabs" 
          :key="tab.id" 
          @click="selected.tab = tab.value" 
          :class="{
            'active': selected.tab == tab.value
          }"
        >
          <vb-icon :icon="tab.icon" height="38px" width="38px" />
          <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
        </div>
      </div>
      <div class="dialer-box alt mt-20px">
        <div class="integrationBox-container">
          <div v-for="(data, index) in integrations" :key="index" class="integrationBox" :class="{'comingSoon':data.comming_son}">
            <div class="nameImg-container">
              <vb-icon :icon="data | get_property('plateforms') | integrationIcons" class="integrationType-icon" />
              <div class="integrationType-name">{{ data | get_property('plateforms') | integrationName }}</div>
            </div>
            <div class="description">{{ data | get_property('plateforms') | integrationDesc }}</div>
            <div v-if="response.integrateds[data.plateforms]">
              <b-checkbox 
                v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
                :disabled="!getUserPermissions.integrations"
                :checked="!!response.integrateds[data.plateforms].up_sync_enable" 
                @change="changeSync(data.plateforms)"
              >
                Up sync
              </b-checkbox>
              <b-checkbox 
                v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
                :disabled="!getUserPermissions.integrations"
                :checked="!!response.integrateds[data.plateforms].down_sync_enable" 
                @change="changeSync(data.plateforms,'down')"
              >
                Down sync
              </b-checkbox>
              <b-checkbox 
                v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
                :disabled="!getUserPermissions.integrations"
                :checked="!!response.integrateds[data.plateforms].cdr_sync_enable" 
                @change="changeSync(data.plateforms,'cdr')"
              >
                CDR
              </b-checkbox>
            </div>
            <div class="checkInstalled-container">
              <div class="companyType">{{ data | get_property('plateforms') | integrationType }}</div>
              <vb-icon 
                v-b-tooltip :title="getUserPermissions.integrations?'':'Your package does not support this feature'"
                @click="!getUserPermissions.integrations ? '' : response.integrateds[data.plateforms] ? deleteintegrate(data.plateforms) : createLink(data)" 
                :icon="response.integrateds[data.plateforms]?'integrationInstalled-icon':'integrationAdd-icon'" 
                class="integrationAdded-icon" 
              />
            </div>
          </div>
          <template v-if="api.list.send">
            <div v-for="n in 6" :key="n.id" class="integrationBox">
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign mr-3" style="height:43px;width:43px;"></div>
                <div class="latestShimmerDesign" style="height:26px;width:66px"></div>
              </div>
              <div class="d-flex w-100 flex-column mt-4" style="min-height: 95px;">
                <div class="latestShimmerDesign mt-1" style="height:16px;width:100%"></div>
                <div class="latestShimmerDesign mt-1" style="height:16px;width:100%"></div>
                <div class="latestShimmerDesign mt-1" style="height:16px;width:100%"></div>
              </div>
              <div class="d-flex w-100 flex-column justify-content-start">
                <div class="d-flex align-items-center py-2">
                  <div class="latestShimmerDesign mr-3" style="height:16px;width:16px;border-radius:50%;"></div>
                  <div class="latestShimmerDesign" style="height:22px;width:70px"></div>
                </div>
                <div class="d-flex align-items-center py-2">
                  <div class="latestShimmerDesign mr-3" style="height:16px;width:16px;border-radius:50%;"></div>
                  <div class="latestShimmerDesign" style="height:22px;width:70px"></div>
                </div>
                <div class="d-flex align-items-center py-2">
                  <div class="latestShimmerDesign mr-3" style="height:16px;width:16px;border-radius:50%;"></div>
                  <div class="latestShimmerDesign" style="height:22px;width:70px"></div>
                </div>
              </div>
              <div class="d-flex align-items-center mt-3 justify-content-between w-100">
                <div class="latestShimmerDesign" style="height:19px;width:30px"></div>
                <div class="latestShimmerDesign" style="height:38px;width:38px;border-radius:50%;"></div>
              </div>
            </div>
          </template>
        </div>
        <div class="emptyScreenContainer" v-if="integrations.length==0 && api.list.send==false">
          <img 
            v-if="GET_LOCAL_SETTING_IS_DARK_MODE" 
            width="579px" 
            height="424px" 
            :src="require('@/assets/images/emptyScreenImages/darkMode/integrationsEmptyScreenImage.png')"
          />
          <img 
            v-else 
            width="579px" 
            height="424px" 
            :src="require('@/assets/images/emptyScreenImages/integrationsEmptyScreenImage.png')"
          />
          <div class="emptyScreenContainer-heading">Supercharge your workflow seamless integrations await!</div>
          <div class="emptyScreenContainer-text w-75">Connect with your favorite tools and elevate your VoIP experience. It's just a click away!</div>
          <button @click="''" type="button" class="dialer-button dialer-button-primary mt-20px">Explore now</button>
        </div>
      </div>
    </section>
    <modal :name="`${_uid}-integrate`" @before-close="selected.platform={}" class="integrateIntegrationModal">
      <div class="dialer-edit-header pt-0 w-100 d-block">
        <div class="d-flex alingn-items-center justify-content-between w-100">
          <h2 class="dialer-edit-title newer my-0">Integration</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${_uid}-integrate`)" />
            <a v-else class="newCloseButton" @click="$modal.hide(`${_uid}-integrate`)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text">Enter the required information below.</div>
      </div>
      <form @submit.prevent="integrate()">
        <div class="latestGreyBox-9-9-2023">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Enter API key</label>
            <b-input :disabled="api.integrate.send" v-model="forms.integrate.api_key" placeholder="Enter API key" />
            <p class="text text-danger animated bounceIntop mb-0" v-if="$v.forms.integrate.api_key.$invalid">
              <span v-if="!$v.forms.integrate.api_key.required">API Key is required</span>
            </p>
            <template v-if="selected.platform.plateforms=='copper'">
              <label class="mt-20px">Enter email</label>
              <b-input :disabled="api.integrate.send" v-if="selected.platform.plateforms=='copper'" v-model="forms.integrate.email" placeholder="Enter email" />
              <p class="text text-danger animated bounceIntop mb-0" v-if="$v.forms.integrate.email.$invalid">
                <span v-if="!$v.forms.integrate.email.required">Email is required</span>
                <span v-else-if="!$v.forms.integrate.email.email">Email is in valid</span>
              </p>
            </template>
          </div>
        </div>
        <button class="fullWidthDoneButton mt-20px" :disabled="api.integrate.send">Submit</button>
      </form>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import required from 'vuelidate/lib/validators/required';
import email from 'vuelidate/lib/validators/email';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
const integrations = {
  hubspot: {
    name: 'HubSpot',
    icon: 'hubSpot-icon',
    desc: `V2 - HubSpot is an inbound marketing and sales platform that helps companies attract visitors, convert leads, and close customers.`,
    type: 'CRM',
    value: 'hubspot',
  },
  zoho: {
    name: 'ZOHO',
    icon: 'zoho-icon',
    desc: `End-to-end, fully customizable CRM solution for growing businesses and enterprises.`,
    type: 'CRM',
    value: 'zoho',
  },
  salesforce: {
    name: 'Sales Force',
    icon: 'salesForce-icon',
    desc: `Integrate all your phone calls & phone numbers into salesforce cloud. Get an integrated dialer & have your phone calls/text messages logged at the right place.`,
    type: 'CRM',
    value: 'salesforce',
  },
  copper: {
    name: 'Copper',
    icon: 'copper-icon',
    desc: `Copper organizes all your emails, calls, files, and notes for every contact—so you have a full view of that relationship history.`,
    type: 'CRM',
    value: 'copper',
  },
  pipedrive: {
    name: 'Pipedrive',
    icon: 'pipedrive-icon',
    desc: `Sync your pipedrive contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'pipedrive',
  },
  freshdesk: {
    name: 'Freshdesk',
    icon: 'freahdesk-icon',
    desc: `Sync your freshdesk contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'freshdesk',
  },
  intercom: {
    name: 'Intercom',
    icon: 'intercom-icon',
    desc: `Sync your intercom contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'intercom',
  },
  monday: {
    name: 'Monday',
    icon: 'monday-com-icon',
    desc: `Sync your monday contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'monday',
  },
}
const tabs = {
  integrated: {
    text: 'My integrations',
    value: 'integrated',
    icon: 'squared-myIntegration-icon'
  },
  not_integrated: {
    text: 'Discover integrations',
    value: 'not_integrated',
    icon: 'squared-discoverIntegration-icon'
  },
}
export default {
  name: "Integrations",
  inject: [
    'appNotify',
  ],
  data() {
    return {
      response: {
        integrations: [],
        integrateds: {},
      },
      api: {
        list: this.$helperFunction.apiInstance(),
        create_link: this.$helperFunction.apiInstance(),
        integrate: this.$helperFunction.apiInstance(),
        delete: this.$helperFunction.apiInstance(),
        sync: this.$helperFunction.apiInstance(),
      },
      selected: {
        tab: tabs.integrated.value,
        platform: {},
      },
      forms: {
        integrate: this.$helperFunction.formInstance({
          data: {
            api_key: "",
            email: "",
          }
        })
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE,
      'getUserPermissions'
    ]),
    tabs(){ return tabs },
    integrations(){
      if(this.selected.tab==tabs.not_integrated.value) {
        return this.response.integrations?.filter?.(i=>!this.response.integrateds[i.plateforms]) ?? []
      } else {
        return this.response.integrations?.filter?.(i=>!!this.response.integrateds[i.plateforms]) ?? []
      }
    },
  },
  validations: {
    forms: {
      integrate: {
        api_key: {
          required: required
        },
        email: {
          required: requiredIf(function(){
            return [
              integrations.copper.value,
            ].includes(this.selected.platform?.plateforms)
          }),
          email: email,
        },
      },
    },
  },
  filters: {
    integrationIcons(value){return integrations[value]?.icon ?? ''},
    integrationDesc(value){return integrations[value]?.desc ?? ''},
    integrationType(value){return integrations[value]?.type ?? ''},
    integrationName(value){return integrations[value]?.name ?? value ?? ''},
  },
  methods: {
    async fetchIntegrations(){
      if(this.api.list.send) return;
      try {
        this.api.list.send=true
        const { data: integrations } = await VOIP_API.endpoints.integrations.list({
          accountcode: this.getCurrentUser.account
        })
        const { data: integrated } = await VOIP_API.endpoints.integrations.integratedlist({
          accountcode: this.getCurrentUser.account
        })
        this.response.integrations=integrations
        this.response.integrateds=_.keyBy(integrated ?? [], 'platform');
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.list.send=false
      }
    },
    async integrate(){
      if(this.api.integrate.send) return;
      this.$v.forms.integrate.$touch()
      if(this.$v.forms.integrate.$invalid) return;
      try {
        this.api.integrate.send=true
        await VOIP_API.endpoints.integrations.installIntegration({
          accountcode: this.getCurrentUser.account,
          integrationId: this.selected.platform.id,
          plateforms: this.selected.platform.plateforms,
          api_key: this.forms.integrate.api_key,
          email: this.forms.integrate.email,
        })
        this.$modal.hide(`${this._uid}-integrate`)
        this.fetchIntegrations()
        this.forms.integrate.reset()
        this.selected.platform={}
        this.appNotify({
          message: 'Successfully Updated',
          type: 'danger',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.integrate.send=false
      }
    },
    async createLink(data){
      if(this.api.create_link.send) return;
      try {
        this.api.create_link.send=true
        if(data.api_type!='Apikey') {
          const { data: { url } } = await VOIP_API.endpoints.integrations.installIntegration({
            accountcode: this.getCurrentUser.account,
            integrationId: data.id,
          })
          if(!url) return;
          window.open(url,'_blank');
        } else {
          this.selected.platform=data
          this.$modal.show(`${this._uid}-integrate`)
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.create_link.send=false
      }
    },
    async deleteintegrate(integration=''){
      if(this.api.delete.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this ${integrations[integration]?.name || integration || ''} integration`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.delete.send=true
        await VOIP_API.endpoints.integrations.deleteintegration({
          accountcode: this.getCurrentUser.account,
          integration: integration,
        })
        this.fetchIntegrations()
        this.appNotify({
          message: 'Successfully Deleted!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.delete.send=false
      }
    },
    async changeSync(integration,direction='up'){
      if(this.api.sync.send) return;
      try {
        this.api.sync.send=true
        await VOIP_API.endpoints.integrations.changeSync({
          accountcode: this.getCurrentUser.account,
          integration,
          direction,
        })
        this.fetchIntegrations()
        if(this.response.integrateds[integration]){
          if(direction=='up'){
            this.response.integrateds[integration].up_sync_enable=~~!this.response.integrateds[integration].up_sync_enable
          } else if(direction=='cdr'){
            this.response.integrateds[integration].cdr_sync_enable=~~!this.response.integrateds[integration].cdr_sync_enable
          } else {
            this.response.integrateds[integration].down_sync_enable=~~!this.response.integrateds[integration].down_sync_enable
          }
        }
        this.appNotify({
          message: 'Successfully sync',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.sync.send=false
      }
    },
  },
  activated() {
    this.fetchIntegrations();
  },
};
</script>