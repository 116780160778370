<template>
  <div 
    class="callActivity-Item-v2-container"
    :class="[
      call.status=='Dialed' ? 'Right' : 'Left',
      {
        'missedCall': call.status=='Missed',
        'recievedCall': call.status=='Received',
        'dialledCall': call.status=='Dialed',
      }
    ]" 
  >
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-if="call.status=='Missed'" v-b-popover.hover.top="'Missed Audio Call'" icon="CallActivityV2-missedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else-if="call.status=='Received'" v-b-popover.hover.top="'Received Audio Call'" icon="CallActivityV2-receivedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else v-b-popover.hover.top="'Dialed Audio Call'" icon="CallActivityV2-dialledCall-icon" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <small v-if="call.is_out_of_hours">Business hour</small>
        <template v-if="call.status=='Dialed'">
          <template v-if="call.dst_type=='Number'">
            <div class="user-name">{{ call.dst_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ call.dst_name }}</div>
            <div class="user-number">{{ call.dst_extn }}</div>
          </template>
          <div v-if="!getIsMobile" class="via-text">
            <template v-if="call.dst_type=='Number'">via <b>{{call.src_number | number_formater}}</b></template>
            <!-- <template v-else>{{call.src_name}}</template> -->
            <!-- {{call.src_name}} -->
          </div>
        </template>
        <template v-else>
          <template v-if="call.src_type=='Number'">
            <div class="user-name">{{ call.src_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ call.src_name }}</div>
            <div class="user-number">{{ call.src_extn }}</div>
          </template>
          <div v-if="!getIsMobile" class="via-text">
            <template v-if="call.dst_type=='Number'">{{call.dst_number | number_formater}}</template>
            <template v-else-if="call.dst_number">called <b>{{call.dst_number | number_formater}} {{call.dst_name ? `(${call.dst_name})` : ''}}</b></template>
            <template v-else>{{call.dst_name}}</template>
          </div>
        </template>
      </div>
      <div class="middle-section">
        <span class="middle-section-text"></span>
        <vb-audio-player :design="'minimum'" v-if="type=='history' && call.soundFile" :src="call.soundFile" />
      </div>
      <div v-if="conditions.actions" class="callLogIcons-container">
        <!-- call -->
        <span v-if="!assignedData" class="mr-8px">
          <vb-icon v-b-tooltip.hover title="Audio call" icon="call-callLog-icon" width="35px" height="35px" @click="$emit('call', { type: 'sip', dialable: dialable })"/>
        </span>
        <!-- call info -->
        <span class="mr-8px" v-if="!assignedData">
          <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px"
            v-if="!assignedData" @click="$emit('info')"
            v-b-tooltip.hover :title="`Call info`"
          />
        </span>
        <!-- add contacts -->
        <span class="mr-8px" v-if="!assignedData && call.status=='Dialed' ? call.dst_type=='Number' && !call.dst_name : call.src_type=='Number' && !call.src_name" >
          <vb-icon v-b-tooltip.hover title="Add to contacts" icon="addContact-callLog-icon" width="35px" height="35px"
            @click="$store.state.global_conditions.add_addressbooks = call.status=='Dialed' ? call.dst_number : call.src_number"
          />
        </span>
        <!-- assign task -->
        <span class="mr-8px" v-if="call.linked_tasks && call.linked_tasks.length==0">
          <vb-icon icon="assignTask-callLog-icon" width="35px" height="35px"
            v-b-tooltip.hover title="Add to tasks"
            @click="addTodoTask()"
          />
        </span>
        <!-- recording -->
        <span v-if="call.soundFile">
          <vb-icon @click="$emit('play-audio',{ soundFile: call.soundFile })" icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
        </span>
        <!-- ai transcription -->
        <span v-if="call.soundFile">
          <vb-icon class="aiTranscript"  icon="ai-audio-icon" width="21.507px" height="23.377px" @click="$emit('transcription')" v-b-tooltip.hover title="AI transcript" />
        </span>
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'h:mm a' }) }}</span>
          <b-button v-if="conditions.assign" @click="$emit('assign')">
            Assign
          </b-button>
          <slot name="more">
            <b-dropdown v-if="conditions.actions" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template #button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <b-dropdown-item 
                v-if="!assignedData && call.status=='Dialed' ? call.dst_type=='Number' && !call.dst_name : call.src_type=='Number' && !call.src_name" 
                @click="$store.state.global_conditions.add_addressbooks = call.status=='Dialed' ? call.dst_number : call.src_number"
              >
                <vb-icon icon="dialer-addToContact-icon" class="dialer-addToContact-icon" width="16.245" height="15.004" />
                <span class="ml-3">Add contact</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!assignedData" @click="$emit('call', { type: 'sip', dialable: dialable })">
                <b-icon icon="telephone" font-scale="0.96"/>
                <span class="ml-3">Call</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!assignedData" @click="$emit('info')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Call info</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="assignedData && call.status=='Missed'" @click="$emit('assigned')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Assigned detail</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="call.linked_tasks && call.linked_tasks.length==0" @click="addTodoTask()">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Add to tasks</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
        <div class="duration">{{ call.bill_sec }}</div>
      </div>
    </div>
    <div class="callActivity-Item-v2-inner-2nd">
      <!-- tag -->
      <div v-if="conditions.tag" class="tags-container">
        <div v-for="tag in tags" :key="tag.id" class="dialer-call-prefix tag">
          <small v-vb-tag-color="tag.tag.colour">
            <span v-vb-tag-color.text="tag.tag.text_color">{{ tag.tag.tag }}</span>
          </small>
        </div>
      </div>
      <!-- note -->
      <span v-if="conditions.note && note" class="dialer-call-prefix note">{{ note }}</span>
    </div>
    
    <p v-if="GET_LOCAL_SETTING_DASHBOARD.design.transfer_log" class="extraInfoInCallLogs mb-0 w-100 ">
      <!-- :id="`popover-${call.callerid}`" -->
      <template v-if="call.forward_from_type || call.forward_to_type">
        <!-- forward -->
        <vb-icon 
          icon="callLogs-forward-icon"
          v-b-popover.hover="'Forward'" 
          width="14.61px" height="15.595px"
        />
        <div v-if="call.forward_from_type" class="d-flex align-items-center">
          <div>from</div>
          <div v-if="call.forward_from_type == 'Number'"><b>{{ call.forward_from_number | number_formater }}</b></div>
          <div v-else><b>{{ call.forward_from_name }}</b></div>
        </div>
        <template v-if="call.forward_to_type">
          <span>to</span>
          <div v-if="call.forward_to_type == 'Number'"><b>{{ call.forward_to_number | number_formater }}</b></div>
          <div v-else><b>{{ call.forward_to_name }}</b></div>
        </template>
      </template>
      <template v-if="call.answered_extn_type">
        answered by: <b>{{call.answered_extn_user_name}}</b>
        <vb-icon 
          :icon="call.answered_extn_type | extensionIcon"
          :class="call.answered_extn_type | extensionIcon" 
          v-b-popover.hover="call.answered_extn_name" 
          width="25px" 
          height="25px" 
        />
      </template>
      <template v-if="call.transfer_to_type">
        <!-- transfer -->
        <vb-icon 
          icon="callLogs-transfer-icon"
          v-b-popover.hover="'Transfer'" 
          width="16.448px" height="15.595px"
        />
        <span>to</span>
        <span v-if="call.transfer_to_type=='Number'"><b>{{ call.transfer_to_number | number_formater }}</b></span>
        <span v-else><b>{{ call.transfer_to_name }}</b></span>
      </template>
    </p>
    <p class="extraInfoInCallLogs mb-0 w-100" v-if="call.linked_tasks && call.linked_tasks.length>1">
      <vb-icon 
        icon="callLogs-task-icon"
        v-b-popover.hover="'Task'" 
        width="18.1px" height="18.11px"
        class="mr-2"
      />
      {{call.linked_tasks.length}} tasks assigned
    </p>
    <p v-else-if="call.linked_tasks && call.linked_tasks.length==1 && call.linked_tasks[0].task_assigned" 
      v-b-tooltip 
      :title="`${call.linked_tasks[0].task_assigned.spaces.prefix}#${call.linked_tasks[0].task_assigned.id}`" 
      @click="!assignedData && conditions.actions ? $emit('info',{ task_modal: true }) : ''"
      class="extraInfoInCallLogs forTasks mt-1 mb-0 w-100"
    >
      <vb-icon 
        icon="callLogs-task-icon"
        v-b-popover.hover="'Task'" 
        width="18.1px" height="18.11px"
        class="mr-2"
      />
      <template>
        <span class="latestBlueColor">{{call.linked_tasks[0].task_assigned.title}}</span>
      </template>
      <span v-if="call.linked_tasks[0].task_assigned.due_date">Due: {{call.linked_tasks[0].task_assigned.due_date}}</span>
      <template>
        <div 
          v-if="call.linked_tasks[0].task_assigned.status" 
          class="categoryText mr-16px ml-4" 
          :style="`background-color: ${call.linked_tasks[0].task_assigned.status.color};`"
        >
          {{call.linked_tasks[0].task_assigned.status.title}}
        </div>
        <div v-if="call.linked_tasks[0].task_assigned.is_important==1" class="categoryText mx-2 px-1" :style="`background-color: #F2F2F2;`">
          <b-icon icon="exclamation-lg" variant="danger" />
        </div>
      </template>
      <template v-if="call.linked_tasks[0].task_assigned.assigned.length!=0">
        <vb-icon icon="callLogs-task-assignedTo-icon" width="11.851px" height="14.227px" />
        Assigned to <Info :id="call.linked_tasks[0].task_assigned.assigned[0].assigned_to" :only_name="true" />
      </template>
    </p>
    <!-- <p class="extraInfoInCallLogs callTranscriptText mb-0 w-100" v-if="call.transcription_summary && call.transcription_summary.recording_text">
      <span style="font-weight: 800;">Call transcript :</span> 
      {{ call.transcription_summary.recording_text }}
    </p> -->
    <p class="extraInfoInCallLogs callTranscriptText mb-0 w-100" v-if="call.call_recording && call.call_recording.recording_text">
      <span style="font-weight: 800;">Call transcript :</span> 
      {{ call.call_recording.recording_text }}
    </p>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import { mapGetters } from 'vuex';
import { number_formater } from '@/filter';
import Info from '@/components/Lists/Info.vue';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
export default {
  name: "SipCallLog",
  components: { 
    Info 
  },
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      api: {
        add_todo: this.$helperFunction.apiInstance({}),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
    note(){ return this.call.tags_notes?.find?.(i=>i.log!=null && i.type=="note")?.log ?? ''; },
    tags(){ return this.call.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag && i.tag.deletedtags<1) ?? [] },
    tagsId(){ return this.call.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag)?.map?.(tag=>tag.tag.id) ?? []; },
    callID(){ return this.call?.call_id; },
    dialable(){
      let type=this.call.src_type, number=this.call.src_number, extn=this.call.src_extn 
      if(this.call.call_direction=='outbound') type=this.call.dst_type, number=this.call.dst_number, extn=this.call.dst_extn
      if(type=='Number') return number
      else return extn
    },
  },
  filters: {
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      else if(value=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(value=='EXTERNALEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
  },
  methods: {
    async addTodoTask(){
      let title = ''
      if(this.call.status=='Dialed') {
        if(this.call.dst_type=='Number') title=number_formater(this.call.dst_number)
        else title=this.call.dst_name
      } else {
        if(this.call.src_type=='Number') title=number_formater(this.call.src_number)
        else title=this.call.src_name
      }
      if(this.api.add_todo.send) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.call.status=='Missed') type='missed'
        else if(this.call.status=='Received') type='incoming'
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: title,
          cdr_id: this.callID,
          type: type
        })
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
  },
};
</script>

<style>
</style>