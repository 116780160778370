<template>
  <div class="dialer-flex alt">
    <template >
      <template v-if="!getIsMobile">
        <div class="d-flex flex-column w-100">
          <div class="d-flex align-items-center justify-content-between">
            <div class="dialer-incommingVideoCall-info d-flex align-items-center">
              <div class="dialer-user-dp mr-2">
                <img width="40" height="40" class="rounded" :src="callObjInfo.image" @error="$event.target.src = userDp" />
              </div>
              <div class="dialer-user-bio">
                <h6 class="text-capitalize mb-0">{{ callObjInfo.name }}</h6>
                <p class="text-capitalize mb-0">{{ callObjInfo.sub_info }}</p>
                <div class="d-flex align-items-center">
                  <p class="m-0 pr-2">Time Left: {{ data.time | time_audio }}</p>
                  <b-icon icon="camera-video" />
                </div>
              </div>
            </div>
            <div class="d-flex">
              <span class="md-mar-left">
                <vb-icon class="cursor_pointer" @click="data.accept('video')" icon="dialer-videoInBox-icon" width="40" height="40" />
              </span>
              <span class="md-mar-left">
                <vb-icon @click="data.accept('audio')" class="cursor_pointer" icon="dialer-callAcceptInbox-icon" width="40" height="40" />
              </span>
              <span class="md-mar-left">
                <vb-icon class="cursor_pointer" @click="data.reject()" icon="dialer-callRejectInbox-icon" width="40" height="40" />
              </span>
            </div>
          </div>
          <slot name="switchCalls" />
        </div>
      </template>
      <template v-if="getIsMobile">
        <template v-if="isMobileMain">
          <div class="dialer-incommingVideoCall-info v2 d-flex align-items-center justify-content-between">
            <div class="incommingCall-topBar">
              <!-- top -->
              <span class="w-100 text-center">Incoming Call ...</span>
              <div class="d-flex align-items-center">
                <b-icon icon="chevron-left" font-scale="1.3" class="settings-back-icon cursor_pointer" style="" @click="$emit('to-dashboard')" />
                <!-- <vb-icon icon="video-call-chat-icon" class="cursor_pointer icon_msg_top_bar" width="57" height="57" /> -->
                <div class="dialer-user-bio">
                  <h6 class="text-capitalize mb-0">{{ callObjInfo.name }}</h6>
                  <p class="text-capitalize mb-0">{{ callObjInfo.sub_info }}</p>
                  <div class="d-flex align-items-center">
                    <p class="m-0 pr-2">Time Left: {{ data.time | time_audio }}</p>
                    <b-icon icon="camera-video" />
                  </div>
                </div>
                <vb-icon icon="mobile-duringCall-switchCall-icon" @click="$emit('calls-list')" width="19.461" height="19.492"/>
              </div>
            </div>
            <div class="incommingCall-middleBar">
              <!-- middle -->
              <div class="dialer-user-dp">
                <img width="40" height="40" class="rounded" :src="callObjInfo.image" @error="$event.target.src = userDp" />
              </div>
            </div>
            <div class="incommingCall-bottomBar">
              <div class="d-flex px-3 w-100 justify-content-between mb-3">
                <span class="md-mar-right">
                  <vb-icon class="cursor_pointer" @click="data.accept('video')" icon="video-IncomingCall-video-icon" width="57" height="57" />
                </span>
                <span class="md-mar-left">
                  <vb-icon @click="data.accept('audio')" class="cursor_pointer" icon="video-IncomingCall-audio-icon" width="57" height="57" />
                </span>
              </div>
              <span class="w-100 d-flex justify-content-center">
                <vb-icon class="cursor_pointer" @click="data.reject()" icon="video-call-hangupIcon-icon" width="57" height="57" />
              </span>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- call comming -->
          <div class="jitsiIncomming_call_bar">
            <div class="dialer-incommingVideoCall-info d-flex align-items-center">
              <div class="dialer-user-dp mr-2">
                <img width="40" height="40" class="rounded" :src="callObjInfo.image" @error="$event.target.src = userDp" />
              </div>
              <div class="dialer-user-bio">
                <p class="m-0 pr-2 small">Incoming Call...</p>
                <h6 class="text-capitalize mb-0">{{ callObjInfo.name }}</h6>
                <p class="text-capitalize mb-0">{{ callObjInfo.sub_info }}</p>
                <div class="d-flex align-items-center">
                  <p class="m-0 pr-2">Time Left: {{ data.time | time_audio }}</p>
                  <b-icon icon="camera-video" />
                </div>
              </div>
            </div>
            <div class="d-flex jitsiIncomming_call_bar_actions">
              <span class="md-mar-left">
                <vb-icon class="cursor_pointer" @click="data.accept('video')" icon="video-IncomingCall-video-icon" width="40" height="40" />
              </span>
              <span class="md-mar-left">
                <vb-icon @click="data.accept('audio')" class="cursor_pointer" icon="video-IncomingCall-audio-icon" width="40" height="40" />
              </span>
              <span class="md-mar-left">
                <vb-icon class="cursor_pointer" @click="data.reject()" icon="video-call-hangupIcon-icon" width="40" height="40" />
              </span>
              
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
// import { centrifugo } from "../../Centrifuge";
import { permissions, VOIP_API } from "../../utils";
// import _ from "lodash";
// import Timer from "timer.js";
import { electron_events } from '@/electron/events';
import { call_info } from '@/mixin';
import { mapGetters } from 'vuex';
import { AMI_EVENTS } from '@/ami';
import { MOBILE_APP } from '@/mobile-app';
import { MOBILE_APP_EVENTS, MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events';
var timeout = 30;
export default {
  name: "IncomingCalls",
  mixins: [
    call_info
  ],
  inject: [
    'appNotify',
  ],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isMobileMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      display_name: "",
      timer: null,
      time: timeout,
      is_submitted: false,
      userDp: require("@/assets/images/user-male.png"),
    };
  },
  computed: {
    ...mapGetters([
      'isSwitchAbleState',
      'getIsMobile',
      'getCurrentUser',
    ]),
    callObj(){ return this.data },
  },
  methods: {
    //--own--
    async accept(call_type='audio') {
      if(!this.isSwitchAbleState) return;
      if(permissions.mic=='denied'){
        this.appNotify({
          message: 'Allow your microphone permission',
          type: 'danger',
        })
        return;
      }
      if(['listen', 'whisper', 'barge', 'test'].includes(this.$store.state.sip.phone.activeSession?.extra_data?.call_type ?? '')){
        this.$store.state.sip.phone.activeSession.hangup()
      }
      if (this.is_submitted) return;
      this.is_submitted = true;
      const { caller, is_group, room } = this.data;
      // const is_dailer = caller === this.$store.getters.getCurrentUser?.account;//this.current_user.account;
      try {
        const { data: { jwt }, } = await VOIP_API.endpoints.users.publish({
          method: "publish",
          extension_accountcode: this.getCurrentUser?.sip?.user,
          params: {
            channel: `${caller}-in`,
            data: {
              ...this.data,
              type: AMI_EVENTS.ACCEPT,
            },
          },
        });
        this.$emit('join',{
          room,
          jwt,
          data: this.data,
          type: "receiver", // is_dailer ? "dailer" : "receiver" // due to multi groups created in updated_group call in jitsi
          is_group,
          call_type,
        })
        if(this.data.extra_data.is_switch_call) {
          this.$emit('removeSipcall',{
            sip_id: this.data.extra_data.sip_id
          })
        }
        this.removeFromList(false)
      } finally {
        this.is_submitted = false;
      }
    },
    async reject() {
      if (this.is_submitted) return;
      this.is_submitted = true;
      try {
        const { caller } = this.data;
        await VOIP_API.endpoints.users.publish({
          method: "publish",
          params: {
            channel: `${caller}-in`,
            data: {
              ...this.data,
              type: AMI_EVENTS.REJECTED,
            },
          },
        });
        this.removeFromList()
      } finally {
        this.is_submitted = false;
      }
    },
    async busy() {
      const { caller, } = this.data;
      await VOIP_API.endpoints.users.publish({
        method: "publish",
        params: {
          channel: `${caller}-in`,
          data: {
            ...this.data,
            type: AMI_EVENTS.BUSY,
          },
        },
      });
      this.appNotify({
        title: `${this.callObjInfo.name} ${this.callObjInfo.sub_info}`,
        icon: this.callObjInfo.image,
        message: `missed Call`,
        notify_type: 'alert',
        position: 'top-right',
      })
      this.removeFromList()
    },
    async load() {
      const { caller } = this.data;
      await VOIP_API.endpoints.users.publish({
        method: "publish",
        params: {
          channel: `${caller}-in`,
          data: {
            ...this.data,
            type: AMI_EVENTS.PROGRESS,
          },
        },
      });
    },
    removeFromList(restricted=true){
      const { restricted_key_callee, room } = this.data;
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{
          id: room
        })
      }
      if(restricted) this.$delete(this.$store.state.calls.restricted_calls,restricted_key_callee);
      this.$delete(this.$store.state.calls.incoming_calls,room);
    },
    //--events--
    BYE(message) {
      const { room } = this.data;
      const { data: { room: r_room }, } = message;
      if (r_room !== room) return;
      this.removeFromList()
    },
    CANCEL(message) {
      const { room } = this.data;
      const { data: { room: r_room }, } = message;
      // console.log(r_room,room)
      if (r_room !== room) return;
      this.appNotify({
        title: `${this.callObjInfo.name} ${this.callObjInfo.sub_info}`,
        icon: this.callObjInfo.image,
        message: `missed Call`,
        notify_type: 'alert',
        position: 'top-right',
      })
      this.removeFromList()
    },
    ACCEPT_CALL({ room: r_room, type }){
      const { room } = this.data;
      if(r_room!=room) return;
      if(type=='video') this.data.accept('video')
      else this.data.accept('audio')
    },
    REJECT_CALL({ room: r_room }){
      const { room } = this.data;
      if(r_room!=room) return;
      this.data.reject()
    },
  },
  created(){
    MOBILE_APP?.on?.(MOBILE_APP_EVENTS.ACCEPT_CALL,this.ACCEPT_CALL.bind(this))
    MOBILE_APP?.on?.(MOBILE_APP_EVENTS.REJECT_CALL,this.REJECT_CALL.bind(this))
  },
  mounted(){
    MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.SHOW_INCOMING_CALL_SCREEN,{ room: this.data.room })
  },
  beforeDestroy(){
    MOBILE_APP?.off?.(MOBILE_APP_EVENTS.ACCEPT_CALL,this.ACCEPT_CALL.bind(this))
    MOBILE_APP?.off?.(MOBILE_APP_EVENTS.REJECT_CALL,this.REJECT_CALL.bind(this))
  },
  // created() {
  //   centrifugo.calls.on(centrifugo.calls.events.CANCEL,this.CANCEL)
  //   centrifugo.calls.on(centrifugo.calls.events.BYE,this.BYE)
  //   let vm = this;
  //   this.load();
  //   this.timer = new Timer({
  //     tick: 1,
  //     ontick() {
  //       vm.time = vm.time - 1;
  //     },
  //     onend() {
  //       if (vm.timer) {
  //         vm.busy();
  //       }
  //     },
  //   });
  //   if(process.env.IS_ELECTRON){
  //     window.electron.receive(electron_events.recieve.CALL_REJECTED,this.reject)
  //     window.electron.receive(electron_events.recieve.CALL_ACCEPTED,this.accept)
  //   }
  // },
  // mounted() {
  //   console.log('this.isMobileMain',this.isMobileMain)
  //   this.timer.start(timeout);
  //   this.appNotify({
  //     toaster: false,
  //     sound: false,
  //     title: `${this.callObjInfo.name} ${this.callObjInfo.sub_info}`,
  //     icon: this.callObjInfo.image,
  //     message: `Incoming Call`,
  //     notify_type: 'alert',
  //   })
  //   if (!window.document.hasFocus() && process.env.IS_ELECTRON) {
  //     window.electron.send(electron_events.INCOMING_CALL,{
  //       id: this.data.room,
  //       from: `${this.callObjInfo.name} ${this.callObjInfo.sub_info}`,
  //       to: `${this.$store.getters.getCurrentUser?.display_name} ${this.$store.getters.getCurrentUser?.extn}`,
  //     })
  //   }
  // },
  // beforeDestroy(){
  //   centrifugo.calls.off(centrifugo.calls.events.CANCEL,this.CANCEL)
  //   centrifugo.calls.off(centrifugo.calls.events.BYE,this.BYE)
  // },
  // destroyed() {
  //   this.timer = null;
  // },
};
</script>

<style lang="scss" scoped>
</style>