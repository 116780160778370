<template>
<div>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <template v-if="conditions.screen==screens.plan_license">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.plans">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Plan License</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="service in planLicenses" 
              :key="service.product_id" 
              @click="selected.service_id=service.product_id;selected.service_type=serviceTypes.free;conditions.screen=screens.service_detail;"
            >
              <div class="headingInsideTable">{{ service | get_property("product_label") }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight mr-8px">
                  {{ service | get_property("product_total_free_allowed", 0) }}/{{ service | get_property("product_already_used", 0) }}/{{ service | get_property("product_free_remaining", 0) }}
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
            <vb-no-record v-if="planLicenses.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.addon_subscription">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.plans">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Addon Subscription</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.call_packages">
              <div class="headingInsideTable">Call Packages</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
            <div 
              class="basicWhiteIOScard-item" 
              v-for="service in serviceAddons" 
              :key="service.product_id" 
              @click="selected.service_id=service.product_id;selected.service_type=serviceTypes.addon;conditions.screen=screens.service_detail;"
            >
              <div class="headingInsideTable">{{ service | get_property("label") }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight mr-8px">
                  {{ service | get_property("allowed", 0) }}/{{ service | get_property("used", 0) }}/{{ service | get_property("remaining", 0) }}
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
            <vb-no-record v-if="serviceAddons.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.service_detail">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div 
          class="settingsTextAndIconOnTop" 
          @click="conditions.screen=selected.service_type==serviceTypes.addon ? 
            screens.addon_subscription : 
            selected.service_type==serviceTypes.addon_call ? 
            screens.call_packages : 
            screens.plan_license
          "
          >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Detail</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="data in selectedServices" :key="data.free_resource_subscription_id || data.subscription_id">
              <template v-if="data.free_product_detail">
                <Info 
                  v-if="['USER','Team','Call Queue'].includes(data.free_product_detail.product.product_label)" 
                  class="InfoComponentInsideAnroidIOSApp" 
                  :id="data.service_internal_accountcode" 
                /> 
                <div v-else class="headingInsideTable">{{ data | get_property('free_product_detail.product.product_label') }}</div>
              </template>
              <template v-else>
                <Info 
                  v-if="['USER','Team','Call Queue'].includes(data.label)" 
                  class="InfoComponentInsideAnroidIOSApp" 
                  :id="data.service_internal_accountcode" 
                /> 
                <div v-else class="headingInsideTable">{{ data | get_property('label') }}</div>
              </template>
              <span @click.stop="unsubscribe(data)">
                <vb-icon icon="trash-IOSIcon" class="rightFacingArrow fillDanger" width="16.642" height="15.275" />
              </span>
            </div>
            <vb-no-record v-if="selectedServices.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.call_packages">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.addon_subscription">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Call Packages</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="service in callAddons" 
              :key="service.product_id" 
              @click="selected.service_id=service.product_id;selected.service_type=serviceTypes.addon_call;conditions.screen=screens.service_detail;"
            >
              <div class="headingInsideTable">{{ service | get_property("label") }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight mr-8px">
                  {{ service | get_property("allowed", 0) }}/{{ service | get_property("used", 0) }}/{{ service | get_property("remaining", 0) }}
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
            <vb-no-record v-if="callAddons.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.plan">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.update_plan.send ? '' : conditions.screen=screens.plans">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Plan</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">

            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">{{ selectedPlan.name }} {{ selectedPlan.real_id==currentPlanId ? 'Current' : '' }}</div>
              <div class="textInsideCard textAlignRight onRightSide mr-6px">
                {{ selectedPlan | get_property('currency.currency_code','') }} {{ selectedPlan.price | floatFixed2 }}/{{selectedPlan.interval}}/user
              </div>
            </div>

            <div class="basicWhiteIOScard-item" @click="api.update_plan.send ? '' : conditions.screen=screens.plan_products">
              <div class="headingInsideTable">Products</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>

            <div class="basicWhiteIOScard-item" @click="api.update_plan.send ? '' : conditions.screen=screens.plan_features">
              <div class="headingInsideTable">Features</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>

          </div>

          <button 
            v-if="selectedPlan.real_id!=currentPlanId" 
            class="IosFullWidthButton forDone mt-16px mb-16px" 
            type="submit" 
            :disabled="api.update_plan.send" 
            @click="updatePlan()"
          >
            <vb-spinner v-if="api.update_plan.send" />
            <template v-else>Choose Plan</template>
          </button>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.plan_products">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.plan">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Plan Products</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="product in selectedPlanProducts" :key="product.product_id">
              <div class="headingInsideTable">{{ product | get_property('product.product_label') }}</div>
              <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ product | get_property('plan_free_product_allowed') }}</div>
            </div>
            <vb-no-record v-if="selectedPlanProducts.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.plan_features">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.plan">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Plan Features</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="feature in selectedPlanFeatures" :key="feature.id">
              <div class="headingInsideTable">{{ feature | get_property('feature.label') }}</div>
            </div>
            <vb-no-record v-if="selectedPlanFeatures.length==0" :text="'There is no Products'" :design="3" />
          </div>

        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Plans</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            
            <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.plan_license">
              <div class="headingInsideTable">Plan License</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>
            <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.addon_subscription">
              <div class="headingInsideTable">Addon Subscription</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>

          </div>
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Monthly</div>
              <div class="position-relative">
                <b-check 
                  class="dialer-switch custom-control custom-switch IosSwitch" 
                  v-model="filters.plans.monthly" 
                  switch 
                />
              </div>
            </div>
            <div class="basicWhiteIOScard-item" v-for="plan in plans" :key="plan.real_id" @click="selected.plan_id=plan.real_id;conditions.screen=screens.plan;">
              <div class="headingInsideTable">{{ plan.name }} {{ plan.real_id==currentPlanId ? 'Current' : '' }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">
                  {{ plan | get_property('currency.currency_code','') }} {{ plan.price | floatFixed2 }}/{{plan.interval}}/user
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <vb-no-record v-if="plans.length==0" :text="api.packages.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.packages.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>
      </div>
    </template>
  </modal>
</div>
</template>

<script>
import Info from '../../../Lists/Info.vue'

import { VOIP_API } from "@/utils";
// import { centrifugo } from '@/Centrifuge';

import { mapGetters } from 'vuex';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

let moment = require("moment-timezone");

let screens = {
  plans: 'plans',
  plan_license: 'plan_license',
  addon_subscription: 'addon_subscription',
  service_detail: 'service_detail',
  call_packages: 'call_packages',
  plan: 'plan',
  plan_products: 'plan_products',
  plan_features: 'plan_features',
}
let service_types = {
  free: 'free',
  addon: 'addon',
  addon_call: 'addon_call',
}

export default {
  name: "SubscriptionPlanDetailModal",
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'SubscriptionPlanDetail'
    },
  },
  data() {
    return {
      api: {
        subscribe: this.$helperFunction.apiInstance({ send: '' }),
        unsubscribe: this.$helperFunction.apiInstance({ send: '' }),
        packages: this.$helperFunction.apiInstance({  }),
        pormotions: this.$helperFunction.apiInstance({  }),
        update_plan: this.$helperFunction.apiInstance({  }),
        subscription: this.$helperFunction.apiInstance({  }),
        addon: this.$helperFunction.apiInstance({  }),
      },
      selected: {
        plan_id: "",
        service_id: "",
        service_type: '',
      },
      filters: {
        plans: {
          monthly: true,
        },
      },
      response: {
        subscription: {},
        addon: {},
        packages: [],
        pormotions: [],
      },
      conditions: {
        screen: screens.plans,
      },
    };
  },
  inject:['isEmpty', 'getProperty','appNotify'],
  filters: {
    floatFixed2(value){
      if(typeof value != 'string') return value
      return parseFloat(value).toFixed(2)
    },
    filter_date_current(value){
      return moment(value).calendar(null, {
        sameDay: '[Today] | LT',
        nextDay: '[Tomorrow] | LT',
        nextWeek: 'dddd | LT',
        lastDay: '[Yesterday] | LT',
        lastWeek: '[Last] dddd | LT',
        sameElse: 'DD MMM YYYY | LT'
      })
    }
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getCurrentUser'
    ]),
    screens(){ return screens },
    serviceTypes(){ return service_types },
    plans(){ return this.response.packages?.filter?.(item=>item.interval==(this.filters.plans.monthly ? 'month':'year')) ?? [] },
    selectedPlan(){ return this.response.packages?.find?.(item=>item.real_id==this.selected.plan_id) },
    selectedPlanProducts(){ return this.selectedPlan?.products ?? [] },
    selectedPlanFeatures(){ return this.selectedPlan?.featureGroup?.features ?? [] },
    planLicenses(){ return this.response.subscription?.freeProductBreakDown ?? {} },
    currentPlanId(){ return this.response?.subscription?.userCurrentSubscription?.plan_id },
    callAddons(){ return Object.values(this.response.addon).filter(i=>i.type!='service') },
    serviceAddons(){ return Object.values(this.response.addon).filter(i=>i.type=='service') },
    selectedServices() {
      const service_id = this.selected.service_id
      const service_type = this.selected.service_type
      if(service_type==service_types.addon){
        return this.serviceAddons?.find?.(service=>service.product_id==service_id)?.usedDetail ?? []
      } else if (service_type==service_types.addon_call){
        return this.callAddons?.find?.(service=>service.product_id==service_id)?.usedDetail ?? []
      } else {
        const product_label = this.planLicenses?.[service_id]?.product_label
        const plan_free_product_id = this.planLicenses?.[service_id]?.plan_free_product_id
        return this.response.subscription?.freeProductSubcription?.filter?.((item) => item.plan_free_product_id === plan_free_product_id)?.map?.(i=>{
          const is_active = i.free_resource_subscription_status.toLowerCase()==='active'
          const is_main = this.getVoipUsersAlises[i.service_internal_accountcode]?.user?.user_type=='main'
          const is_current_user = i.service_internal_accountcode==this.getCurrentUser.account
          return {
            ...i,
            is_can_delete: product_label=='USER' ? is_active && !is_main && !is_current_user : is_active,
          }
        }) ?? [];
      }
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchPackages()
      this.fetchSubscriptionDetail()
      this.fetchAddonDetail()
      this.fetchPromotions()
    },
    onBeforeClose(){
      this.selected.plan_id = ""
      this.selected.service_id = ""
      this.selected.service_type = ''
      this.filters.plans.monthly = true
      this.response.subscription = {}
      this.response.addon = {}
      this.response.packages = []
      this.response.pormotions = []
      this.conditions.screen=screens.plans
      this.api.subscribe.reset()
      this.api.unsubscribe.reset()
      this.api.packages.reset()
      this.api.pormotions.reset()
      this.api.update_plan.reset()
      this.api.subscription.reset()
      this.api.addon.reset()
    },
    fetchPromotions(){
      let vm = this
      if(vm.api.pormotions.send) return;
      vm.api.pormotions.send=true
      VOIP_API.endpoints.billing.getpromotions({
        uid: vm.getCurrentUser?.uid,//vm.current_user.uid
      }).then(({ data: pormotions }) => {
        vm.response.pormotions=pormotions;
      }).finally(() => {
        vm.api.pormotions.send = false;
      });
    },
    fetchPackages(){
      let vm = this
      if(vm.api.packages.send) return;
      vm.api.packages.send=true
      VOIP_API.endpoints.billing.packages({
        uid: vm.getCurrentUser?.uid,//vm.current_user.uid
      }).then(({ data: { data: packages } }) => {
        vm.response.packages = packages;
      }).finally(() => {
        vm.api.packages.send = false;
      });
    },
    fetchSubscriptionDetail(){
      let vm = this
      if(vm.api.subscription.send) return;
      vm.api.subscription.send = true;
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: vm.getCurrentUser?.uid,//vm.current_user.uid
      }).then(({ data }) => {
        vm.response.subscription = data;
      }).finally(() => {
        vm.api.subscription.send = false;
      });
    },
    fetchAddonDetail(){
      let vm = this
      if(vm.api.addon.send) return;
      vm.api.addon.send = true;
      VOIP_API.endpoints.billing.getPlanAddonDetail({ 
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.addon = data;
      })
      .finally(() => {
        vm.api.addon.send = false;
      });
    },
    updatePlan() {
      let vm = this
      if (vm.api.update_plan.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to choose this plan`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.update_plan.send = true;
            VOIP_API.endpoints.billing.updateplan({
              new_plan_id: vm.selectedPlan.real_id,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
              default_account: vm.getCurrentUser?.default_accountcode,
            })
            .then(()=>{
              vm.fetchPromotions()
              vm.fetchPackages()
              vm.fetchSubscriptionDetail()
              vm.fetchAddonDetail()
              vm.$emit('update-plan')
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.update_plan.send = false;
            });
          }
        },
      });
    },
    unsubscribe(data){
      const { free_resource_subscription_id, subscription_id: addon_subscription_id } = data
      const subscription_id = free_resource_subscription_id || addon_subscription_id
      let vm = this
      if(vm.api.unsubscribe.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.unsubscribe.send=subscription_id
            VOIP_API.endpoints.billing.unsubscribe({
              id: data.service_internal_accountcode,
              uid: vm.getCurrentUser?.uid,//vm.current_user.uid,
              accountcode: data.service_internal_accountcode,
            }).then(()=>{
              vm.appNotify({
                message: 'Successfully UnSubscribed!',
                type: 'success',
              })
              setTimeout(()=>{
                vm.fetchSubscriptionDetail()
                vm.fetchAddonDetail()
              },3 * 1000)
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.unsubscribe.send=''
            })
          }
        },
      });
    },
    onNotification(message) {
      if (message.data.action == "add" && message.data.section == "plan") {
        this.$modal.hide(this.modalName);
        this.fetchPackages()
        this.fetchSubscriptionDetail()
        this.fetchAddonDetail()
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
  },
};
</script>

<style>
</style>