<template>
  <div 
    id="app" 
    :class="[themeClass,{
      'insideIntigrations': $store.getters.isIntegration
    }]"
    style="display: flex;flex-direction: column;"
  >
    <DeveloperSetting />
    <CommonElectron  v-if="isElectron"/>
    <LoaderMain v-if="is_loading" style="z-index: 999999999999999999999999999999999999999999999999999999999999999999999999;" />
    <div :class="`latestMobileLoading ${is_mobile_loading ? 'visible' : ''}`" style="z-index: 999999999999999999999999999999999999999999999999999999999999999999999999;">
      <div class="latestMobileLoading-inner h-100"></div>
    </div>
    <div :class="`${$store.getters.isAppTourStarted ? 'fixWhenAppTourStarted custom-100vh' : ''}`">
      <router-view name="login" />
      <div class="h-100" >
        <router-view />
      </div>
    </div>
    <!-- <vue-confirm-dialog /> -->
  </div>
</template>

<script>
import { LOGGER, devices, permissions, VOIP_API, DEVICE_INFO, MEDIA_DEVICES_SETTING, URLS, $fn } from './utils';
// import { centrifugo } from './Centrifuge';
import { mattermost, MATTERMOST_API } from './MatterMost';
import { MOTHERSHIP } from './mothership';
// import { STRIPE } from './strip';
import CommonElectron from './components/CommonElectron.vue';
import DeveloperSetting from './components/Modals/DeveloperSetting.vue';
import _ from "lodash";
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LoaderMain from "./components/LoaderMain.vue";
import { INTEGRATIONS } from './integrations';
import { FIREBASE } from './firebase';
import { GET_API_ERRORS, /*GET_CENTRIFUGO_ERRORS,*/ GET_LOCAL_SETTING_IS_DARK_MODE } from './store/helper/getters';
import { SET_LOCAL_SETTING_THEME_SYSTEM_MODE } from './store/helper/mutations';
import { AMI_SOCKET } from './ami';
import { RESET_API_ERRORS/*, RESET_CENTRIFUGO_ERRORS*/ } from './store/helper/actions';
import moment from 'moment';
import Push from 'push.js'
const { electron_events } = require('./electron/events')
let shortcut_times = 0
let notification_messages = []
export default {
  name: "App",
  components: {
    LoaderMain,
    CommonElectron,
    DeveloperSetting,
  },
  data(){
    return {
      is_loading: false,
      is_mobile_loading: false,
    }
  },
  provide(){
    return{
      isEmpty: this.isEmpty,
      getProperty: this.getProperty,
      setDesign: this.setDesign,
      appNotify: this.notify,
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE,
      GET_API_ERRORS,
      // GET_CENTRIFUGO_ERRORS
    ]),
    themeClass(){
      if(this.GET_LOCAL_SETTING_IS_DARK_MODE  && !this.$store.getters.getIsMobile ) return 'darkModeV2 v2'
      else return 'coloredModeThreeV2'
      // 'darkModeV2 v2': GET_LOCAL_SETTING_IS_DARK_MODE,
      // 'coloredModeV2': $store.getters.getIscolored,
      // 'coloredModeTwoV2': $store.getters.getIscolored2,
      // 'coloredModeThreeV2': $store.getters.getIscolored3,
    },
    isElectron(){ return process.env.IS_ELECTRON ? true : false },
    isMobile(){ return this.$store.state.device.mobile ? 'yes' : 'no' },
    deviceInfo(){ return this.$store.state.device_info },
  },
  watch: {
    // "$store.getters.getIsMobile"(new_value,old_value){
    //   console.log('run',new_value,old_value)
    //   this.is_mobile_loading=true
    //   this.debounceisMobileLoadingFalse()
    // },
  },
  methods: {
    ...mapMutations([
      'setMediaDevicesSetting',
      SET_LOCAL_SETTING_THEME_SYSTEM_MODE,
    ]),
    ...mapActions([
      RESET_API_ERRORS,
    ]),
    notify(data={}){
      let vm = this
      const sound_type = ['send-message','recieve-message','alert'].includes(data?.sound_type) ? data?.sound_type : 'alert'
      const type = ['info','danger','success','warning'].includes(data?.type) ? data?.type : 'info'
      const notify_type = ['alert','toast'].includes(data?.notify_type) ? data?.notify_type : 'toast'
      const position = ['top-right','top-left','top-center','top-full','bottom-right','bottom-left','bottom-center','bottom-full'].includes(data?.position) ? data?.position : (notify_type=='toast' ? 'bottom-right' : 'bottom-right')
      const message = data?.message ?? ''
      const title = data?.title ?? ''
      const icon = data?.icon ?? ''
      const payload = data?.payload ?? {}
      const sound = data?.sound ?? true
      let toaster = data?.toaster ?? true
      let add_instance = data?.add ?? true
      const duration = data?.duration ?? (notify_type=='toast' ? 3 * 1000 : 10 * 1000)
      const onClick = typeof data?.onClick == 'function' ? data?.onClick : null
      const current_time = moment.utc().valueOf()
      const id = $fn.makeid(30)
      const hide = () => {
        vm.$bvToast.hide(id)
      }
      const clicked = () => {
        const notify_instance = vm.$store.state.notifys?.find?.(i=>i.id==id)
        if(notify_instance){
          notify_instance.view=true
        }
        onClick?.()
        hide()
      }
      const close = () => {
        vm.$store.state.notifys.splice(vm.$store.state.notifys.findIndex(i=>i.id==id),1)
        hide()
      }
      if(notify_type=='toast') {
        if(type=='success') {
          add_instance=false
        }
        if([VOIP_API.errorMessages.server_down,VOIP_API.errorMessages.network,VOIP_API.errorMessages.timeout].includes(message)) {
          if(!notification_messages.includes(message)) {
            notification_messages.push(message)
            setTimeout(()=>{
              notification_messages.splice(notification_messages.indexOf(message),1);
            },duration)
          } else {
            toaster=false
            add_instance=false
          }
        }
      }
      if(toaster){
        const h = vm.$createElement
        const vNodesInfo = 
        h('div',{ class: 'i-container' },[
          h('vb-icon',{ props: { icon: 'latestToasterDesign-i-icon', height: '36px', width: '13.5px' }, class: '' },[]),
        ])
        const vNodesDanger = 
        h('div',{ class: 'i-container' },[
          h('vb-icon',{ props: { icon: 'latestToasterDesign-error-icon', height: '36.02px', width: '38.23px' }, class: '' },[]),
        ])
        const vNodesSuccess = 
        h('div',{ class: 'i-container' },[
          h('vb-icon',{ props: { icon: 'latestToasterDesign-success-icon', height: '36px', width: '36px' }, class: '' },[]),
        ])
        const vNodesWarning = 
        h('div',{ class: 'i-container' },[
          h('vb-icon',{ props: { icon: 'latestToasterDesign-i-icon', height: '36px', width: '13.5px' }, class: '' },[]),
        ])
        const node_type = {
          info: vNodesInfo,
          danger: vNodesDanger,
          success: vNodesSuccess,
          warning: vNodesWarning,
        }
        const type_color = {
          info: '#0068FF',
          danger: '#FE355A',
          success: '#00CC69',
          warning: '#0068FF',
        }
        const vNodesType = node_type[type] ?? node_type.info
        const color = type_color[type] ?? type_color.info
        const vNodesMsg = 
          h('div',{ class: 'new-error-template-2-1 v2', on: { click: clicked } },[
            h('div',{ class: 'error-message-new' }, [
              h('b-icon',{ props: { icon: "x" }, on: { click: hide }, class: 'cross-icon custom-toaster-close-button-9900' },[]),
              h('div',{ class: 'beta-container  z-index-0-imp',style: { '--tasterTimerForProgressBar':`${duration/1000}s`,'--tasterColorForProgressBar':`${color}` } },[
                h('div',{ class: 'beta-text' },[
                  h('div',{ class: 'd-flex align-items-center' },[
                    vNodesType,
                    h('div',{ class: 'pr-3 word-break d-flex flex-row align-items-center' },[
                      icon ? h('div',{ class: 'mr-3' },[
                        h('vb-avatar',{ props: { image: icon } })
                      ]) : '',
                      h('div',{ class: 'd-flex flex-column' },[
                        title ? h('span',{ class: 'font-weight-bold' },[`${title}`]) : '',
                        `${message}`
                      ])
                    ])
                  ]),
                ]),
              ]),
            ]),
          ])
        const vNodesTitle = h('div',{style: {'display': 'none'}},[])
        vm.$bvToast.toast([vNodesMsg], {
          id: id,
          title: [vNodesTitle],
          toaster: `b-toaster-${position}`,
          noCloseButton: true,
          solid: false,
          autoHideDelay: duration,
          toastClass: 'custome-toast-class',
          bodyClass: 'custome-toast-body-class',
          headerClass: 'custome-toast-header-class',
        })
      }
      const notify_instance = {
        sound,
        type,
        message,
        title,
        icon,
        notify_type,
        current_time,
        payload,
        click: clicked,
        close: close,
        id,
        hide,
        view: false,
      }
      if(notify_type=='alert'){
        if (vm.getCurrentUser?.desktop_notification && !window.document.hasFocus()){
          if (window.self !== window.top){ // web app
            MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.DESKTOP_NOTIFICATION,{
              title: title,
              body: message,
              icon: icon,
              payload: payload,
            })
          } 
          // else if(process.env.IS_ELECTRON) { // desktop app
          //   window.electron.send(electron_events.SHOW_NOTIFICATION,{
          //     title: title,
          //     body: message,
          //     icon: icon,
          //     payload: payload,
          //   })
          // } 
          else { // local app
            const notification_instance = Push.create(title, {
              body: message,
              icon: icon,
              payload: payload,
              onClick() {
                window.focus();
                this.close();
              }
            })
            notification_instance.onclick = clicked
          }
          if(sound){
            if(sound_type=='send-message'){
              document.getElementById('send-message-ringtone')?.play?.()
            } else if(sound_type=='recieve-message'){
              document.getElementById('recieve-message-ringtone')?.play?.()
            } else {
              document.getElementById('alert-ringtone')?.play?.()
            }
          }
        }
      }
      if(add_instance) {
        this.$store.state.notifys.push(notify_instance)
      } 
      return notify_instance
    },
    isEmpty(value){ return _.isEmpty(value); },
    getProperty(object, attribute, defaultVal = ""){ return _.get(object, attribute) ?? defaultVal; },
    onBeforeUnload() {
      if(process.env.IS_ELECTRON) window.electron.send(electron_events.BEFORE_UNLOAD)
    },
    init(){
      let vm = this
      FIREBASE.init()
      INTEGRATIONS.init({
        callBacks: {
          onReady(platform){
            LOGGER.log('integrated:platform',platform)
            vm.$store.state.integration_platform=platform
          },
        },
      })
      AMI_SOCKET.init()
      URLS.init()
      VOIP_API.init()
      MATTERMOST_API.init()
      mattermost.init()
      MOTHERSHIP.init()
      this.$store.state.device_info=DEVICE_INFO.init()
      permissions.init()
      devices.init()
      const __MEDIA_DEVICES_SETTING = MEDIA_DEVICES_SETTING.init()
      this.setMediaDevicesSetting(__MEDIA_DEVICES_SETTING)
    },
    onKeyDown(event){
      const { key, shiftKey, ctrlKey } = event
      if(process.env.IS_ELECTRON){
        if(key=='R' && shiftKey && ctrlKey){
          event.preventDefault()
        } else if(key=='I' && shiftKey && ctrlKey){
          event.preventDefault()
        } else if(key=='r' && ctrlKey){
          event.preventDefault()
        }
      }
      if(event.altKey && event.ctrlKey && event.shiftKey && event.code=='Slash'){
        shortcut_times++
        this.openDEveloperModal()
      }
    },
    openDEveloperModal: _.debounce(function(){
      if(shortcut_times==5){
        this.$modal.show('DeveloperSetting')
      }
      shortcut_times=0
    },0.5*1000),
    setDesign(){
      const videocomon = document.getElementById("videocomon");
      const dialerParent = document.getElementById("dialer-parent-inner");
      const dialerRightPanel = document.getElementById("rightpanel");
      const chatBoxSection = document.getElementById("chat-box-section");
      const chatBoxSection2 = document.getElementById("chat-box-section-2");
      if(dialerParent && videocomon) dialerParent.style.height = videocomon.offsetHeight==0 ? "100vh" : `calc(100vh  -  ${videocomon.offsetHeight}px )`;
      if(chatBoxSection && videocomon) chatBoxSection.style.height = videocomon.offsetHeight==0 ? "calc(100vh - 135px)" : `calc(100vh  - 130px -  ${videocomon.offsetHeight}px )`;
      if(chatBoxSection2 && videocomon) chatBoxSection2.style.height = videocomon.offsetHeight==0 ? "calc(100vh - 158px)" : `calc(100vh  - 153px -  ${videocomon.offsetHeight}px )`;
      if(dialerRightPanel && videocomon) dialerRightPanel.style.height = videocomon.offsetHeight==0 ? "93%" : `calc(98vh -  ${videocomon.offsetHeight}px )`;
      const right_sidebar_el = document.getElementById('right-side-bar-334')?.clientHeight
      this.$store.state.css_break_points.b1=(window.innerWidth > 1500 && right_sidebar_el <= 800) || ((window.innerWidth >= 801 || window.innerWidth >= 1500) && right_sidebar_el <= 615)
      this.$store.state.css_break_points.b3_rightSideBar=right_sidebar_el <= 560
    },
    setDesignTwo(){
      const is_mobile_view = window.innerWidth <= 800
      if((is_mobile_view && !this.getIsMobile) || (!is_mobile_view && this.getIsMobile)) {
        this.is_mobile_loading = true
        this.setMobileView()
      }
      document.querySelector(':root').style.setProperty('--inner-height', `${window.visualViewport ? window.visualViewport.height : window.innerHeight}px`);
      document.querySelector(':root').style.setProperty('--inner-width', `${window.innerWidth}px`);
      let ww = window.innerWidth,hh = window.innerHeight;
      let twh = 0;
      twh = (((ww * hh)/100)/36) - 4;
      document.querySelector(':root').style.setProperty('--twh', `${twh}px`);
    },
    setMobileView: _.debounce(function(){
      this.$store.commit('setIsMobile',window.innerWidth <= 800)
      this.is_mobile_loading = false
    },3*1000),
    getDeviceInfo(){
      VOIP_API.endpoints.dns.deviceInfo()
    },
    onChangeSystemThemeMode(){
      this.SET_LOCAL_SETTING_THEME_SYSTEM_MODE(window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches ? 'dark' : 'light')
    },
    checkAnyApiError: _.debounce(async function(){
      try {
        if(this.GET_API_ERRORS.length>0) {
          await VOIP_API.endpoints.error_capture.sync({
            errors: this.GET_API_ERRORS
          })
          this.RESET_API_ERRORS()
        }
      } finally {
        this.checkAnyApiError()
      }
    }, 1.5*60*1000),
  },
  created() {
    if(process.env.NODE_ENV=='production'){
      let head = document.getElementsByTagName('head')[0];
      let monitor_script = document.createElement('script');
      monitor_script.type = 'text/javascript';
      monitor_script.src = "/assets/js/monitor.js";
      head.appendChild(monitor_script);
    }
    this.$watch('$store.state.local_setting.theme.mode', (theme) => {
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.CHANGE_THEME,{
          theme: theme,
        })
      }
    },{
      immediate: true,
    })
    if(process.env.IS_ELECTRON){
      this.$watch('$store.state.electron.update.disabled', (disabled) => {
        window.electron.send(electron_events.CHANGE_UPDATE_DISABLED,{
          disabled: disabled?'1':'0',
        })
      })
      this.$watch('$store.state.electron.app_view', (app_view) => {
        window.electron.send(electron_events.CHANGE_APP_VIEW,{
          app_view: app_view,
        })
      })
    }
    this.init()
    this.getDeviceInfo()
    this.setDesign()
    this.setDesignTwo()
    this.onChangeSystemThemeMode()
    const bodyElement = document.body;
    const config = {
      childList: true,
      subtree: true,
    };
    const observer = new MutationObserver(this.setDesign);
    observer.observe(bodyElement, config);
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.addEventListener?.('change', this.onChangeSystemThemeMode);
    window?.matchMedia?.('(prefers-color-scheme: colored)')?.addEventListener?.('change', this.onChangeSystemThemeMode);
    window.addEventListener('resize',this.setDesign)
    window.addEventListener('resize',this.setDesignTwo)
    window.addEventListener('orientationchange',this.setDesign)
    window.addEventListener("beforeunload", this.onBeforeUnload);
    document.addEventListener('keydown',this.onKeyDown)
    this.checkAnyApiError()
  },
  beforeDestroy() {
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.removeEventListener?.('change', this.onChangeSystemThemeMode);
    window?.matchMedia?.('(prefers-color-scheme: colored)')?.removeEventListener?.('change', this.onChangeSystemThemeMode);
    window.removeEventListener('resize',this.setDesign)
    window.removeEventListener('resize',this.setDesignTwo)
    window.removeEventListener('orientationchange',this.setDesign)
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    document.removeEventListener('keydown',this.onKeyDown)
  },
};
</script>

<style lang="scss">
:root {
  --inner-height : 100vh; 
  --inner-width : 100vw; 
  --twh: 56px;
} 
.dialer-main-loader {
  img {
    // height: 250px;
    // width: 250px;
    height: 450px;
    width: 450px;
  }
}
$billDark: #191919;
$input-range-color: linear-gradient(to right,$billDark 0%,$primary 100%);
.darkModeV2.v2 {
  .chat-actions {
    .dialer-chatBox-typingMessage-area {
      background-color: #191919;
    }
  } 
  .new-error-template {
    background-color: #2f2d30;
    .error-message-new {
      .cross-icon {
        fill: #ffffff;
      }
      color: #ffffff;
    }
  }
  .side_bar_profile_dropdown {
    .dropdown-toggle {
      color: #ffffff;
    }
  }
  .volumeControl-icon {
    background-color: rgb(87, 86, 86);
  }
  // .dialer-button {
  //   &.dialer-button-primary-outline {
  //     &.v2 {
  //       background-color: $billDark !important;
  //       color: $primary !important;
  //     }
  //     &.active {
  //       color: $white !important;
  //       background-color: $primary !important;
  //     }
  //     &.selected {
  //       color: $white !important;
  //       background-color: $primary !important;
  //     }
  //   }
  //   &.dialer-button-delete {
  //     color: #ffffff !important;
  //     border-color: $billDark !important;
  //     background-color: $billDark !important;
  //   }
  // }
  .dialer-switch {
    .custom-control-input:checked ~ .custom-control-label {
      &:after {
        background-color: $billDark !important;
      }
      &:before {
        background-color: $primary !important;
      }
    }
    .custom-control-label {
      &:after {
        background-color: $primary !important;
      }
      &:before {
        background-color: $billDark !important;
      }
    }
  }
  .custom-control-label {
    &:before {
      background-color: $billDark !important;
    }
  }
  input {
    &[type="range"] {
      &::-webkit-slider-runnable-track {
        background: $input-range-color;
      }
      &::-moz-range-track {
        background: $input-range-color;
      }
      &::-ms-track {
        background: $input-range-color;
      }
      &::-webkit-slider-thumb {
        background: $primary !important;
        border-color: $primary !important;
      }
      &::-moz-range-thumb {
        background: $primary !important;
        border-color: $primary !important;
      }
      &::-ms-thumb {
        background: $primary !important;
        border-color: $primary !important;
      }
    }
  }
  
    // box-shadow: 2px 3px 9px 1px rgb(0 0 0 / 56%);
}
@import "./assets/scss/style.scss";
@import "./assets/scss/style_new.scss";
@import "./assets/scss/darkMode.scss";
@import "./assets/scss/_coloredMode.scss";
@import "./assets/scss/_hrm_mike.scss";
body:has(.crmView){
  @import "./assets/scss/_crm_styles.scss";
}
@import "./assets/scss/_hrm_styles.scss";
@import "./assets/scss/_loginStyles.scss";
@media screen and (max-width: 800px){
  @import "./assets/scss/mobileStyle.scss";
}
</style>
