var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isEmpty(_vm.JitsiConference) ? _c('div', {
    staticClass: "conference-container",
    class: _vm.$store.state.global_conditions.is_picture_in_picture_mode ? 'pictureInPictureModeOn' : 'pictureInPictureModeOff'
  }, [_c('div', {
    staticClass: "activeCall-videoArea"
  }, [_c('div', {
    staticClass: "video-container",
    class: {
      active: _vm.conditions.show_button
    },
    attrs: {
      "id": `${_vm._uid}-main-page-top`
    }
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "VideoCallVersion4-topBar"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isMemberOpen = !_vm.videoCall.isMemberOpen;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer addMembersInMobileVideoCall",
    attrs: {
      "icon": _vm.videoCall.isMemberOpen ? 'mobileVideoCall-addUsersToCall-icon' : 'mobileVideoCall-addUsersToCall-icon',
      "width": "40",
      "height": "40"
    }
  })], 1)]), _c('Participants', {
    class: `VideoCallVersion4-bodySection ${_vm.swithViewN ? 'swithView' : ''}`,
    attrs: {
      "id": `${_vm._uid}-main-page`,
      "performanceSetting": _vm.performanceSetting,
      "speaker_id": _vm.speaker_id,
      "JitsiConference": _vm.JitsiConference,
      "filterParticipants": _vm.filterParticipants,
      "participants": _vm.participants,
      "videoCall": _vm.videoCall,
      "speaker_device_id": _vm.speaker_device_id
    }
  }, [_c('LocalTracks', {
    attrs: {
      "performanceSetting": _vm.performanceSetting,
      "speaker": _vm.speaker_id == _vm.user_id,
      "JitsiConference": _vm.JitsiConference,
      "localTracks": _vm.localTracks,
      "isJoined": _vm.isJoined,
      "payload": _vm.payload,
      "listIndex": _vm.listIndex,
      "participants": _vm.participants,
      "videoCall": _vm.videoCall,
      "speaker_device_id": _vm.speaker_device_id,
      "error": _vm.error
    },
    on: {
      "camera": function ($event) {
        return _vm.camera();
      },
      "audio": function ($event) {
        return _vm.audio();
      },
      "error-close": function ($event) {
        _vm.error = '';
      },
      "swithView": function ($event) {
        _vm.swithViewN = !_vm.swithViewN;
      }
    }
  })], 1), _c('div', {
    staticClass: "VideoCallBeforeConnect-bottomBar"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-bottomBar-inner"
  }, [_c('div', {
    staticClass: "mobileVideoCall-video-icon-container"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer mobileVideoCall-video-icon",
    attrs: {
      "icon": _vm.videoCall.isVideoMute ? 'mobileVideoCall-videoDisabled-icon' : 'mobileVideoCall-video-icon',
      "width": "54px",
      "height": "54px"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsVideoMute();
      }
    }
  }), _c('div', {
    staticClass: "dialer-dropdown position-absolute audioVideoDevicesDD"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter w-auto",
    attrs: {
      "varient": "link",
      "dropup": "",
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "arrowPillow-duringJitsiCall-icon",
            "height": "18px",
            "width": "18px"
          }
        })];
      },
      proxy: true
    }], null, false, 1476405961)
  }, [_c('div', {
    staticClass: "moreOptionsDD-heading"
  }, [_c('div', [_vm._v("Camera Devices")])]), _vm._l(_vm.media_devices_setting.videoinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.camera_device_id = device.deviceId;
          _vm.camera();
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('span', {
      staticClass: "icon"
    }, [_vm.camera_device_id == device.deviceId ? _c('b-icon', {
      attrs: {
        "icon": "camera-video-fill"
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "ml-3 textContainer"
    }, [_vm._v(_vm._s(device.label))])])]);
  })], 2)], 1)], 1), _c('vb-icon', {
    staticClass: "cursor_pointer mobileVideoCall-microphone-icon",
    attrs: {
      "icon": _vm.videoCall.isAudioMute ? 'mobileVideoCall-microphoneDisabled-icon' : 'mobileVideoCall-microphone-icon',
      "width": "54px",
      "height": "54px"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsAudioMute();
      }
    }
  }), _c('vb-icon', {
    staticClass: "cursor_pointer mobileVideoCall-playPause-icon",
    attrs: {
      "icon": _vm.videoCall.isPlay ? 'mobileVideoCall-pause-icon' : 'mobileVideoCall-play-icon',
      "width": "54px",
      "height": "54px",
      "id": "video-icon-hold"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isPlay = !_vm.videoCall.isPlay;
      }
    }
  }), !_vm.$store.state.global_conditions.is_picture_in_picture_mode ? _c('vb-icon', {
    staticClass: "cursor_pointer mobileVideoCall-chat-icon",
    attrs: {
      "icon": _vm.videoCall.isChat ? 'mobileVideoCall-chat-icon' : 'mobileVideoCall-chat-icon',
      "width": "54px",
      "height": "54px",
      "id": "video-icon-open-chat"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = !_vm.videoCall.isChat;
      }
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "cursor_pointer addMembersInMobileVideoCall",
    attrs: {
      "icon": "mobileVideoCall-hangUp-icon",
      "width": "54px",
      "height": "54px",
      "id": "video-icon-hold"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isHangout = !_vm.videoCall.isHangout;
      }
    }
  })], 1)])] : [_vm.getIsMobile && false ? [_c('div', {
    staticClass: "videoCallTopBar"
  }, [_c('div', {
    staticClass: "videoCallTopBar-inner"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info"
  }, [_c('div', {
    staticClass: "dialer-caller-dp d-flex align-items-center mr-3",
    attrs: {
      "id": "video-icon-dp"
    }
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer mr-2",
    staticStyle: {
      "margin-left": "-18px"
    },
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.9"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('to-dashboard');
      }
    }
  }), _vm.videoCall.images.length === 0 ? _c('div', {
    staticClass: "dialer-user-dp"
  }, [_c('img', {
    staticClass: "object-fit-cover",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.getVoipUsersAlisesImages[_vm.videoCall.data.callee] || '',
      "alt": _vm.getVoipUsersAlisesDisplayName[_vm.videoCall.data.callee]
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]) : _vm._e(), _vm.videoCall.images.length === 1 ? _vm._l(_vm.videoCall.images, function (image) {
    return _c('div', {
      key: image.key,
      staticClass: "dialer-user-dp"
    }, [_c('img', {
      staticClass: "object-fit-cover",
      attrs: {
        "width": "40",
        "height": "40",
        "src": image.src || '',
        "alt": image.alt
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.userDp;
        }
      }
    })]);
  }) : _vm.videoCall.images.length > 1 ? _c('div', {
    staticClass: "image-length-container"
  }, [_c('div', {
    staticClass: "image-length"
  }, [_vm._v(_vm._s(_vm.videoCall.images.length))])]) : _vm._e()], 2), _vm.videoCall.isChat && _vm.$store.state.global_conditions.is_right_bar_open ? _c('b-popover', {
    attrs: {
      "target": "video-icon-dp",
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0 text-dark"
  }, [_vm._v(_vm._s(_vm.videoCall.data.display_name || _vm.videoCall.name))]), _vm.$store.state.calls.outgoing_calls[_vm.videoCall.room] ? [_vm.videoCall.outgoing && _vm.videoCall.outgoing.time ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3 text-dark"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.videoCall.outgoing.time)))])]) : _vm._e(), _vm.videoCall.outgoing && _vm.videoCall.outgoing.status ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3 text-dark"
  }, [_vm._v("Status: " + _vm._s(_vm.videoCall.outgoing.status))])]) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3 text-dark"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.videoCall.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)], 2)]) : _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0 text-light"
  }, [_vm._v(_vm._s(_vm.videoCall.data.display_name || _vm.videoCall.name))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3 text-light"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.videoCall.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video",
      "variant": "light"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isPlay ? 'video-call-pause-icon' : 'video-call-play-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-hold"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isPlay = !_vm.videoCall.isPlay;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-hold",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isPlay ? 'hold' : 'unhold'))])])], 1), _c('span', {
    staticClass: "ml-3"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-switchCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('calls-list');
      }
    }
  })], 1)])])])] : _vm._e(), _c('Participants', {
    class: `${_vm.swithViewN ? 'swithView' : ''}`,
    attrs: {
      "performanceSetting": _vm.performanceSetting,
      "speaker_id": _vm.speaker_id,
      "JitsiConference": _vm.JitsiConference,
      "participants": _vm.participants,
      "filterParticipants": _vm.filterParticipants,
      "videoCall": _vm.videoCall,
      "speaker_device_id": _vm.speaker_device_id
    }
  }, [_c('LocalTracks', {
    attrs: {
      "performanceSetting": _vm.performanceSetting,
      "speaker": _vm.speaker_id == _vm.user_id,
      "JitsiConference": _vm.JitsiConference,
      "localTracks": _vm.localTracks,
      "isJoined": _vm.isJoined,
      "payload": _vm.payload,
      "listIndex": _vm.listIndex,
      "participants": _vm.participants,
      "videoCall": _vm.videoCall,
      "speaker_device_id": _vm.speaker_device_id,
      "error": _vm.error
    },
    on: {
      "camera": function ($event) {
        return _vm.camera();
      },
      "audio": function ($event) {
        return _vm.audio();
      },
      "error-close": function ($event) {
        _vm.error = '';
      },
      "swithView": function ($event) {
        _vm.swithViewN = !_vm.swithViewN;
      }
    }
  })], 1), _vm.isJoined ? _c('div', {
    staticClass: "d-flex dialer-incommingVideoCall-active v2",
    class: _vm.videoCall.isChat && _vm.$store.state.global_conditions.is_right_bar_open ? 'dialer-incommingVideoCall-active-new' : ''
  }, [_c('div', {
    staticClass: "d-flex dialer-incommingVideoCall-close"
  }, [_c('div', {
    staticClass: "actions-info-container"
  }, [_c('span', {
    staticClass: "d-flex with-dd position-relative forVideoCamera"
  }, [!_vm.getIsMobile ? _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isVideoMute ? 'videoOff-duringJitsiCall-icon' : 'videoOn-duringJitsiCall-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-video"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsVideoMute();
      }
    }
  }) : _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isVideoMute ? 'video-call-VideoNot-icon' : 'video-call-VideoIcon-icon',
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsVideoMute();
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-video",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isVideoMute ? 'video on' : 'video off'))])]), _c('div', {
    staticClass: "dialer-dropdown position-absolute audioVideoDevicesDD",
    staticStyle: {
      "top": "-22px",
      "right": "-10px"
    }
  }, [_c('b-dropdown', {
    staticClass: "ico-filter w-auto",
    attrs: {
      "varient": "link",
      "dropup": "",
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "arrowPillow-duringJitsiCall-icon",
            "height": "18px",
            "width": "18px"
          }
        })];
      },
      proxy: true
    }], null, false, 1476405961)
  }, [_c('div', {
    staticClass: "moreOptionsDD-heading"
  }, [_c('div', [_vm._v("Camera Devices")])]), _vm._l(_vm.media_devices_setting.videoinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.camera_device_id = device.deviceId;
          _vm.camera();
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('span', {
      staticClass: "icon"
    }, [_vm.camera_device_id == device.deviceId ? _c('b-icon', {
      attrs: {
        "icon": "camera-video-fill"
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "ml-3 textContainer"
    }, [_vm._v(_vm._s(device.label))])])]);
  })], 2)], 1)], 1), _c('span', {
    staticClass: "md-mar-left with-dd position-relative forAudioDevice"
  }, [!_vm.getIsMobile ? _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isAudioMute ? 'audioOff-duringJitsiCall-icon' : 'audioOn-duringJitsiCall-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-audio-mute"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsAudioMute();
      }
    }
  }) : _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isAudioMute ? 'video-call-AudioNot-icon' : 'video-call-AudioIcon-icon',
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsAudioMute();
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-audio-mute",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isAudioMute ? 'audio unmute' : 'audio mute'))])]), _c('div', {
    staticClass: "dialer-dropdown audioVideoDevicesDD position-absolute",
    staticStyle: {
      "top": "-22px",
      "right": "-10px"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "arrowPillow-duringJitsiCall-icon",
            "height": "18px",
            "width": "18px"
          }
        })];
      },
      proxy: true
    }], null, false, 1476405961)
  }, [_c('div', {
    staticClass: "moreOptionsDD-heading"
  }, [_c('div', [_vm._v("Speaker Devices")])]), _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('b-dropdown-item', {
      key: `${device.deviceId}-audiooutputs`,
      on: {
        "click": function ($event) {
          _vm.speaker_device_id = device.deviceId;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('span', {
      staticClass: "icon"
    }, [_vm.speaker_device_id == device.deviceId ? _c('b-icon', {
      attrs: {
        "icon": "speaker-fill"
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "ml-3 textContainer"
    }, [_vm._v(_vm._s(device.label))])])]);
  }), _c('div', {
    staticClass: "dialer-divider-md"
  }), _c('div', {
    staticClass: "moreOptionsDD-heading"
  }, [_c('div', [_vm._v("Microphone Devices")])]), _vm._l(_vm.media_devices_setting.audioinputs, function (device) {
    return _c('b-dropdown-item', {
      key: `${device.deviceId}-audioinputs`,
      on: {
        "click": function ($event) {
          _vm.mic_device_id = device.deviceId;
          _vm.audio();
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('span', {
      staticClass: "icon"
    }, [_vm.mic_device_id == device.deviceId ? _c('b-icon', {
      attrs: {
        "icon": "mic-fill"
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "ml-3 textContainer"
    }, [_vm._v(_vm._s(device.label))])])]);
  })], 2)], 1)], 1), _c('span', {
    staticClass: "md-mar-left d-flex with-dd position-relative forEmojiAndHandRaise"
  }, [!_vm.getIsMobile ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.top.hover.html",
      value: {
        content: '<small>Hand Raise</small>'
      },
      expression: "{content: '<small>Hand Raise</small>'}",
      modifiers: {
        "top": true,
        "hover": true,
        "html": true
      }
    }],
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "handRaise-duringJitsiCall-icon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.sendEmoji(_vm.defaultEmoji.value);
      }
    }
  }) : _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.top.hover.html",
      value: {
        content: '<small>Hand Raise</small>'
      },
      expression: "{content: '<small>Hand Raise</small>'}",
      modifiers: {
        "top": true,
        "hover": true,
        "html": true
      }
    }],
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "HandRise-MobileIcon",
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.sendEmoji(_vm.defaultEmoji.value);
      }
    }
  }), _c('div', {
    staticClass: "dialer-dropdown position-absolute handRiseDD-container",
    staticStyle: {
      "top": "-22px",
      "right": "-10px"
    }
  }, [_c('b-dropdown', {
    staticClass: "ico-filter w-auto",
    attrs: {
      "varient": "link",
      "dropup": "",
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "arrowPillow-duringJitsiCall-icon",
            "height": "18px",
            "width": "18px"
          }
        })];
      },
      proxy: true
    }], null, false, 1476405961)
  }, _vm._l(_vm.emojis, function (emoji) {
    return _c('b-dropdown-item', {
      key: emoji.value,
      on: {
        "click": function ($event) {
          return _vm.sendEmoji(emoji.value);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('div', {
      staticStyle: {
        "font-size": "35px"
      }
    }, [_vm._v(_vm._s(emoji.icon))])])]);
  }), 1)], 1)], 1), !_vm.getIsMobile ? _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isVideo ? 'shareScreen-duringJitsiCall-icon' : 'shareScreen-duringJitsiCall-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-screen-sharing"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsVideo();
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-screen-sharing",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isVideo ? 'screen sharing' : 'screen sharing'))])])], 1) : _vm._e(), _vm.videoCall.is_conference ? _c('div', {
    staticClass: "md-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(_vm.videoCall.room, {
          meeting_id: _vm.videoCall.meeting_id,
          link: _vm.joinURL
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isMemberOpen ? 'addPeople-duringJitsiCall-icon' : 'addPeople-duringJitsiCall-icon',
      "width": "40",
      "height": "40"
    }
  })], 1) : _vm._e(), !_vm.getIsMobile ? [!_vm.videoCall.is_conference ? _c('span', {
    staticClass: "md-mar-left position-relative"
  }, [_vm.unread > 0 ? _c('b-badge', {
    staticStyle: {
      "top": "-18px",
      "right": "-7px",
      "position": "absolute"
    },
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.unread))]) : _vm._e(), _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isChat ? 'openChat-duringJitsiCall-icon' : 'openChat-duringJitsiCall-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-open-chat"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = !_vm.videoCall.isChat;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-open-chat",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isChat ? 'close chat' : 'open chat'))])])], 1) : _vm._e(), !_vm.videoCall.is_conference ? _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isMemberOpen ? 'addPeople-duringJitsiCall-icon' : 'addPeople-duringJitsiCall-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-open-members"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isMemberOpen = !_vm.videoCall.isMemberOpen;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-open-members",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isMemberOpen ? 'close members' : 'open members'))])])], 1) : _vm._e(), _c('span', {
    staticClass: "md-mar-left"
  }, [_c('div', {
    staticClass: "dialer-dropdown moreOptionsDD"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter w-auto during-video-call",
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "moreOptions-duringJitsiCall-icon",
            "height": "40px",
            "width": "40px"
          }
        })];
      },
      proxy: true
    }], null, false, 1356725812)
  }, [_c('div', {
    staticClass: "moreOptionsDD-heading"
  }, [_c('div', [_vm._v("More")])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.feedBack();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "leaveFeedback-duringJitsiCall-icon",
      "height": "22px",
      "width": "20px"
    }
  })], 1), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Leave Feedback")])])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.el_id}PerformanceSettingModal`, {
          value: _vm.performance_setting
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "performanceSettings-duringJitsiCall-icon",
      "height": "22px",
      "width": "20px"
    }
  })], 1), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Performance setting")])])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.toggleFullScreen();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('vb-icon', {
    class: `${_vm.full_screen ? 'halfScreen-duringJitsiCall-icon' : ''}`,
    attrs: {
      "icon": _vm.full_screen ? 'halfScreen-duringJitsiCall-icon' : 'fullScreen-duringJitsiCall-icon',
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.full_screen ? 'Exit' : 'View') + " full screen")])])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.el_id}ShortCuts`);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('span', {
    staticClass: "icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "ShortcutKeysModal-duringJitsiCall-icon",
      "height": "16px",
      "width": "18px"
    }
  })], 1), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Shortcut keys")])])])], 1)], 1)])] : _vm._e(), !_vm.getIsMobile ? _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isPlay ? 'dialer-pauseInBox-icon' : 'dialer-playInBox-icon',
      "width": "40",
      "height": "40",
      "id": "video-icon-hold"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isPlay = !_vm.videoCall.isPlay;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-hold",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isPlay ? 'hold' : 'unhold'))])])], 1) : _vm._e(), _c('span', {
    staticClass: "md-mar-left"
  }, [!_vm.getIsMobile ? _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40",
      "id": "video-icon-hangup"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isHangout = !_vm.videoCall.isHangout;
      }
    }
  }) : _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "57",
      "height": "57"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isHangout = !_vm.videoCall.isHangout;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-hangup",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isHangout ? 'not hangup' : 'hangup'))])])], 1), _vm.getIsMobile ? [_c('span', {
    staticClass: "md-mar-left forExtraStuffDuringCall"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-more-icon",
      "width": "28",
      "height": "8"
    },
    on: {
      "click": function ($event) {
        _vm.show_extra_button = !_vm.show_extra_button;
      }
    }
  })], 1)] : _vm._e()], 2)])]) : _vm._e(), _vm.reactEmoji ? _c('div', {
    class: `emojiAnimation ${_vm.reactEmoji.classC}`
  }, [_c('span', [_vm._v(_vm._s(_vm.reactEmoji.icon))])]) : _vm._e(), _c('div', {
    class: `DuringJitsiCallMICmutedUnMutedNotification ${_vm.mic_mute_change ? 'active' : ''}`
  }, [_c('div', {
    staticClass: "DuringJitsiCallMICmutedUnMutedNotification-inner"
  }, [_c('span', [_vm._v("Your mic is " + _vm._s(_vm.videoCall.isAudioMute ? 'Muted' : 'Un Muted'))])])]), _vm.show_extra_button && _vm.getIsMobile ? _c('div', {
    staticClass: "extra-buttons-bottom"
  }, [_c('div', {
    staticClass: "extra-buttons-bottom-inner"
  }, [_c('b-icon', {
    staticClass: "extraButtonCloseIcon",
    attrs: {
      "icon": "x"
    },
    on: {
      "click": function ($event) {
        _vm.show_extra_button = false;
      }
    }
  }), !_vm.videoCall.is_conference ? _c('span', {
    staticClass: "md-mar-left"
  }, [_vm.unread > 0 ? _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.unread))]) : _vm._e(), _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isChat ? 'video-call-chat-icon' : 'video-call-chat-icon',
      "width": "57",
      "height": "57",
      "id": "video-icon-open-chat"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = !_vm.videoCall.isChat;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-open-chat",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isChat ? 'close chat' : 'open chat'))])])], 1) : _vm._e(), !_vm.videoCall.is_conference ? _c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isMemberOpen ? 'video-call-AddPeople-icon' : 'video-call-AddPeople-icon',
      "width": "57",
      "height": "57",
      "id": "video-icon-open-members"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isMemberOpen = !_vm.videoCall.isMemberOpen;
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-open-members",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isMemberOpen ? 'close members' : 'open members'))])])], 1) : _vm._e(), _vm.getIsMobile ? _c('span', {
    staticClass: "md-mar-left mr-3"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": _vm.videoCall.isVideo ? 'video-call-ShareScreen-icon' : 'video-call-ShareScreen-icon',
      "width": "57",
      "height": "57",
      "id": "video-icon-screen-sharing"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsVideo();
      }
    }
  }), _c('b-popover', {
    attrs: {
      "target": "video-icon-screen-sharing",
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.videoCall.isVideo ? 'screen sharing' : 'video'))])])], 1) : _vm._e()], 1)]) : _vm._e()]], 2), !_vm.videoCall.is_conference ? _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.videoCall.isChat ? _c('div', {
    staticClass: "dialer-chatBox-inVideo v2 newChatInterfaceDuringVideoCall"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex justify-content-between px-3 p-3 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Chat")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [!_vm.getIsMobile ? _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = false;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1) : _vm._e(), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = false;
      }
    }
  }) : _vm._e()], 1)]), _c('ChatBox', {
    ref: "chat",
    attrs: {
      "channel_id": _vm.channel_id,
      "type": "video"
    },
    on: {
      "change-channel-id": function ($event) {
        _vm.channel_id = $event;
      }
    }
  })], 1) : _vm._e()]) : _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.videoCall.isChat ? _c('div', {
    staticClass: "dialer-chatBox-inVideo v2"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex justify-content-between px-3 p-3 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Chat")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [!_vm.getIsMobile ? _c('a', {
    staticClass: "btn btn-outline-primary p-2",
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = false;
      }
    }
  }, [_vm._v("Close")]) : _vm._e(), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isChat = false;
      }
    }
  }) : _vm._e()], 1)]), _c('ConferenceChat', {
    attrs: {
      "messages": _vm.messages
    }
  })], 1) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.videoCall.isParticipants ? _c('div', {
    staticClass: "dialer-chatBox-inVideo addMemberDuringChat"
  }, _vm._l(_vm.filterParticipants, function (participant) {
    return _c('div', {
      key: participant._id
    }, [_vm._v(_vm._s(participant._displayName))]);
  }), 0) : _vm._e()]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.videoCall.isMemberOpen,
      expression: "videoCall.isMemberOpen"
    }]
  }, [_c('AddMembers', {
    ref: "members",
    attrs: {
      "room": _vm.videoCall.room,
      "listIndex": _vm.listIndex
    },
    on: {
      "closeModal": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isMemberOpen = false;
      }
    }
  })], 1)], 1), !_vm.getIsMobile ? _c('MembersList', {
    ref: "membersList",
    attrs: {
      "filterParticipants": _vm.filterParticipants
    }
  }) : _vm._e(), _c('AddPersonByEmail', {
    attrs: {
      "modalName": _vm.videoCall.room
    }
  }), _c('FeedBackModal', {
    attrs: {
      "modalName": `${_vm.el_id}FeedBack`
    }
  }), _c('PerformanceSettingModal', {
    attrs: {
      "modalName": `${_vm.el_id}PerformanceSettingModal`,
      "performanceSettings": _vm.performanceSettings
    },
    on: {
      "update": function ($event) {
        _vm.performance_setting = $event;
      }
    }
  }), _c('ShortCutsModal', {
    attrs: {
      "modalName": `${_vm.el_id}ShortCuts`
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }