<template>
  <div class="sidebar left-sidebar position-relative">
    <div class="dontDisplayInMobile logo position-absolute w-100 text-center py-3">
      <a class="d-inline-block position-relative">
        <img :src="require('@/assets/images/Top-icon-for-dialing/latest-VB-Logo.svg')" alt="VOIP" style="cursor: default;" />
        <!-- <img :src="require('@/assets/images/Top-icon-for-dialing/Logo.svg')" alt="VOIP" style="cursor: default;" /> -->
        <!-- @click="changeActiveTab('QuickStartPage')" -->
        <!-- <div>
          <div v-if="!$store.state.sip.state.is_connected" v-b-popover.hover="'Sip Socket OR Web Socket not connected'" class="indicator-light bill-223344-reload">
            <b-icon icon="arrow-clockwise" @click="reConnectUserAgent()" />
          </div>
          <div v-if="!$store.state.sip.state.is_register" style="background-color: green;right: 20px;" v-b-popover.hover="'Sip User is not register'" class="indicator-light bill-223344-reload">
            <b-icon icon="arrow-clockwise" @click="reRegisterUserAgent()" />
          </div>
        </div> -->
      </a>
    </div>
    <template v-if="$store.state.css_break_points.b1">
      <!-- <div v-if="!$store.getters.getIsMobile" class="sidebarBurger-container">
        <vb-icon @click="conditions.show_more=!conditions.show_more" icon="leftSideBar-hamBurger-icon" width="50" height="50" class="leftSideBar-hamBurger-icon"/>
      </div> -->
      <template v-if="!$store.getters.getIsMobile" >
        <nav class="dontDisplayInMobile nav nav-menu position-relative text-center">
          <ul class="list-unstyled">
            <li @click="changeActiveTab('homeCopy')">
              <a 
                id="tooltip-Right-Side-Bar-Dashboard" 
                v-b-popover.hover="'Dialer'" 
                class="d-flex align-items-center justify-content-center" 
                :class="{'router-link-exact-active':activeTab === 'homeCopy'}"
              >
                <vb-svg 
                  id="dialer-callIcon" 
                  name="call-monitoring-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 64 64" 
                  stroke-width="0" 
                  stroke="#005DFF" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon" 
                  :class="{ active: activeTab === 'homeCopy' }" 
                />
              </a>
            </li>
            <li 
              v-if="!$store.getters.isIntegration" 
              @click="!getUserPermissions.chat ? '' : changeActiveTab('chat')"
            >
              <a 
                id="tooltip-Right-Side-Bar-Chat" 
                v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
                class="d-flex align-items-center justify-content-center" 
                :class="{'router-link-exact-active':activeTab === 'chat'}"
              >
                <vb-svg 
                  name="chat-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 64 64" 
                  stroke-width="0" 
                  stroke="#005DFF" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon" 
                  :class="{ active: activeTab === 'chat' }" 
                />
                <div class="unreadMessageNumber" v-if="getTotalUnread">{{ getTotalUnread > 99 ? '+99' : getTotalUnread}}</div>
              </a>
              <b-popover target="tooltip-Right-Side-Bar-Chat" content="Chat" triggers="hover" />
            </li>
            <li 
              v-if="!$store.getters.isIntegration" 
              app-tour-web-dashboard-meeting 
              @click="changeActiveTab('todo')"
            >
              <a 
                id="tooltip-Right-Side-Bar-Meeting" 
                v-b-popover.hover="'Task hub'" 
                class="d-flex align-items-center justify-content-center" 
                :class="{'router-link-exact-active':activeTab === 'todo'}"
              >
                <vb-svg 
                  name="todo-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 57.795 57.794"
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon meeting_icon" 
                  :class="{ active: activeTab === 'todo', }" 
                />
              </a>
            </li>
          </ul>
          <ul class="list-unstyled">
            <!-- <li>
              <a class="d-flex align-items-center justify-content-center sidebarBurger-container" >
                <vb-icon id="tooltip-Right-Side-Bar-burger" @click="conditions.show_more=!conditions.show_more" icon="leftSideBar-hamBurger-icon" width="50" height="50" class="leftSideBar-hamBurger-icon"/>
              </a>
              <b-popover target="tooltip-Right-Side-Bar-burger" content="App drawer" triggers="hover" />
            </li> -->
            <li>
              <b-dropdown dropright class="VerticalAppDrawerDD" no-caret>
                <template #button-content>
                  <vb-icon id="tooltip-Right-Side-Bar-burger" icon="leftSideBar-hamBurger-icon" width="50" height="50" class="leftSideBar-hamBurger-icon"/>
                </template>
                <b-dropdown-item 
                  v-if="!$store.getters.isIntegration" 
                  @click="changeActiveTab('schedule')"
                >
                  <vb-icon icon="newScheduling-sidebar-icon" height="50px" width="50px" />
                  <span>Members</span>
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="!$store.getters.isIntegration" 
                  @click="!getUserPermissions.analytics ? '' : changeActiveTab('analytics')"  
                >
                  <vb-svg 
                    name="analytics-icon" 
                    width="50" 
                    height="50" 
                    viewBox="0 0 64 64" 
                    stroke-width="0" 
                    stroke="#005DFF" 
                    fill="none" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    :class="{ active: activeTab === 'analytics', }" 
                  />
                  <span>Analytics</span>
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="!$store.getters.isIntegration && $store.getters.getCurrentUser.administrator_account" 
                  @click="changeActiveTab('callDashboard')"
                >
                  <!-- <vb-svg 
                    name="call-monitoring-icon" 
                    width="50" 
                    height="50" 
                    viewBox="0 0 64 64" 
                    stroke-width="0" 
                    stroke="#005DFF" 
                    fill="none" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    :class="{ active: activeTab === 'callDashboard', }" 
                  /> -->
                  <vb-icon icon="leftSideBar-monitorDashboard-icon" height="50px" width="50px" class="sidebar_icon leftSideBar-monitorDashboard-icon" :class="{ active: activeTab === 'callDashboard', }" />
                  <span>Monitor Dashboard</span>
                </b-dropdown-item>
                <b-dropdown-item 
                  @click="changeActiveTab('PowerDialer')"
                >
                  <vb-icon 
                    :class="{ active: activeTab === 'PowerDialer', }" 
                    class="sidebar_icon leftSideBar-powerDialer-icon" 
                    icon="leftSideBar-powerDialer-icon" 
                    width="50" 
                    height="50" 
                  />
                  <span>Power dialer</span>
                </b-dropdown-item>
              </b-dropdown>
            </li>
            <li v-if="!$store.getters.isIntegration" @click="changeActiveTab('settings')">
              <a 
                id="tooltip-Right-Side-Bar-Setting" 
                v-b-popover.hover="'Setting'" 
                class="d-flex align-items-center justify-content-center" 
                :class="{ 'router-link-exact-active' :activeTab === 'settings' }"
              >
                <vb-svg 
                  name="setting-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 64 64" 
                  stroke-width="0" 
                  stroke="#005DFF" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon" 
                  :class="{ active: activeTab === 'settings' }" 
                />
              </a>
            </li>

          </ul>
        </nav>
      </template>
      <!-- <div v-if="!$store.getters.getIsMobile && conditions.show_more" class="sub-sidebar">
        <div class="back-container" @click="conditions.show_more=!conditions.show_more">
          <b-icon class="mr-1" icon="chevron-left"/>
          Back
        </div>
        <nav class="dontDisplayInMobile nav nav-menu position-relative text-center">
          <ul class="list-unstyled">
            
            <li 
              v-if="!$store.getters.isIntegration" 
              app-tour-web-dashboard-meeting 
              @click="changeActiveTab('schedule')"
            >
              <a 
                id="tooltip-Right-Side-Bar-Meeting" 
                v-b-popover.hover="'Members'" 
                class="d-flex align-items-center justify-content-center" 
                :class="{'router-link-exact-active':activeTab === 'schedule'}"
              >
                <vb-icon icon="newScheduling-sidebar-icon" class="newScheduling-sidebar-icon" height="50px" width="50px" />
              </a>
            </li>
          </ul>
          <ul class="list-unstyled">
            <li v-if="!$store.getters.isIntegration" @click="!getUserPermissions.analytics ? '' : changeActiveTab('analytics')">
              <a 
                id="tooltip-Right-Side-Bar-Analytics-1" 
                v-b-tooltip :title="getUserPermissions.analytics?'':'Your package does not support this feature'"
                class="d-flex align-items-center justify-content-center" 
                :class="{ 'router-link-exact-active': activeTab === 'analytics', }"
              >
                <vb-svg 
                  name="analytics-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 64 64" 
                  stroke-width="0" 
                  stroke="#005DFF" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon" 
                  :class="{ active: activeTab === 'analytics', }" 
                />
              </a>
              <b-popover target="tooltip-Right-Side-Bar-Analytics-1" content="Analytics" triggers="hover" />
            </li>
            <li v-if="!$store.getters.isIntegration && $store.getters.getCurrentUser.administrator_account" @click="changeActiveTab('callDashboard')">
              <a 
                id="tooltip-Right-Side-Bar-Call-dashboard" 
                v-b-popover.hover="'Monitor Dashboard'" 
                class="position-relative d-flex align-items-center justify-content-center" 
                :class="{ 'router-link-exact-active': activeTab === 'callDashboard', }"
              >
                <vb-svg 
                  name="call-monitoring-icon" 
                  width="50" 
                  height="50" 
                  viewBox="0 0 64 64" 
                  stroke-width="0" 
                  stroke="#005DFF" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="sidebar_icon" 
                  :class="{ active: activeTab === 'callDashboard', }" 
                />
              </a>
            </li>
            <li v-if="isDevelopment" class="mb-2">
              <b-dropdown dropleft class="ApplicationsDropDown" no-caret>
                <template #button-content>
                  <vb-icon class="speedDial-icon rightSidebar_icon" icon="appDrawer-icon" width="50" height="50" />
                </template>
                <b-dropdown-item >
                  <div class="applicationsContainer">
                    <div @click="$store.commit('setApplication','hrm')" class="applicationsItself">
                      <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                      <div class="ApplicationText text-wrap">HRM</div>
                    </div>
                    <div  @click="$store.commit('setApplication','dialer')" class="applicationsItself">
                      <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                      <div class="ApplicationText">Dialer</div>
                    </div>
                    <div  @click="$store.commit('setApplication','pamarly')" class="applicationsItself">
                      <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                      <div class="ApplicationText">Pamarly</div>
                    </div>
                    <div  @click="$store.commit('setApplication','crm')" class="applicationsItself">
                      <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                      <div class="ApplicationText">CRM</div>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </li>
          </ul>
        </nav>
      </div> -->
    </template> 
    <nav v-else class="dontDisplayInMobile nav nav-menu position-relative text-center">
      <ul class="list-unstyled">
        <template v-if="getIsDialerApplication">
          <li 
            app-tour-web-dashboard-dialer 
            @click="changeActiveTab('homeCopy')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Dashboard" 
              v-b-popover.hover="'Dialer'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'homeCopy'}"
            >
              <vb-svg 
                id="dialer-callIcon" 
                name="call-monitoring-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'homeCopy' }" 
              />
            </a>
          </li>
          <li 
            v-if="!$store.getters.isIntegration" 
            @click="!getUserPermissions.chat ? '' : changeActiveTab('chat')"
            app-tour-web-dashboard-chat 
          >
            <a 
              id="tooltip-Right-Side-Bar-Chat" 
              v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'"
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'chat'}"
            >
              <vb-svg 
                name="chat-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'chat' }" 
              />
              <div class="unreadMessageNumber" v-if="getTotalUnread">{{ getTotalUnread > 99 ? '+99' : getTotalUnread}}</div>
            </a>
            <b-popover target="tooltip-Right-Side-Bar-Chat" content="Chats" triggers="hover" />
          </li>
          <li 
            v-if="!$store.getters.isIntegration" 
            app-tour-web-dashboard-meeting 
            @click="changeActiveTab('schedule')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Meeting" 
              v-b-popover.hover="'Members'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'schedule'}"
            >
              <!-- <vb-svg 
                name="meeting-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 50 50" 
                stroke-width="0" 
                stroke="" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon meeting_icon" 
                :class="{ active: activeTab === 'schedule', }" 
              /> -->
              <vb-icon icon="newScheduling-sidebar-icon" class="newScheduling-sidebar-icon" height="50px" width="50px" />
            </a>
          </li>
          <li 
            v-if="!$store.getters.isIntegration" 
            app-tour-web-dashboard-meeting 
            @click="changeActiveTab('todo')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Meeting" 
              v-b-popover.hover="'Task hub'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'todo'}"
            >
              <vb-svg 
                name="todo-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 57.795 57.794"
                stroke-width="0" 
                stroke="" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon meeting_icon" 
                :class="{ active: activeTab === 'todo', }" 
              />
            </a>
          </li>
          <li 
            @click="changeActiveTab('PowerDialer')"
          >
            <a 
              id="tooltip-Right-Side-Bar-PowerDialer" 
              v-b-popover.hover="'Power dialer'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'PowerDialer'}"
            >
              <vb-icon 
                :class="{ active: activeTab === 'PowerDialer', }" 
                class="sidebar_icon leftSideBar-powerDialer-icon" 
                icon="leftSideBar-powerDialer-icon" 
                width="50" 
                height="50" 
              />
            </a>
          </li>
        </template>
        <template v-else-if="getIsHRMApplication">
          <li @click="changeActiveTab('HrmHomePage')">
            <a v-b-popover.hover="'Home'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'HrmHomePage', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'HrmHomePage', }" />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-calender')">
            <a v-b-popover.hover="'Calender'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-calender', }">
              <vb-icon icon="calender-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'hrm-calender', }" />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-home')">
            <a v-b-popover.hover="'Users'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-home', }">
              <vb-icon icon="user-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'hrm-home', }" />
            </a>
          </li>
          <!-- <li @click="changeActiveTab('hrm-home')">
            <a 
              v-b-popover.hover="'Home'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'hrm-home'}"
            >
              <vb-svg 
                id="dialer-callIcon" 
                name="call-monitoring-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'hrm-home' }" 
              />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-calender')">
            <a  
              v-b-popover.hover="'Calender'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{'router-link-exact-active':activeTab === 'hrm-calender'}"
            >
              <vb-svg 
                id="dialer-callIcon" 
                name="call-monitoring-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'hrm-calender' }" 
              />
            </a>
          </li> -->
        </template>
        <template v-else-if="getIsPamarlyApplication">
          <li @click="changeActiveTab('pamarly-home')">
            <a v-b-popover.hover="'Home'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-home', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-home', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-teams')">
            <a v-b-popover.hover="'Teams'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-teams', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-teams', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-resources')">
            <a v-b-popover.hover="'Resources'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-resources', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-resources', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-permissions')">
            <a v-b-popover.hover="'Permissions'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-permissions', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-permissions', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-folder')">
            <a v-b-popover.hover="'Folders'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-folder', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-folder', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-deleted-resources')">
            <a v-b-popover.hover="'Deleted Resources'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-deleted-resources', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-deleted-resources', }" />
            </a>
          </li>
          <li @click="changeActiveTab('pamarly-history')">
            <a v-b-popover.hover="'History'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'pamarly-history', }">
              <vb-icon icon="home-leftSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'pamarly-history', }" />
            </a>
          </li>
        </template>
        <template v-else-if="getIsCRMApplication">
          <li @click="changeActiveTab('crm-deals')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-deals', }">
              <vb-icon icon="crm-deals-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-deals', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Deals</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-activities')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-activities', }">
              <vb-icon icon="crm-Activities-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-activities', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Activities</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-inbox')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-inbox', }">
              <vb-icon icon="crm-inbox-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-inbox', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Inbox</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-documents')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-documents', }">
              <vb-icon icon="crm-documents-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-documents', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Documents</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-contacts')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-contacts', }">
              <vb-icon icon="crm-contacts-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-contacts', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Contacts</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-companies')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-companies', }">
              <vb-icon icon="crm-company-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-companies', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Companies</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-insights')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-insights', }">
              <vb-icon icon="crm-insights-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-insights', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Insights</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-products')">
            <a  class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-products', }">
              <vb-icon icon="crm-products-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-products', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Products</span>
              </span>
            </a>
          </li>
          <li @click="changeActiveTab('crm-setting')">
            <a class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'crm-setting', }">
              <vb-icon icon="crm-settings-icon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'crm-setting', }" />
              <span class="router-link-exact-text tooltipVersion">
                <span>Settings</span>
              </span>
            </a>
          </li>
        </template>
      </ul>
      <ul class="list-unstyled">
        <template v-if="getIsDialerApplication">
          <li 
            v-if="!$store.getters.isIntegration" 
            app-tour-web-dashboard-analytics 
            @click="!getUserPermissions.analytics ? '' : changeActiveTab('analytics')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Analytics" 
              v-b-tooltip :title="getUserPermissions.analytics?'':'Your package does not support this feature'"
              class="d-flex align-items-center justify-content-center" 
              :class="{ 'router-link-exact-active': activeTab === 'analytics', }"
            >
              <vb-svg 
                name="analytics-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'analytics', }" 
              />
            </a>
            <b-popover target="tooltip-Right-Side-Bar-Analytics" content="Analytics" triggers="hover" />
          </li>
          <li 
            v-if="!$store.getters.isIntegration && $store.getters.getCurrentUser.administrator_account" 
            app-tour-web-dashboard-monitor 
            @click="changeActiveTab('callDashboard')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Call-dashboard" 
              v-b-popover.hover="'Monitor Dashboard'" 
              class="position-relative d-flex align-items-center justify-content-center" 
              :class="{ 'router-link-exact-active': activeTab === 'callDashboard', }"
            >
              <!-- <vb-svg 
                name="call-monitoring-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'callDashboard', }" 
              /> -->
              <vb-icon icon="leftSideBar-monitorDashboard-icon" height="50px" width="50px" class="sidebar_icon leftSideBar-monitorDashboard-icon" :class="{ active: activeTab === 'callDashboard', }" />
            </a>
          </li>
          <li 
            v-if="!$store.getters.isIntegration" 
            app-tour-web-dashboard-settings 
            app-tour-step="6" 
            @click="changeActiveTab('settings')"
          >
            <a 
              id="tooltip-Right-Side-Bar-Setting" 
              v-b-popover.hover="'Settings'" 
              class="d-flex align-items-center justify-content-center" 
              :class="{ 'router-link-exact-active' :activeTab === 'settings' }"
            >
              <vb-svg 
                name="setting-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon" 
                :class="{ active: activeTab === 'settings' }" 
              />
            </a>
          </li>
        </template> 
        <template v-if="getIsHRMApplication">
          <li @click="changeActiveTab('HrmDocuments')">
            <a v-b-popover.hover="'Documents'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'HrmDocuments', }">
              <vb-icon icon="documents-leftSideBar-hrmIcon" width="50" height="50" class="" :class="{ active: activeTab === 'HrmDocuments', }" />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-reports')">
            <a v-b-popover.hover="'Reports'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-reports', }">
              <vb-icon icon="ReportsSideBar-hrmIcon" width="50" height="50" class="sidebar_icon home-leftSideBar-hrmIcon" :class="{ active: activeTab === 'hrm-reports', }" />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-settingNew')">
            <a v-b-popover.hover="'Settings'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-settingNew', }">
              <vb-svg 
                name="setting-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon " 
                :class="{ active: activeTab === 'hrm-settingNew', }" 
              />
            </a>
          </li>
          <!-- <li @click="changeActiveTab('hrm-reports')">
            <a v-b-popover.hover="'reports'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-reports', }">
              <vb-icon icon="ReportsSideBar-hrmIcon" width="50" height="50" class="sidebar_icon HrmReportsIcon" :class="{ active: activeTab === 'hrm-reports', }" />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-setting')">
            <a v-b-popover.hover="'Setting'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-setting', }">
              <vb-svg 
                name="setting-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon " 
                :class="{ active: activeTab === 'hrm-setting', }" 
              />
            </a>
          </li>
          <li @click="changeActiveTab('hrm-settingNew')">
            <a v-b-popover.hover="'Setting'" class="d-flex align-items-center justify-content-center" :class="{ 'router-link-exact-active': activeTab === 'hrm-settingNew', }">
              <vb-svg 
                name="setting-icon" 
                width="50" 
                height="50" 
                viewBox="0 0 64 64" 
                stroke-width="0" 
                stroke="#005DFF" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
                class="sidebar_icon " 
                :class="{ active: activeTab === 'hrm-settingNew', }" 
              />
            </a>
          </li> -->
        </template>
        
        <!-- dont remove this.It will be used in the future (START) -->
        <li v-if="isDevelopment" class="mb-2">
          <b-dropdown dropleft class="ApplicationsDropDown" no-caret>
            <template #button-content>
              <vb-icon class="speedDial-icon rightSidebar_icon" icon="appDrawer-icon" width="50" height="50" />
            </template>
            <b-dropdown-item >
              <div class="applicationsContainer">
                <div @click="$store.commit('setApplication','hrm')" class="applicationsItself">
                  <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                  <div class="ApplicationText text-wrap">HRM</div>
                </div>
                <div  @click="$store.commit('setApplication','dialer')" class="applicationsItself">
                  <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                  <div class="ApplicationText">Dialer</div>
                </div>
                <div  @click="$store.commit('setApplication','pamarly')" class="applicationsItself">
                  <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                  <div class="ApplicationText">Pamarly</div>
                </div>
                <div  @click="$store.commit('setApplication','crm')" class="applicationsItself">
                  <vb-icon class="ApplicationIcon" icon="speedDial-icon" width="50" height="50" />
                  <div class="ApplicationText">CRM</div>
                </div>
                <div @click="changeActiveTab('schedule')" class="applicationsItself" v-if="!$store.getters.isIntegration">
                  <vb-svg 
                    name="meeting-icon" 
                    width="50" 
                    height="50" 
                    viewBox="0 0 50 50" 
                    stroke-width="0" 
                    stroke="" 
                    fill="none" 
                    stroke-linecap="round" 
                    stroke-linejoin="round" 
                    class="sidebar_icon meeting_icon" 
                    :class="{ active: activeTab === 'schedule', }" 
                  />
                  <div class="ApplicationText">Meetings</div>
                  <!-- <li 
                    v-if="!$store.getters.isIntegration" 
                    app-tour-web-dashboard-meeting 
                    @click="changeActiveTab('schedule')"
                  >
                    <a 
                      id="tooltip-Right-Side-Bar-Meeting" 
                      v-b-popover.hover="'Meetings'" 
                      class="d-flex align-items-center justify-content-center" 
                      :class="{'router-link-exact-active':activeTab === 'schedule'}"
                    >
                      <vb-svg 
                        name="meeting-icon" 
                        width="50" 
                        height="50" 
                        viewBox="0 0 50 50" 
                        stroke-width="0" 
                        stroke="" 
                        fill="none" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        class="sidebar_icon meeting_icon" 
                        :class="{ active: activeTab === 'schedule', }" 
                      />
                    </a>
                  </li> -->
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <!-- dont remove this.It will be used in the future (END) -->
      </ul>
    </nav>
    <UserProfile v-if="!$store.getters.getIsMobile" />
    <div class="display-only-mobile">
      <div class="bottom-icons-container">
        <vb-icon class="mobile-monitor-dashboard TODO-mobile-bottomBarIcon"
          v-if="!$store.getters.isIntegration" 
          :class="{active: activeTab === 'todo'}" 
          icon="mobile-bottomBar-TODO-likeAirCall-icon" 
          @click="$emit('selectedTab','todo')" 
          width="40" 
          height="40" 
        />
        <vb-icon class="mobile-monitor-dashboard"
          v-if="!$store.getters.isIntegration" 
          :class="{active: activeTab === 'selectContext'}" 
          icon="mobile-bottomBar-contacts-likeAirCall-icon" 
          @click="$emit('selectedTab','selectContext')" 
          width="40" 
          height="40" 
        />
        <vb-icon class="mobile-call-activity"
          v-if="!$store.getters.isIntegration" 
          :class="{active: activeTab === 'callLogs'}" 
          icon="mobile-bottomBar-history-likeAirCall-icon" 
          @click="$emit('selectedTab','callLogs')" 
          width="40" 
          height="40" 
        />
        <vb-icon 
          :class="{'active': activeTab === 'homeCopy', 'mx-auto':$store.getters.isIntegration}" 
          class="mobile-dialer-icon" 
          icon="mobile-bottomBar-keypad-likeAirCall-icon" 
          @click="$emit('selectedTab','homeCopy')" 
          width="40" 
          height="40" 
        />
        <UserProfile class="userProfile-inCaseOfIntegrations" v-if="$store.getters.isIntegration" />
        <!-- <UserProfile class="userProfile-inCaseOfIntegrations"  /> -->
        <!-- <vb-icon 
          v-if="!$store.getters.isIntegration" 
          :class="{active: activeTab === 'chat'}" 
          class="mobile-chat" 
          icon="mobile-bottomBar-chat-likeAirCall-icon" 
          @click="!getUserPermissions.chat ? appNotify({message:'Your package does not support this feature',type:'error'}) : $emit('selectedTab','chat')" 
          width="40" 
          height="40" 
        /> -->
        <vb-icon 
          v-if="!$store.getters.isIntegration" 
          :class="{active: activeTab === 'chat'}" 
          class="mobile-chat" 
          icon="mobile-bottomBar-chat-likeAirCall-icon" 
          @click="!getUserPermissions.chat ? appNotify({message:'Your package does not support this feature',type:'danger'}) : $emit('selectedTab','chat')" 
          width="40" 
          height="40" 
        />
      </div>
    </div>
  </div>
  
</template>

<script>
import { electron_events } from '../electron/events';
import { events } from "../utils";
import UserProfile from './UserProfile.vue'
import { mapGetters } from 'vuex'
export default {
  name: "Sidebar",
  components: {
    UserProfile,
  },
  inject: [
    'setDesign',
    'appNotify',
  ],
  props: {
    activeTab: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      conditions: {
        show_more: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getTotalUnread',
      'getIsHRMApplication',
      'getIsCRMApplication',
      'getIsPamarlyApplication',
      'getIsDialerApplication',
    ]),
    isDevelopment(){ return process.env.NODE_ENV!='production' },
  },
  methods: {
    alt_1() {
      this.changeActiveTab('homeCopy')
    },
    alt_2() {
      if(this.getUserPermissions.chat && !this.$store.getters.isIntegration){
        this.changeActiveTab('chat')
      }
    },
    alt_3() {
      if(!this.$store.getters.isIntegration && !this.$store.getters.getIsMobile){
        this.changeActiveTab('schedule')
      }
    },
    alt_4() {
      if(this.getUserPermissions.analytics && !this.$store.getters.isIntegration && !this.$store.getters.getIsMobile){
        this.changeActiveTab('analytics')
      }
    },
    alt_5() {
      if(this.$store.getters.getCurrentUser.administrator_account && !this.$store.getters.isIntegration && !this.$store.getters.getIsMobile){
        this.changeActiveTab('callDashboard')
      }
    },
    alt_6() {
      if(!this.$store.getters.isIntegration && !this.$store.getters.getIsMobile){
        this.changeActiveTab('settings')
      }
    },
    changeActiveTab(value){
      this.$emit("selectedTab", value);
    },
    reload(){
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.RELOAD)
      } else {
        window.location.reload();
      }
    },
    reRegisterUserAgent(){
      this.$store.state.sip.phone?.userAgentRegister?.()
    },
    reConnectUserAgent(){
      this.$store.state.sip.phone?.attemptReconnection?.()
    }
  },
  created() {
    this.$root.$on(events.alt_1, this.alt_1);
    this.$root.$on(events.alt_2, this.alt_2);
    this.$root.$on(events.alt_3, this.alt_3);
    this.$root.$on(events.alt_4, this.alt_4);
    this.$root.$on(events.alt_5, this.alt_5);
    this.$root.$on(events.alt_6, this.alt_6);
  },
  mounted(){
    this.setDesign()
  },
  beforeDestroy() {
    this.$root.$off(events.alt_1, this.alt_1);
    this.$root.$off(events.alt_2, this.alt_2);
    this.$root.$off(events.alt_3, this.alt_3);
    this.$root.$off(events.alt_4, this.alt_4);
    this.$root.$off(events.alt_5, this.alt_5);
    this.$root.$off(events.alt_6, this.alt_6);
  },
};
</script>

<style lang="scss">
.bill-223344-reload {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px !important;
  width: 20px !important;
  color: white;
  svg {
    height: 80%;
    width: 80%;
  }
}
.status-1 {
  color: #22db3a;
}
.status-2 {
  color: #ffd21d;
}
.status-3 {
  color: #ff3b3b;
}
.status-4 {
  color: black;
}
.status-18 {
  color: #ff3b3b;
}
.user-status-1 {
  background-color: #22db3a !important;
}
.user-status-2 {
  background-color: #ffd21d !important;
}
.user-status-3 {
  background-color: #ff3b3b !important;
}
.user-status-4 {
  background-color: black !important;
}
.user-status-18 {
  background-color: #ff3b3b !important;
}

.sidebar {
  .nav {
    ul {
      li {
        a {
          .sidebar_icon {
            g {
              path {
                fill: transparent;
              }
            }
            #chat-icon-2,
            #setting-icon-2 {
              path {
                fill: $primaryLight;
              }
            }
            g {
              g {
                g {
                  path {
                    fill: $primaryLight;
                  }
                }
              }
            }
            &.meeting_icon {
              g {
                path {
                  fill: transparent;
                }
                g {
                  path {
                    fill: $primaryLight;
                  }
                }
              }
            }
            &.active {
              path {
                fill: $white;
              }
              #chat-icon-2,
              #setting-icon-2 {
                path {
                  fill: $primary;
                }
              }
              g {
                g {
                  g {
                    path {
                      fill: $primary;
                    }
                  }
                }
              }
              &.meeting_icon {
                g {
                  path {
                    fill: $white;
                  }
                  g {
                    path {
                      fill: $primary;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            .sidebar_icon {
              path {
                fill: $white;
              }
              #chat-icon-2,
              #setting-icon-2 {
                path {
                  fill: $primary;
                }
              }
              g {
                g {
                  g {
                    path {
                      fill: $primary;
                    }
                  }
                }
              }
              &.meeting_icon {
                g {
                  path {
                    fill: $white;
                  }
                  g {
                    path {
                      fill: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .side_bar_profile_dropdown {
    ul {
      padding: $dialer-default-padding;
      border: none;
      // min-width: 220px;
      min-width: 250px;
      @include box-shadow-mixin(0px 4px 12px rgba(0, 0, 0, 0.1));
      li {
        a {
          display: flex;
          align-items: center;
          .dialer_contact {
            .dialer-userStatus {
              border-color: $white;
            }
            .user-info {
              padding: 0px 0px 0px 12px;
              li {
                a {
                  padding: 0px 0px 8px;
                }
                &.dialer-organization {
                  margin: 0px;
                }
              }
            }
          }
          .main {
            span {
              &:last-child {
                color: $secondary;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
          &:hover {
            background-color: transparent;
            color: $body-color;
          }
        }
      }
    }

    .dialer-organization {
      margin: $dialer-default-padding 0px 8px;
      .organization-list {
        flex-direction: column;
        position: absolute;
        display: none;
        left: 100%;
        background: $white;
        list-style-type: none;
        @include border-radius($dialer-default-padding);
        overflow-y: auto;
        padding: 0 $dialer-default-padding;
        border-top: 24px solid
          $white;
        border-bottom: 24px solid
          $white;
        border-left: 8px solid $white;
        border-right: 8px solid $white;
        max-height: 233px;
        li {
          display: flex;
          justify-content: space-between;
          a {
            &:hover {
              color: $primary;
            }
          }
          &:last-child {
            a {
              padding: 0px;
            }
          }
        }
      }

      .arrow_icon {
        position: absolute;
        right: 0px;
        transition: transform 0.1s ease-in-out;
      }
      &:hover {
        .organization-list {
          display: flex;
        }
        .arrow_icon {
          transform: rotate(-90deg);
        }
      }
    }

    .dialer-header-status {
      height: 8px;
      width: 8px;
      box-sizing: content-box;
      border-radius: 100%;
      background: $green;
      display: inline-block;

      &.snoozed {
        background: $red;
        background-repeat: no-repeat;
        border: none !important;
        height: 17px;
        width: 17px;
      }
    }

    .with-border {
      position: absolute;
      bottom: 8px;
      right: -13px;
    }
  }
}

@media screen and (max-width: 575px) {
}
</style>
