<template>
  <div :id="el_id" class="connection">
    <Conference
      @to-dashboard="$emit('to-dashboard')"
      @calls-list="$emit('calls-list')"
      v-if="isConnectionEstablished"
      ref="conference"
      :JitsiConnection="JitsiConnection"
      :payload="videoCall.payload"
      :videoCall="videoCall"
      :listIndex="listIndex"
      @onDestroyed="onConferenceDestroyed"
      @addNewConference="addNewConference($event)"
      @toggleIsPlay="toggleIsPlay"
      @showModal="$modal.show('AddMembersInVideoCall')"
      :el_id="el_id"
    />
    <template v-else>
      <div v-if="$store.getters.getIsMobile" class="VideoCallBeforeConnect">
        <div class="VideoCallBeforeConnect-topBar">
          <div class="VideoCallBeforeConnect-topBar-inner">
            <div class="VideoCallBeforeConnect-topBar-centerPart">
              <div class="VideoCallBeforeConnect-topBar-name">{{videoCall.name}}</div>
              <div class="VideoCallBeforeConnect-topBar-ringingPart">
                <div class="VideoCallBeforeConnect-topBar-ringingPart-imgSection">
                  <img :src="require('@/assets/images/Top-icon-for-dialing/latest-VB-Logo.svg')" alt="VOIP" style="cursor: default;" />
                </div>
                <div class="VideoCallBeforeConnect-topBar-ringingPart-textPart">Ringing</div>
              </div>
            </div>
          </div>
        </div>
        <div class="VideoCallBeforeConnect-bodySection">
          <video style="height: 100%; width: 100%;" autoplay :id="`${_uid}-testerrrrrr`" />
        </div>
        <div class="VideoCallBeforeConnect-bottomBar">
          <div class="VideoCallBeforeConnect-bottomBar-inner">
            <vb-icon
              :icon="true ? 'mobileVideoCall-videoDisabled-icon' : 'mobileVideoCall-video-icon'" 
              class="cursor_pointer" 
              width="54px" 
              height="54px" 
            />
            <vb-icon
              :icon="true ? 'mobileVideoCall-microphoneDisabled-icon' : 'mobileVideoCall-microphone-icon'" 
              class="cursor_pointer" 
              width="54px" 
              height="54px" 
            />
            <vb-icon
              class="cursor_pointer"
              :icon="true?'mobileVideoCall-hangUp-icon': 'mobileVideoCall-hangUp-icon'"
              width="54px"
              height="54px"
              id="video-icon-hold"
            />
          </div>
        </div>
      </div>
      <div v-if="!$store.getters.getIsMobile" class="" style="background-color: #2f2d30; height: 100%; width: 100%;border-radius: 24px;">
        <video style="height: 100%; width: 100%;" autoplay :id="`${_uid}-testerrrrrr`" />
      </div>
    </template>
  </div>
</template>

<script>
import JitsiMeetJS from "@/Jitsi";
import Conference from "./Conference.vue";
import { $fn, LOGGER, URLS } from "../../utils";
import JwtDecode from "jwt-decode";

export default {
  props: ["videoCall", "listIndex"],
  name: "Connection",
  components: {
    Conference,
  },
  data() {
    return {
      isConnectionEstablished: false,
      isExecute: false,
      JitsiConnection: null,
      payload: {},
      el_id: '',
      stream: null,
    };
  },
  methods: {
    //--own--
    toggleIsPlay(isPlay) {
      this.$emit("toggleIsPlay", isPlay);
    },
    async invite(){
      try {
        const jwt = await this.videoCall.getToken()
        this.$store.state.calls.videoCalls[this.listIndex].jwt=jwt
        this.$store.state.calls.videoCalls[this.listIndex].payload=JwtDecode(jwt)
        if(this.$store.state.calls.outgoing_calls[this.videoCall.room]) this.$store.state.calls.outgoing_calls[this.videoCall.room].jwt=jwt
        this.makeConnection(jwt)
      } catch (ex) {
        console.log(ex)
        this.$destroy();
      }
    },
    makeConnection(token) {
      try {
        // this.payload = JwtDecode(token);
        const domain = URLS.JitsiUrl.replace('https://','').replace('/http-bind','')
        const socket_url = `wss://${domain}/xmpp-websocket`
        const options = {
          hosts: {
            domain: `${this.videoCall.payload.sub}`,
            muc: `muc.${this.videoCall.payload.sub}`,
          },
          serviceUrl: socket_url,
          clientNode: domain,
          // consider_bosh_secure: true,
          enableEffect: true,
        };
        this.JitsiConnection = new JitsiMeetJS.JitsiConnection(this.videoCall.payload.iss,token,options);
        LOGGER.info("JitsiConnection: ", this.JitsiConnection);
        const { events: { connection: events }, } = JitsiMeetJS;
        this.JitsiConnection.addEventListener(events.CONNECTION_ESTABLISHED,this.CONNECTION_ESTABLISHED);
        this.JitsiConnection.addEventListener(events.CONNECTION_FAILED,this.CONNECTION_FAILED);
        this.JitsiConnection.addEventListener(events.CONNECTION_DISCONNECTED,this.CONNECTION_DISCONNECTED);
        this.JitsiConnection.addEventListener(events.WRONG_STATE, this.WRONG_STATE);
        this.JitsiConnection.addEventListener(events.DISPLAY_NAME_REQUIRED,this.DISPLAY_NAME_REQUIRED);
        this.JitsiConnection.connect();
        this.isExecute = true;
      } catch (error) {
        LOGGER.danger("error in makeConnection function", error);
        this.$destroy()
      }
    },
    onConferenceDestroyed() {
      this.$destroy();
    },

    //--events--
    CONNECTION_ESTABLISHED(id) {
      let vm = this
      LOGGER.event("CONNECTION_ESTABLISHED", {
        id,
      });
      this.isConnectionEstablished = true;
      this.$nextTick(()=>{
        vm.stream?.getTracks?.().forEach((track) => track.stop());
      })
    },
    CONNECTION_FAILED(errorCode, type) {
      LOGGER.event("CONNECTION_FAILED", {
        errorCode,
        type,
      });
      const {
        errors: { connection: errors },
      } = JitsiMeetJS;
      switch (errorCode) {
        case errors.OTHER_ERROR:
          this.OTHER_ERROR();
          break;
        case errors.PASSWORD_REQUIRED:
          this.PASSWORD_REQUIRED();
          break;
        case errors.CONNECTION_DROPPED_ERROR:
          this.CONNECTION_DROPPED_ERROR();
          break;
        case errors.SERVER_ERROR:
          this.SERVER_ERROR();
          break;

        default:
          LOGGER.info("error are not in JitsiMeetJS", { errors });
          break;
      }
    },
    CONNECTION_DISCONNECTED() {
      LOGGER.event("CONNECTION_DISCONNECTED");
      this.$destroy();
    },
    WRONG_STATE() {
      LOGGER.event("WRONG_STATE");
    },
    DISPLAY_NAME_REQUIRED(...args) {
      LOGGER.event("DISPLAY_NAME_REQUIRED", ...args);
    },

    //--error--
    CONNECTION_DROPPED_ERROR() {
      LOGGER.danger("CONNECTION_DROPPED_ERROR");
    },
    PASSWORD_REQUIRED() {
      LOGGER.danger("PASSWORD_REQUIRED");
    },
    SERVER_ERROR() {
      LOGGER.danger("SERVER_ERROR");
    },
    OTHER_ERROR() {
      LOGGER.danger("OTHER_ERROR");
      this.JitsiConnection.disconnect();
    },
  },
  created() {
    LOGGER.hook("data", this.videoCall);
    console.log(this.videoCall.jwt,this.videoCall.data)
    if(!this.videoCall.jwt && this.videoCall.getToken) {
      this.invite()
    } else {
      this.makeConnection(this.videoCall?.jwt);
    }
    this.el_id=`jitsi-${$fn.makeid()}`
  },
  mounted(){
    let vm = this
    navigator.mediaDevices.getUserMedia({
      video: true,
    }).then((stream)=>{
      if(vm._isDestroyed) {
        stream?.getTracks?.()?.forEach?.(track=>track.stop())
      } else {
        vm.stream=stream
        document.getElementById(`${vm._uid}-testerrrrrr`).srcObject=stream
      }
    })
  },
  beforeDestroy() {
    LOGGER.hook("beforeDestroy Connection");
    this.stream?.getTracks?.().forEach((track) => track.stop());
    if (this.JitsiConnection) {
      const { events: { connection: events }, } = JitsiMeetJS;
      this.JitsiConnection.removeEventListener(events.CONNECTION_ESTABLISHED,this.CONNECTION_ESTABLISHED);
      this.JitsiConnection.removeEventListener(events.CONNECTION_FAILED,this.CONNECTION_FAILED);
      this.JitsiConnection.removeEventListener(events.CONNECTION_DISCONNECTED,this.CONNECTION_DISCONNECTED);
      this.JitsiConnection.removeEventListener(events.WRONG_STATE, this.WRONG_STATE);
      this.JitsiConnection.removeEventListener(events.DISPLAY_NAME_REQUIRED,this.DISPLAY_NAME_REQUIRED);
      this.JitsiConnection.disconnect();
    }
  },
  destroyed() {
    LOGGER.hook("destroyed Connection");
    this.$emit("onEnd");
  },
};
</script>

<style lang="scss" scoped>
.connection {
  height: 100%;
  width: 100%;
  border-bottom: currentColor solid 1px;
  .connection-Loading {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    video{
      width: 100%;
      height: 100%;
    }
  }
}
</style>