var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 EditExtensionDetailModal bigSquareCheckbox",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v(_vm._s(_vm._f("extensionText")(_vm.data.extension.type)))]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Below are the settings and details associated with this device.")])]), _vm.isExternal ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mb-20px"
  }, [_vm.isExternal ? _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateLabel();
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pr-2"
  }, [_c('label', [_vm._v("Select Country")]), _c('b-dropdown', {
    staticClass: "filterDropdownForTaskHub forNumberForwardingModal w-100",
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.forms.update_label.country ? _vm.CountryName2[_vm.forms.update_label.country] : 'Select Country') + " ")];
      },
      proxy: true
    }], null, false, 1008792252)
  }, [_c('b-dropdown-form', {
    staticClass: "inputItemContainer",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100"
  }, [_c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "greySerchIcon",
    attrs: {
      "icon": "callActivity-search",
      "height": "18px",
      "width": "17.75px"
    }
  }), _c('b-input', {
    attrs: {
      "placeholder": "Search Country"
    },
    model: {
      value: _vm.filters.countries.search,
      callback: function ($$v) {
        _vm.$set(_vm.filters.countries, "search", $$v);
      },
      expression: "filters.countries.search"
    }
  })], 1)])]), _vm._l(_vm.countries, function (data) {
    return _c('b-dropdown-item', {
      key: data.id,
      class: `${_vm.forms.update_label.country == data.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.update_label.country = data.value;
          _vm.filters.countries.search = '';
        }
      }
    }, [_vm._v(" " + _vm._s(data.text) + " "), _vm.forms.update_label.country == data.value ? _c('b-icon', {
      staticClass: "darker-chevron-down withoutPath",
      attrs: {
        "icon": "check-lg"
      }
    }) : _vm._e()], 1);
  })], 2)], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pl-2"
  }, [_c('div', [_c('label', [_vm._v("Enter extension number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.number,
      expression: "forms.update_label.number"
    }, {
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate",
      value: _vm.forms.update_label.country,
      expression: "forms.update_label.country"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update_label.send || _vm.api.delete_extension.send,
      "placeholder": "Enter a number here"
    },
    domProps: {
      "value": _vm.forms.update_label.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "number", $event.target.value);
      }
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.update_label.number.validNumber ? _c('span', [_vm._v("* Number is not valid")]) : _vm._e()]) : _vm._e()])])]), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.update_label.send || _vm.api.delete_extension.send
    }
  }, [_vm._v("Submit")])], 1) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', [_vm.isHeadset ? _c('form', {
    staticClass: "mb-20px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateLabel();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50"
  }, [_c('div', [_c('label', [_vm._v("SIP handset label")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update_label.send || _vm.api.delete_extension.send,
      "placeholder": "Enter a name here"
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("* Device name is required")]) : !_vm.$v.forms.update_label.label.maxLength ? _c('span', [_vm._v("* Device name is can be maximum " + _vm._s(_vm.$v.forms.update_label.label.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Would you like to receive calls on this device?")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("You can choose whether you want to enable call reception on this device.")])]), _c('div', {
    staticClass: "ml-4",
    class: ['w-fit-content']
  }, [_c('b-form-checkbox', {
    ref: "recieve-call",
    staticClass: "newerSwitch",
    attrs: {
      "name": "check-button",
      "disabled": _vm.api.change_recieve_call.send || _vm.api.delete_extension.send,
      "checked": _vm.conditions.recieve_call,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggleRecieveCalls($event);
      }
    }
  })], 1)])])]), _vm.isDesktop ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Desktop app version")])]), _c('div', {
    staticClass: "ml-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(_vm._s(_vm.DesktopAppVersion))])])])]) : _vm._e(), _vm.isHeadset ? [_c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "dialer-box p-0"
  }, [_c('div', {
    staticClass: "dialer-box-header color-black"
  }, [_vm._v("SIP device configuration")]), _c('div', {
    staticClass: "dialer-box-text mt-3 mb-4"
  }, [_vm._v("Click on each field below to copy it to your clipboard.")]), _c('div', {
    staticClass: "innerWhiteBoxwithRoundedBorder"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-26px"
  }, [_c('div', {
    staticClass: "sipInfoLabel"
  }, [_vm._v("SIP username")]), _c('div', {
    staticClass: "sipInfoData ml-29px"
  }, [_vm._v(_vm._s(_vm._f("check_empty")(_vm.data.extension.accountcode, '-')))])]), _c('div', {
    staticClass: "d-flex align-items-center mb-26px"
  }, [_c('div', {
    staticClass: "sipInfoLabel"
  }, [_vm._v("SIP password")]), _c('div', {
    staticClass: "ml-29px d-flex justify-content-between align-items-center flex-grow-1"
  }, [_c('input', {
    staticClass: "password-input-with-icon sipInfoData",
    attrs: {
      "type": _vm.conditions.show_password ? 'text' : 'password',
      "disabled": ""
    },
    domProps: {
      "value": _vm.data.extension.secret || ''
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.conditions.show_password ? 'eye-slash-fill' : 'eye-fill',
      "height": "19px",
      "width": "21px"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_password = !_vm.conditions.show_password;
      }
    }
  }), _c('vb-icon', {
    staticClass: "mx-3",
    attrs: {
      "icon": "dialer-regenratePassword-icon",
      "disabled": _vm.api.update_secret.send,
      "width": "20.061px",
      "height": "21px"
    },
    on: {
      "click": function ($event) {
        return _vm.updateSecret();
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "width": "17.512px",
      "height": "17.494px",
      "icon": "handsetSettings-copy-icon",
      "disabled": _vm.api.copy_secret.send
    },
    on: {
      "click": function ($event) {
        return _vm.copySecret();
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex align-items-center mb-26px"
  }, [_c('div', {
    staticClass: "sipInfoLabel"
  }, [_vm._v("SIP servers")]), _c('div', {
    staticClass: "sipInfoData ml-29px"
  }, [_vm._v("sip.voipbusiness.com")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "sipInfoLabel"
  }, [_vm._v("SIP port")]), _c('div', {
    staticClass: "sipInfoData ml-29px"
  }, [_vm._v("5060 or 5061")])])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex w-75 flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Device location")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Select your handset location for emergency services. In the event of an emergency, first responders will need to know your location. ")])]), _c('button', {
    staticClass: "newButton mwd-175px ml-4",
    attrs: {
      "disabled": _vm.api.get_emergency_addresses.send || _vm.api.change_location.send || _vm.api.delete_extension.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocation();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change location")])], 1)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-12px mb-0"
  }, [_c('vb-select', {
    attrs: {
      "disabled": _vm.api.get_emergency_addresses.send || _vm.api.change_location.send,
      "options": _vm.response.emergency_addresses,
      "valueField": 'did_number',
      "textField": 'address_label',
      "onEmptyListText": 'There is no location'
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option;
        return [option ? [_c('div', {
          staticClass: "OwnertaggedVersion mb-0 mr-16px d-inline-flex w-auto"
        }, [_vm._v(_vm._s(option.address_label))]), _c('span', [_vm._v(_vm._s(`${option.house} ${option.street} ${option.town} ${option.postcode}`))])] : [_c('div', {
          staticClass: "mr-auto"
        }, [_vm._v("Select a option")])]];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_c('b-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": _vm.forms.change_location.did == option.did_number ? 'check2' : 'blank'
          }
        }), _c('div', {
          staticClass: "OwnertaggedVersion mb-0 mr-16px d-inline-flex w-auto"
        }, [_vm._v(_vm._s(option.address_label))]), _c('span', [_vm._v(_vm._s(`${option.house} ${option.street} ${option.town} ${option.postcode}`))])];
      }
    }], null, false, 3792580102),
    model: {
      value: _vm.forms.change_location.did,
      callback: function ($$v) {
        _vm.$set(_vm.forms.change_location, "did", $$v);
      },
      expression: "forms.change_location.did"
    }
  }), _vm.forms.change_location.submitted && _vm.$v.forms.change_location.did.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_location.did.required ? _c('span', [_vm._v("Location is required")]) : _vm._e()]) : _vm._e()], 1)])])])] : _vm._e(), _vm.isLoggedIn ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_c('div', {
    staticClass: "d-flex flex-column pr-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Logout this device?")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("It will be removed from your device. You can easily add it back later by logging into the app on this device. ")])]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "type": "button",
      "disabled": _vm.api.logout_extension.send || _vm.api.delete_extension.send
    },
    on: {
      "click": function ($event) {
        return _vm.extensionLogout();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.logout_extension.send ? _c('vb-spinner', {
    staticClass: "sm-mar-right"
  }) : [_c('span', [_vm._v("Logout")])]], 2)], 1)])])]) : _vm._e(), _vm.isHeadset && _vm.data.voipaccount ? _c('div', {
    staticClass: "mt-20px",
    attrs: {
      "accountcode": _vm.data.voipaccount
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_vm.isHeadset && _vm.data.voipaccount ? _c('IncomingCallPresentationWidget', {
    attrs: {
      "disabled": _vm.api.delete_extension.send,
      "accountcode": _vm.data.voipaccount
    }
  }) : _vm._e()], 1)])]) : _vm._e(), !_vm.isExternal ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again p-0 smallerFontSizes mt-0",
    attrs: {
      "isListEmpty": _vm.response.history.length == 0,
      "listLength": _vm.response.history.length,
      "perPage": 5,
      "loading": _vm.api.get_history.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return [_vm.$store.getters.getIsMobile ? _vm._l(_vm.response.history, function (history, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'app-name')) + " ")]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(history.status))])])]), _c('td', {
            staticClass: "right dialer-col-right"
          }, [_c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(_vm._f("filter_date_current")(history.updated_at)))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'ip')))]), _c('div', {
            staticClass: "textInsideCard"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'location')))])])]);
        }) : _vm._l(_vm.response.history, function (history, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index
          }, [_c('td', {
            staticStyle: {
              "max-width": "80px",
              "width": "10%"
            }
          }, [_vm._v(_vm._s(history.status))]), _c('td', {
            staticStyle: {
              "max-width": "120px",
              "width": "20%"
            }
          }, [_c('p', {
            staticClass: "myDevicesIpV6"
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'ip')))])]), _c('td', {
            staticStyle: {
              "max-width": "120px",
              "width": "20%"
            }
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'app-name')))]), _c('td', {
            staticStyle: {
              "max-width": "250px",
              "width": "30%"
            }
          }, [_vm._v(_vm._s(_vm._f("extensionDeviceInfo")(history, 'location')))]), _c('td', {
            staticStyle: {
              "max-width": "120px",
              "width": "20%"
            }
          }, [_vm._v(_vm._s(_vm._f("filter_date_current")(history.updated_at)))])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])])]);
        });
      },
      proxy: true
    }], null, false, 2423468101)
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("status")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("IP")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("name")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("location ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Date ")])])]) : _vm._e()])], 1) : _vm._e(), _vm.isCanDelete ? _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this device")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("This will remove "), _c('span', {
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(_vm._s(_vm.forms.update_label.label))]), _vm._v(" from your account.")])]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_extension.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteExtension();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_c('span', [_vm._v("Delete")])])], 1)])])]) : _vm._e()], 2), _c('div', {
    staticStyle: {
      "color": "transparent"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.data.extension, 'prefix.call_prefix.label')))])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }