<template>
  <div v-if="getIsMobile" class="latestGreyBox-9-9-2023 mt-12px">
    <div class="basicWhiteIOScard insideWebApp">
      <div class="basicWhiteIOScard-item">
        <div class="d-flex align-items-center">
          <div class="basicIOSIcon mr-16px">
            <vb-icon class="" icon="latest-mobileLinkedCall-icon"/>
          </div>
          <div class="d-flex flex-column">
            <div class="headingInsideTable">
              Linked calls
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <vb-icon @click="disabled?'':$emit('call-linking-modal-show',{ task_id, cdr_id })" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex flex-column align-items-start mt-45px">
    <div class="TODO-innerHeading">Linked calls</div>
    <CallActivityTestItem 
      v-for="call in response.call_logs" 
      :key="call.id"
      class="mt-16px" 
      :call="call" 
      @onClickInfo1="$modal.show(`${_uid}-TaskCallInfoModalNew`,{
        callInfo: response.call_log
      })"  
    />
    <div class="d-flex align-items-center mt-16px" @click="disabled?'':$emit('call-linking-modal-show',{ task_id, cdr_ids: data.cdr_ids })">
      <div class="linkCallToTicket-text mr-16px">Link a call to the ticket</div>
      <vb-icon icon="linkCallToTicket-plus-icon" class="cursor_pointer_no_hover" height="45px" width="45px" />
    </div>
    <CallActivityModal :modalName="`${_uid}-TaskCallInfoModalNew`" @latest-record="fetchCallActivity()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import CallActivityTestItem from '@/components/Home/CallActivity/TestItem/index.vue';
import CallActivityModal from "../../Modals/callactivity/index.vue";
export default {
  name: 'CallLog',
  components: { 
    CallActivityTestItem,
    CallActivityModal,
  },
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    linked_cdrs: {
      type: Array,
      default: ()=>[],
    },
    task: {
      type: Object,
      default: ()=>({}),
    },
    task_id: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      api: {
        get_call_log: this.$helperFunction.apiInstance(),
      },
      response: {
        call_logs: [],
      },
      data: {
        cdr_ids: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
  },
  methods: {
    async getCallLog() {
      if(this.api.get_call_log.send) return;
      try {
        this.api.get_call_log.send=true
        const { data: { data: logs } } = await VOIP_API.endpoints.call_activity.getsipLogs({
          accountcode: this.getCurrentUser.account,
          clean_data: 'yes',
          sip_logs: 'yes',
          call_ids: this.data.cdr_ids
        })
        this.response.call_logs=logs
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_call_log.send=false
      }
    },
    onUpdatedCallLink(event){
      this.data.cdr_ids=event.cdr_ids;
      this.$emit('call-log-updated')
      this.getCallLog()
    },
  },
  mounted() {
    this.data.cdr_ids=this.linked_cdrs?.map(i=>i.cdrs_id)
    console.log(this.data.cdr_ids.length)
    if(this.data.cdr_ids.length>0){
      this.getCallLog();
    }
  },
}
</script>

<style>

</style>