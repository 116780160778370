<template>
  <div>
    <div v-if="api.fetch_balance_inquiry.send" class="w-100 mt-16px">
      <div class="latestShimmerDesign w-100" style="height:60px;"></div>
    </div>
    <div v-else-if="conditions.have_balance" class="latestSMS-textArea-container">
      <div class="latestSMS-textArea-inner">
        <!-- <div :class="`latestSMS-textArea ${forms.send_sms.sms.length > 50 ? '' : 'h-25px'}`"> -->
        <div :class="`latestSMS-textArea ${forms.send_sms.sms.length > 50 ? '' : ''}`">
          <vb-icon icon="latest-smileyFace-icon" height="25px" width="25px" />
          <b-form-textarea 
            size="sm" 
            v-model="forms.send_sms.sms"
            :disabled="api.send_sms.send" 
            :maxlength="$v.forms.send_sms.sms.$params.maxLength.max"
            placeholder="Write your message"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <p v-if="(forms.send_sms.submitted && $v.forms.send_sms.sms.$invalid) || api.send_sms.validation_errors.sms" class="text text-danger animated bounceIntop">
            <span v-if="!$v.forms.send_sms.sms.required">Sms is required</span>
            <span v-else-if="!$v.forms.send_sms.sms.maxLength">Sms can be maximum {{$v.forms.send_sms.sms.$params.maxLength.max}} character</span>
            <template v-else-if="api.send_sms.validation_errors.sms">
              <span v-for="(em,i) in api.send_sms.validation_errors.sms" :key="i">{{em}}</span>
            </template>
          </p>
        </div>
        <b-dropdown :disabled="api.send_sms.send" no-caret class="latestSMS-textArea-DD" menu-class="w-fit-content-imp" top>
          <template #button-content>
            <span class="latestSMS-textArea-DD-buttonText">
              <template v-if="forms.send_sms.from_number">{{ forms.send_sms.from_number | number_formater }}</template>
              <template v-else>Select caller id</template>
            </span>
            <span class="latestSMS-textArea-DD-arrowIcon">
              <b-icon icon="chevron-down" />
            </span>
          </template>
          <b-dropdown-item v-for="number in vonageNumbers" :key="number" @click="forms.send_sms.from_number=number">{{ number | number_formater }}</b-dropdown-item>
          <b-dropdown-item v-if="vonageNumbers.length==0">No telephone numbers to list.</b-dropdown-item>
        </b-dropdown>
        <p v-if="(forms.send_sms.submitted && $v.forms.send_sms.from_number.$invalid) || api.send_sms.validation_errors.from_number" class="text text-danger animated bounceIntop">
          <span v-if="!$v.forms.send_sms.from_number.required">Sms is required</span>
          <template v-else-if="api.send_sms.validation_errors.from_number">
            <span v-for="(em,i) in api.send_sms.validation_errors.from_number" :key="i">{{em}}</span>
          </template>
        </p>
      </div>
      <b-button :disabled="api.send_sms.send" type="submit" class="noStyleButton" variant="primary" @click="sendSms()">
        <vb-icon icon="latest-sendSMS-icon" width="59.997px" height="59.997px" />
      </b-button>
    </div>
    <div v-else-if="!conditions.have_balance">You don't have balance</div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import required from 'vuelidate/lib/validators/required'
import maxLength from 'vuelidate/lib/validators/maxLength'
import _ from 'lodash'
export default {
  name: 'SMSAction',
  props: {
    otherNumber: {
      type: String,
      default: ''
    },
    vonageNumbers: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      forms: {
        send_sms: this.$helperFunction.formInstance({
          data: {
            sms: '',
            from_number: '',
          },
        }),
      },
      api: {
        fetch_balance_inquiry: this.$helperFunction.apiInstance({
          status: true
        }),
        send_sms: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
      },
      conditions: {
        have_balance: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  validations: {
    forms: {
      send_sms: {
        sms: {
          required: required,
          maxLength: maxLength(160),
        },
        from_number: {
          required: required,
        },
      },
    },
  },
  watch: {
    "otherNumber":{
      immediate: true,
      handler(){
        this.balanceInquiryDebounce()
      }
    },
    "vonageNumbers": {
      immediate: true,
      handler(value){
        if(value.length==1) this.forms.send_sms.from_number=value[0]
        else if (!value.includes(this.forms.send_sms.from_number)) this.forms.send_sms.from_number=value[0]
      },
    },
  },
  methods: {
    balanceInquiryDebounce: _.debounce(function(){
      this.balanceInquiry()
    }, 1*1000),
    async balanceInquiry(){
      try {
        this.api.fetch_balance_inquiry.send=true
        this.conditions.have_balance=false
        const { data } = await VOIP_API.endpoints.sms.balanceInquiry({
          uid: this.getCurrentUser?.uid,
          number: this.otherNumber,
        })
        this.conditions.have_balance=data?.sms_balance>0
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_balance_inquiry.send=false
      }
    },
    async sendSms() {
      this.forms.send_sms.submitted=true
      this.$v.forms.send_sms.$touch()
      if(this.api.send_sms.send || this.$v.forms.send_sms.$invalid || !this.conditions.have_balance) return;
      try {
        this.api.send_sms.send=true
        this.api.send_sms.validation_errors={}
        const { data } = await VOIP_API.endpoints.sms.sendsms({
          from_number: this.forms.send_sms.from_number,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
          to: this.otherNumber,
          from: this.getCurrentUser?.account,
          text: this.forms.send_sms.sms,
        })
        if(data?.message) this.$emit('sms-send',data?.message)
        this.forms.send_sms.sms=''
        this.conditions.have_balance=data?.checkBalance?.balanceAfterDeduction>0
      } catch (ex) {
        this.api.send_sms.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.send_sms.send=false
        this.forms.send_sms.submitted=false
      }
    }
  },
}
</script>

<style>

</style>