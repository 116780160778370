<template>
  <div class="latestMonitorDashboardBox-row" @click="$emit('clicked')">
    <template v-if="mode=='user'">
      <template v-if="isLive">
        <div class="latestMonitorDashboardBox-column one">
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-if="call.type=='incoming'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          <Info :id="call.accountcode" />
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{duration | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <template v-if="extensionInfo">
            <vb-icon class="mr-12px" :icon="extensionInfo | extensionIcon" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{extensionInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.accountcode==getCurrentUser.sip.user" class="newThisDeviceIndicaterTextBox-25-11-2023 ml-0">This Device</div>
          <b-dropdown 
            v-else
            :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send"
            v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
            varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
          >
            <template #button-content>
              <span class="mr-8px">Listen in</span>
              <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
            </template>
            <b-dropdown-item @click.stop="calling(call,'listen')">Listen</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'whisper')">Whisper</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'barge')">Barge</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{ call.created_at | filter_date_current('',{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' }) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="latestMonitorDashboardBox-column one">
          <Info :id="call.accountcode" />
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div v-if="call.bill_sec" class="latestMonitorDashboardBox-text">{{call | get_property('bill_sec') | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <template v-if="extensionInfo">
            <vb-icon class="mr-12px" :icon="extensionInfo | extensionIcon" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{extensionInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.soundFile" class="d-flex align-items-center">
            <button @click.stop="$emit('click-play',{ soundFile: call.soundFile })" class="border-0 monitorDashboardIconsButton mr-8px">
              <vb-icon icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
            </button>
            <button @click.stop="$emit('ai-modal')" class="border-0 monitorDashboardIconsButton">
              <vb-icon icon="ai-audio-icon" width="26px" height="24px" class="cursor_pointer_no_hover ai-audio-icon" />
            </button>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' })}}</div>
        </div>
      </template>
    </template>
    <template v-else-if="mode=='extension'">
      <template v-if="isLive">
        <div class="latestMonitorDashboardBox-column one">
          <template v-if="extensionInfo">
            <vb-icon class="mr-12px" :icon="extensionInfo | extensionIcon" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{extensionInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{duration | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.accountcode==getCurrentUser.sip.user" class="newThisDeviceIndicaterTextBox-25-11-2023 ml-0">This Device</div>
          <b-dropdown 
            v-else
            :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send"
            v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
            varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
          >
            <template #button-content>
              <span class="mr-8px">Listen in</span>
              <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
            </template>
            <b-dropdown-item @click.stop="calling(call,'listen')">Listen</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'whisper')">Whisper</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'barge')">Barge</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{ call.created_at | filter_date_current('',{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' }) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="latestMonitorDashboardBox-column one">
          <template v-if="extensionInfo">
            <vb-icon class="mr-12px" :icon="extensionInfo | extensionIcon" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{extensionInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div v-if="call.bill_sec" class="latestMonitorDashboardBox-text">{{call | get_property('bill_sec') | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.soundFile" class="d-flex align-items-center">
            <button @click.stop="$emit('click-play',{ soundFile: call.soundFile })" class="border-0 monitorDashboardIconsButton mr-8px">
              <vb-icon icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
            </button>
            <button @click.stop="$emit('ai-modal')" class="border-0 monitorDashboardIconsButton">
              <vb-icon icon="ai-audio-icon" width="26px" height="24px" class="cursor_pointer_no_hover ai-audio-icon" />
            </button>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' })}}</div>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="isLive">
        <div class="latestMonitorDashboardBox-column one">
          <vb-icon v-if="call.type!='outgoing'" class="latestMonitorDashboardBox-firstColumn-icon" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon v-else class="latestMonitorDashboardBox-firstColumn-icon" :class="{ 'ringing': call.status=='Ringing' }" icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          <div class="latestMonitorDashboardBox-text">
            <Info :id="call.accountcode" :only_name="true" />
            {{ call.status=='Ring' ? 'dialing a call to' : call.status=='Ringing' ? 'ringing a call from' : 'is in call with' }}
            <span class="darkerColor">{{call.number | number_formater}}</span>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{duration | time_audio}}</div>
          <div v-if="call.status=='Ringing'" class="monitorDashboard-ringing-container mr-8px">
            <span class="monitorDashboard-ringing-text">Ringing</span>
            <span class="monitorDashboard-ringing-circles">
              <span class="monitorDashboard-ringing-circle one"></span>
              <span class="monitorDashboard-ringing-circle two"></span>
              <span class="monitorDashboard-ringing-circle three"></span>
            </span>
          </div>
          <div v-if="call.status=='Ring'" class="monitorDashboard-ringing-container mr-8px">
            <span class="monitorDashboard-ringing-text">Dialing</span>
            <span class="monitorDashboard-ringing-circles">
              <span class="monitorDashboard-ringing-circle one"></span>
              <span class="monitorDashboard-ringing-circle two"></span>
              <span class="monitorDashboard-ringing-circle three"></span>
            </span>
          </div>
          <!-- Hold -->
          <div v-if="call.hold" class="latestMonitorDashboardBox-text ml-8px">
            <vb-icon v-b-tooltip title="Call is on hold" icon="monitorDashboard-holdCall-icon" height="25px" width="25px" />
          </div>
          <!-- Recording -->
          <div v-if="call.recording" class="latestMonitorDashboardBox-text ml-8px">
            <vb-icon v-b-tooltip title="Call is being recorded" icon="monitorDashboard-recordedCall-icon" height="25px" width="25px" />
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <vb-icon v-if="extensionInfo" class="mr-12px" :icon="extensionInfo | extensionIcon" v-b-tooltip :title="extensionInfo.accountname" width="20.219px" height="24.997px" />
          <div v-if="call.via" class="latestMonitorDashboardBox-text">
            {{ call.type=='outgoing' ? 'Via' : 'Called' }} <img :src="call.via | number_formater | flag_icon" /> {{call.via | number_formater}} 
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.accountcode==getCurrentUser.sip.user" class="newThisDeviceIndicaterTextBox-25-11-2023 ml-0">This Device</div>
          <b-dropdown 
            v-else
            :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" 
            v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
            varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
          >
            <template #button-content>
              <span class="mr-8px">Listen in</span>
              <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
            </template>
            <b-dropdown-item @click.stop="calling(call,'listen')">Listen</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'whisper')">Whisper</b-dropdown-item>
            <b-dropdown-item @click.stop="calling(call,'barge')">Barge</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{ call.created_at | filter_date_current('',{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' }) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="latestMonitorDashboardBox-column one">
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-if="call.status=='Received'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-else-if="call.status=='Missed'" icon="monitorDashboard-table-missedCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          <div class="latestMonitorDashboardBox-text">
            <Info :id="call.accountcode" :only_name="true" />
            {{call.status=='Missed' ? 'missed a call from' : 'was in call with'}}
            <template v-if="call.call_direction=='outbound'">
              <span v-if="call.dst_type=='Number'" class="darkerColor">{{call.dst_number | number_formater}}</span>
              <span v-else class="darkerColor">{{ (call.dst_name || call.dst_number) | number_formater }}{{ call.dst_extn ? ` (${call.dst_extn})` : '' }}</span>
            </template>
            <template v-else>
              <span v-if="call.src_type=='Number'" class="darkerColor">{{call.src_number | number_formater}}</span>
              <span v-else class="darkerColor">{{ (call.src_name || call.src_number) | number_formater }}{{ call.src_extn ? ` (${call.src_extn})` : '' }}</span>
            </template>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{call | get_property('bill_sec')}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <vb-icon v-if="extensionInfo" class="mr-12px" :icon="extensionInfo | extensionIcon" v-b-tooltip :title="extensionInfo.accountname" width="20.219px" height="24.997px" />
          <div v-if="call.src_number && call.call_direction=='outbound'" class="latestMonitorDashboardBox-text">
            Via <img :src="call.src_number | number_formater | flag_icon" /> {{call.src_number | number_formater}}
          </div>
          <div v-else-if="call.dst_number && call.call_direction=='inbound'" class="latestMonitorDashboardBox-text">
            Called <img :src="call.dst_number | number_formater | flag_icon" /> {{call.dst_number | number_formater}} 
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <template v-if="call.soundFile">
            <div class="d-flex align-items-center">
              <button class="monitorDashboardIconsButton mr-8px" @click.stop="$emit('click-play',{ soundFile: call.soundFile })">
                <vb-icon icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
              </button>
              <button class="monitorDashboardIconsButton" @click.stop="$emit('ai-modal')">
                <vb-icon icon="ai-audio-icon" width="26px" height="24px" class="cursor_pointer_no_hover ai-audio-icon" />
              </button>
            </div>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' })}}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { events } from '@/utils'
import Info from '../Lists/Info.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
let interval_instance = null
export default {
  name: 'ActiveCallItem',
  components: { 
    Info 
  },
  inject: [
    'appNotify',
  ],
  props: {
    call: {
      type: Object,
      default: ()=>({}),
    },
    extension_data: {
      type: Object,
      default: ()=>({}),
    },
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      duration: 0,
      api: {
        calling: this.$helperFunction.apiInstance(),
      },
    }
  },
  filters: {
    extensionIcon(val) {
      switch (val.accounttype) {
        case 'WEBEXT':
          return 'devices-webExtension-icon'
        case 'DESKTOPEXT':
          return 'devices-desktopExtension-icon'
        case 'MOBILEEXT':
          return 'devices-mobileExtension-icon'
        case 'HEADEXT':
          return 'devices-headExtension-icon'
        case 'INTEGRATIONEXT':
          return 'devices-headExtension-icon'
        case 'EXTERNALEXT':
          return 'devices-headExtension-icon'
        default:
          return 'devices-headExtension-icon'
      }
    },
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
    ]),
    isLive(){ return this.call.ami },
    extensionInfo(){ return this.extension_data?.[this.call?.extension_accountcode || this.call?.accountcode] },
  },
  watch: {
    "call.answered_at": {
      immediate: true,
      handler(value){
        let vm = this
        if(value) {
          interval_instance = setInterval(()=>{
            console.log('run')
            if(vm._isDestroyed) {
              clearInterval(interval_instance)
            } else {
              vm.duration=moment.unix(moment.utc().unix()).diff(moment.unix(this.call.answered_at), 'seconds')
            }
          },1*1000)
        }
      }
    },
  },
  methods: {
    async calling(call,type='listen'){
      if(this.api.calling.send || !this.extensionInfo) return;
      try {
        this.api.calling.send=true
        this.$root.$emit(events.audio_call,{
          call_type: type,
          number: this.extensionInfo.extn,
          callee_id: this.extensionInfo.user_ext,
          callee_type: 'user',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.calling.send=false
      }
    },
  },
  beforeDestroy(){
    clearInterval(interval_instance)
  },
}
</script>

<style>

</style>