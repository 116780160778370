<template>
  <div class="dialer-settings-main"> 
    <section v-if="getIsMobile" class="dialer-settings-section locationSettings ">
      <div class="d-flex align-items-center mb-3 calc-title-width">
        <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer" @click="$emit('openSideBar')" />
        <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">My tasks</h2>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText ml-auto">
          <b-dropdown right>
            <template #button-content> 
              <div class="d-flex align-items-center">
                <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack sm-mar-left" scale="1.5" />
              </div>
            </template>
            <b-dropdown-item @click="exportTasks()">Export</b-dropdown-item>
            <b-dropdown-item @click="api.import_tasks.send?'':$refs['upload_file'].click()">
              <input 
                ref="upload_file" 
                type="file" 
                v-show="false" 
                :multiple="false" 
                :disabled="api.import_tasks.send"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="importTasks(getProperty($event,'target.files[0]'))" 
              />
              <template v-if="api.import_tasks.upload_progress.is_process">
                <b-popover show top target="popover-button-open">
                  {{ `${api.import_tasks.upload_progress.percentage}%` }} imported
                </b-popover>
              </template>
              <template v-else>Import</template>
            </b-dropdown-item>
            <b-dropdown-item 
              @click="$modal.show('SelectFilterOfTasks',{
                assignees: getTasksFilters.assignees,
                statuses: getTasksFilters.statuses,
                groups: getTasksFilters.groups,
                priorities: getTasksFilters.priorities,
                space_ids: spaceIds,
              })"
            >
              Filter
            </b-dropdown-item>
            <b-dropdown-item 
              @click="
                filter.tasks.assignees=[];
                filter.tasks.statuses=[];
                filter.tasks.groups=[];
                filter.tasks.priorities=[];
                filter.tasks.filter_id='';
                getTasks(true);
              "
            >
              Clear filters
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top">
        <div class="whiteBGinputWithGreyRoundedBorder seachBox">
          <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
          <b-input type="text" v-model="filter.tasks.search" @input="getTasksDebounce()" placeholder="Search tasks"  />
        </div>
      </div>
      <div v-for="data in taskGroup" :key="data.space.id" class="bill-task-list">
        <div class="statusDevider d-flex justify-content-between bill-space-container">
          <div class="d-flex align-items-center">
            <div v-if="data.space.color" class="statusColorBox mr-2 ml-0" :style="`background-color: ${data.space.color}`"></div>
            <span>{{ data.space.name }}</span>
          </div>
        </div>
        <div class="allTasksList-container bill-task-list-view">
          <AddTask v-if="isEmpty(data.result)" :space_id="data.space.id" :spaces="spaces" @created="getTasksDebounce()" />
          <div v-for="column in data.result" :key="column.filter.key" class="bill-column">
            <div class="statusDevider d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <span>{{ column.text }}</span>
                <div v-if="column.color" class="statusColorBox" :style="`background-color: ${column.color}`" />
              </div>
            </div>
            <AddTask :key="`add-task-${column.filter.grouping_by}-${column.filter.key}`" :space_id="data.space.id" :spaces="spaces" :id="column.filter.key" :id_type="column.filter.grouping_by" @created="getTasksDebounce()" />
            <TaskItem 
              v-for="task in column.tasks" 
              :key="task.id" 
              :task="task" 
              @taskClick="$modal.show(`${_uid}-EditTasks`,{ 
                id: task.id 
              })" 
              @deleted="$delete(response.tasks,$event.task_id)"
            />
          </div>
        </div>
      </div>
    </section>
    <section v-else class="dialer-settings-section locationSettings newTasksModule">
      <div class="taskHub-firstSection-grid">
        <h2 class="dialer-settings-title d-flex align-items-center">
          <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" @click="$emit('openSideBar')" />
          My tasks
        </h2>
        <div class="whiteBGinputWithGreyRoundedBorder ml-auto seachBox">
          <b-input type="text" v-model="filter.tasks.search" @input="getTasksDebounce()" placeholder="Search tasks"  />
        </div>
        <span class="dialer-settings-intro newerTextVersion mb-0">
          Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. 
          Streamline your workflow for enhanced productivity.
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-end w-100 mt-45px mb-10px">
        <div v-b-popover.hover.top="'Export'" class="mr-12px cursor_pointer_no_hover" @click="exportTasks()">
          <vb-icon icon="taskHub-export-icon" width="50px" height="50px" />
        </div>
        <div v-b-popover.hover.top="'Import'" class="mr-12px cursor_pointer_no_hover" @click="api.import_tasks.send?'':$refs['upload_file'].click()">
          <input 
            ref="upload_file" 
            type="file" 
            v-show="false" 
            :multiple="false" 
            :disabled="api.import_tasks.send"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="importTasks(getProperty($event,'target.files[0]'))" 
          />
          <vb-icon id="popover-button-open" icon="taskHub-import-icon" width="50px" height="50px" />
          <template v-if="api.import_tasks.upload_progress.is_process">
            <b-popover show top target="popover-button-open">
              {{ `${api.import_tasks.upload_progress.percentage}%` }}
            </b-popover>
          </template>
        </div>
        <div v-if="conditions.board">
          <b-icon v-if="api.sync_task_updates.send" animation="throb" icon="circle-fill" />
          <div v-else-if="forms.sync_task_updates.activity.length">not synced</div>
          <div v-else-if="api.sync_task_updates.status==1">synced</div>
          <div v-else-if="api.sync_task_updates.status==2">{{api.sync_task_updates.error_message}}</div>
        </div>
        <!-- board -->
        <div class="mr-12px cursor_pointer_no_hover compactViewContainer" @click="conditions.board?'':filter.tasks.group_by=groupBy.status.value;conditions.board=!conditions.board;conditions.compact=true">
          <vb-icon v-if="conditions.board" icon="todo-CanbanView-icon" height="50px" width="50px" />
          <vb-icon v-else icon="todo-compact-icon" height="50px" width="50px" />
        </div>
        <!-- compact -->
        <div v-if="!conditions.board" class="mr-12px cursor_pointer_no_hover compactViewContainer" @click="conditions.compact=!conditions.compact;">
          <vb-icon v-if="conditions.compact" icon="todo-comapactUPDATED-icon" height="50px" width="50px" />
          <vb-icon v-else icon="todo-NOTcomapactUPDATED-icon" height="50px" width="50px" />
        </div>
        <!-- select filter -->
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion ul-width-fit-content mr-12px">
          <vb-select 
            v-model="filter.tasks.filter_id"
            :disabled="false"
            @change="getTasks(true)"
            :options="response.filters"
            textField="label"
            valueField="id"
            :isCheckShow="true"
            :defaultSelectedText="'Select filter'"
          >
            <template #option="{ option }">
              <div>
                <b-icon v-if="filter.tasks.filter_id==option.id" icon="check" variant="success" />
                {{ option.label }}
              </div>
              <div class="d-flex">
                <b-spinner v-if="api.delete_filter.send.includes(option.id) || api.toggle_default_filter.send.includes(option.id)" type="grow" label="Spinning" />
                <template v-else>
                  <b-button class="p-0 ml-8px" :disabled="option.status==1" @click.stop="markDefaultFilter(option)" variant="link">
                    <b-icon v-if="option.status==1" icon="circle-fill" variant="success" />
                    <b-icon v-else icon="circle" />
                  </b-button>
                  <b-button class="p-0 ml-8px" :disabled="filter.tasks.filter_id==option.id" @click.stop="deleteFilter(option)" variant="link">
                    <b-icon icon="trash-fill" variant="danger" />
                  </b-button>
                </template>
              </div>
            </template>
          </vb-select>
        </div>
        <!-- open filter model -->
        <div class="mr-12px cursor_pointer_no_hover" @click="$modal.show('SelectFilterOfTasks',{
          assignees: getTasksFilters.assignees,
          statuses: getTasksFilters.statuses,
          groups: getTasksFilters.groups,
          priorities: getTasksFilters.priorities,
          space_ids: spaceIds,
        })">
          <!-- Filters -->
          <vb-icon icon="todo-filter-icon" height="50px" width="50px" />
        </div>
        <!-- clear filter -->
        <div 
          class="mr-12px cursor_pointer_no_hover" 
          @click="
            filter.tasks.assignees=[];
            filter.tasks.statuses=[];
            filter.tasks.groups=[];
            filter.tasks.priorities=[];
            filter.tasks.filter_id='';
            getTasks(true);
          "
        >
          <!-- Clear Filters -->
          <vb-icon icon="clearFilters-icon" height="50px" width="50px" />
        </div>
        <!-- group by -->
        <b-dropdown class="filterDropdownForTaskHub" right>
          <template #button-content>
            <vb-icon icon="callInfoModal-funnel-icon" class="funnel-icon" height="16px" width="16px" />
            Group by: <span>{{ filter.tasks.group_by }}</span>
          </template>
          <b-dropdown-form @submit.prevent="''" class="inputItemContainer">
            <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100">
              <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                <vb-icon icon="callActivity-search" class="greySerchIcon" height="18px" width="17.75px" />
                <b-input placeholder="Search filters..." v-model="filter.group_by.search" />
              </div>
            </div>
          </b-dropdown-form>
          <b-dropdown-item :class="`${filter.tasks.group_by==group.value?'active':''}`"
            v-for="group in filterGroupBy" 
            :key="group.value" 
            @click="filter.tasks.group_by=group.value;filter.group_by.search='';"
          >
            {{ group.text }}
            <b-icon v-if="filter.tasks.group_by==group.value" class="darker-chevron-down withoutPath" icon="check-lg" />
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="bill-task-container">
        <div :class="`${conditions.compact?'TODO-compactView':''}`">
          <div v-for="data in taskGroup" :key="data.space.id" class="bill-task-list">
            <div class="statusDevider d-flex justify-content-between bill-space-container">
              <div class="d-flex align-items-center">
                <div v-if="data.space.color" class="statusColorBox mr-2 ml-0" :style="`background-color: ${data.space.color}`"></div>
                <span>{{ data.space.name }}</span>
              </div>
              <b-button class="bill-button" v-b-toggle="`collapse-${_uid}-${data.space.id}`" variant="link">
                <span class="bill-down"><b-icon icon="chevron-down" /></span>
                <span class="bill-up"><b-icon icon="chevron-up" /></span>
              </b-button>
            </div>
            <b-collapse visible :id="`collapse-${_uid}-${data.space.id}`">
              <div v-if="conditions.board" class="bill-board">
                <div v-for="column in data.result" :key="column.filter.key" class="bill-bill-board-group">
                  <div class="statusDevider">
                    <div v-if="column.color" class="statusColorBox mr-2" :style="`background-color: ${column.color};`"></div>
                    <span>{{ column.text }} ({{column.tasks.length}} tasks)</span>
                  </div>
                  <AddTask v-if="flag!='archived'" :key="`add-task-${column.filter.grouping_by}-${column.filter.key}`" :space_id="data.space.id" :spaces="spaces" :id="column.filter.key" :id_type="column.filter.grouping_by" @created="getTasksDebounce()" />
                  <div 
                    @dragenter="$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display='flex'"
                    class="bill-board-group-container"
                  >
                    <div 
                      :ref="`column-${data.space.id}-${column.filter.key}`" 
                      class="drop-here"
                      @dragover.prevent="!response.tasks[drag.task_id] || response.tasks[drag.task_id].space_id!=column.space.id ? $event.dataTransfer.dropEffect = 'none' : ''"
                      @dragleave="$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display='none'" 
                      @drop="$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display='none';drop($event, column)" 
                    >
                      Drop Here
                    </div>
                    <div v-if="column.tasks.length==0" class="d-flex justify-content-center">No Tasks</div>
                    <TaskItem 
                      :id="`task-id-${task.id}`"
                      :type="'board'"
                      @dragstart="drag.task_id=task.id;$event.dataTransfer.dropEffect = 'move';"
                      @dragend="drag.task_id='';"
                      v-for="task in column.tasks" 
                      :key="task.id" 
                      :task="task" 
                      @taskClick="$modal.show(`${_uid}-EditTasks`,{ 
                        id: task.id 
                      })" 
                      @deleted="$delete(response.tasks,$event.task_id)"
                    />
                  </div>
                </div>
              </div>
              <template v-else>
                <div class="TODO-taksTable-titleRow mt-38px">
                  <div class="TODO-taksTable-titleRow-column first">
                    <div class="TODO-taksTable-titleRow-column-text">TASK TITLE</div>
                  </div>
                  <div class="TODO-taksTable-titleRow-column second">
                    <div class="TODO-taksTable-titleRow-column-text">ASSIGNEES</div>
                  </div>
                  <div class="TODO-taksTable-titleRow-column third">
                    <div class="TODO-taksTable-titleRow-column-text">STATUS</div>
                  </div>
                  <div class="TODO-taksTable-titleRow-column fourth" ></div>
                </div>
                <div class="allTasksList-container bill-task-list-view">
                  <AddTask v-if="isEmpty(data.result) && flag!='archived'" :space_id="data.space.id" :spaces="spaces" @created="getTasksDebounce()" />
                  <div v-for="column in data.result" :key="column.filter.key" class="bill-column">
                    <div class="statusDevider d-flex justify-content-between">
                      <div class="d-flex align-items-center">
                        <span>{{ column.text }}</span>
                        <div v-if="column.color" class="statusColorBox" :style="`background-color: ${column.color}`" />
                      </div>
                      <b-button class="bill-button" v-b-toggle="`collapse-${_uid}-${data.space.id}-${column.filter.key}`" variant="link">
                        <span class="bill-down"><b-icon icon="chevron-down" /></span>
                        <span class="bill-up"><b-icon icon="chevron-up" /></span>
                      </b-button>
                    </div>
                    <b-collapse visible :id="`collapse-${_uid}-${data.space.id}-${column.filter.key}`">
                      <AddTask v-if="flag!='archived'" :key="`add-task-${column.filter.grouping_by}-${column.filter.key}`" :space_id="data.space.id" :spaces="spaces" :id="column.filter.key" :id_type="column.filter.grouping_by" @created="getTasksDebounce()" />
                      <TaskItem 
                        v-for="task in column.tasks" 
                        :key="task.id" 
                        :task="task" 
                        @taskClick="$modal.show(`${_uid}-EditTasks`,{ 
                          id: task.id 
                        })" 
                        @deleted="$delete(response.tasks,$event.task_id)"
                      />
                    </b-collapse>
                  </div>
                  <div style="height: 50px;" />
                </div>
              </template>
            </b-collapse>
          </div>
        </div>
      </div>
    </section>
    <EditTasksModal :modalName="`${_uid}-EditTasks`" @update-list="getTasks(true)" />
    <SelectFilterOfTasksModal 
      @apply-filter="
        filter.tasks.assignees=$event.assignees;
        filter.tasks.statuses=$event.statuses;
        filter.tasks.groups=$event.groups;
        filter.tasks.priorities=$event.priorities;
        filter.tasks.filter_id=$event.filter_id;
        getTasks(true);
      " 
      @added="getFilters(filter.tasks.filter_id)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'
import { VOIP_API } from '@/utils'
import TaskItem from './TaskItem.vue'
import AddTask from './AddTask.vue'
import EditTasksModal from '../Modals/Todo/EditTasksModal.vue'
import SelectFilterOfTasksModal from '../Modals/Todo/SelectFilterOfTasksModal.vue'
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters'
const group_by = {
  tasks: {
    text: 'Tasks',
    value: 'tasks',
    board: false,
  },
  status: {
    text: 'Status',
    value: 'status',
    board: true,
  },
  groups: {
    text: 'Groups',
    value: 'groups',
    board: true,
  },
  priorities: {
    text: 'Priorities',
    value: 'priorities',
    board: true,
  },
  due_date: {
    text: 'Due dates',
    value: 'due_date',
    board: false,
  },
}
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: 'NewTasks',
  components: { 
    TaskItem,
    AddTask,
    EditTasksModal,
    SelectFilterOfTasksModal,
  },
  inject: [
    'isEmpty',
    'getProperty',
    'appNotify',
  ],
  props: {
    spaces: {
      type: Object,
      default: ()=>({})
    },
    flag: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api: {
        tasks: this.$helperFunction.apiInstance({ 
          source: true, 
        }),
        fetch_filters: this.$helperFunction.apiInstance(),
        delete_filter: this.$helperFunction.apiInstance({
          send: [],
        }),
        export_tasks: this.$helperFunction.apiInstance(),
        import_tasks: this.$helperFunction.apiInstance({
          upload_progress: true,
        }),
        toggle_default_filter: this.$helperFunction.apiInstance({
          send: [],
        }),
        get_group_statuses: this.$helperFunction.apiInstance(),
        sync_task_updates: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
        }),
      },
      response: {
        tasks: {},
        filters: [],
        statuses: {},
        groups: {},
        users: [],
      },
      paginations: {
        tasks: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
      filter: {
        tasks: {
          search: '',
          group_by: group_by.tasks.value,
          assignees: [],
          statuses: [],
          groups: [],
          priorities: [],
          filter_id: '',
        },
        group_by: {
          search: '',
        },
      },
      conditions: {
        compact: true,
        board: false,
      },
      drag: {
        task_id: '',
      },
      forms: {
        sync_task_updates: this.$helperFunction.formInstance({
          data: {
            activity: [],
          },
        }),
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    spaceIds(){ return Object.keys(this.spaces) },
    groupBy(){ return group_by },
    filterGroupBy(){ return Object.values(this.groupBy).filter(i=>this.conditions.board ? i.board : true && i.text.trim().toLowerCase().includes(this.filter.group_by.search.trim().toLowerCase())) },
    tasks(){ return _.orderBy(Object.values(this.response.tasks), ['id'], ['desc']); },
    taskGroup(){
      let result = [], grouping_by = ''
      if(this.filter.tasks.group_by==group_by.groups.value) grouping_by='group'
      else if(this.filter.tasks.group_by==group_by.status.value) grouping_by='status'
      else if(this.filter.tasks.group_by==group_by.priorities.value) grouping_by='priority'
      else if(this.filter.tasks.group_by==group_by.due_date.value) grouping_by='due_date'
      else grouping_by='task'
      const groups = this.response.groups ?? {}
      const statuses = this.response.statuses ?? {}
      const spaces = this.spaces ?? {}
      const tasks = this.tasks
      const space_by_groups = _.groupBy(groups,'space_id')
      const space_by_statuses = _.groupBy(statuses,'space_id')
      const space_by_tasks = _.groupBy(tasks,'space_id')
      result = Object.values(spaces).map((space)=>{
        let grouping = {}, group_result = [], prop_tasks = space_by_tasks[space.id] ?? [], prop_groups = space_by_groups[space.id] ?? [], prop_statuses = space_by_statuses[space.id] ?? []
        if(grouping_by=='group') grouping = _.groupBy(prop_tasks,'group.id')
        else if(grouping_by=='status') grouping = _.groupBy(prop_tasks,'status.id')
        else if(grouping_by=='priority') grouping = _.groupBy(prop_tasks,'priority')
        else if(grouping_by=='due_date') grouping = _.groupBy(prop_tasks,(item) => item.due_date ? moment(item.due_date, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD') : undefined)
        else grouping = _.groupBy(prop_tasks,'')
        if(grouping_by=='group') group_result = [...prop_groups,undefined].map(i=>({
          filter: {
            priority: '',
            key: i?.id ?? '',
            grouping_by: grouping_by,
            status_type: null,
            text: i?.name ?? undefined,
          },
          text: i?.name ?? 'No Group',
          color: i?.color ?? '',
          space: spaces[i?.space_id] ?? spaces[grouping[i?.id]?.[0]?.space_id] ?? space,
          tasks: grouping[i?.id] ?? []
        }))
        else if(grouping_by=='priority') group_result = [...Object.values(priorities)].map(i=>({
          filter: {
            priority: i?.value,
            key: i?.value ?? '',
            grouping_by: grouping_by,
            status_type: null,
            text: i?.text ?? undefined,
          },
          text: i?.text ?? 'No Priority',
          color: i?.color ?? '',
          space: spaces[grouping[i?.value]?.[0]?.space_id] ?? space,
          tasks: grouping[i?.value] ?? []
        }))
        else if(grouping_by=='status') group_result = [...prop_statuses].map(i=>({
          filter: {
            priority: '',
            key: i?.id ?? '',
            grouping_by: grouping_by,
            status_type: i?.type ?? null,
            text: i?.title ?? undefined,
          },
          text: i?.title ?? 'No Status',
          color: i?.color ?? '',
          space: spaces[i?.space_id] ?? space,
          tasks: grouping[i?.id] ?? []
        }))
        else if(grouping_by=='due_date') group_result = [...Object.keys(grouping)].map(i=>({
          filter: {
            priority: '',
            key: i ?? '',
            grouping_by: grouping_by,
            status_type: null,
            text: i ?? undefined,
          },
          text: (i=='undefined' ? undefined : i) ?? 'No Due Date',
          color: '',
          space: spaces[grouping[i]?.[0]?.space_id] ?? space,
          tasks: grouping[i] ?? []
        }))
        else group_result = [...Object.keys(grouping)].map(i=>({
          filter: {
            priority: '',
            key: '',
            grouping_by: grouping_by,
            status_type: null,
            text: undefined,
          },
          text: '',
          color: '',
          space: spaces[grouping[i]?.[0]?.space_id] ?? space,
          tasks: grouping[i] ?? []
        }))
        if(grouping_by=='priority') group_result.sort((a,v)=>{
          if(a.filter.key=='high') return -1
          else if(a.filter.key=='mid' && v.filter.key!='high') return -1
          else if(a.filter.key=='low' && !['high','mid'].includes(v.filter.key)) return -1
          return 0
        })
        else if(grouping_by=='status') group_result.sort((a,b)=>{
          if(b.filter.status_type=='active') return 1
          else if(b.filter.status_type=='closed') return -1
          else return 0
        })
        else group_result = _.orderBy(group_result, ['filter.text'], 'asc')
        return {
          space: space,
          result: group_result
        } 
      })
      return result
    },
    selectedFilter(){
      return this.response.filters.find(i=>i.id==this.filter.tasks.filter_id)
    },
    getTasksFilters(){
      let filters;
      if(this.selectedFilter){
        filters = JSON.parse(this.selectedFilter.filters)
      }
      return {
        assignees: filters?.assignees ?? this.filter.tasks.assignees ?? [],
        statuses: filters?.statuses ?? this.filter.tasks.statuses ?? [],
        groups: filters?.groups ?? this.filter.tasks.groups ?? [],
        priorities: filters?.priorities ?? this.filter.tasks.priorities ?? [],
      }
    },
  },
  watch: {
    spaceIds() {
      this.load()
    },
    flag() {
      this.getTasks(true)
    },
  },
  methods: {
    load(){
      this.filter.tasks.assignees=[]
      this.filter.tasks.groups=[]
      this.filter.tasks.priorities=[]
      this.filter.tasks.statuses=[]
      this.filter.tasks.filter_id=''
      this.filter.tasks.search=''
      this.getFilters()
      this.fetchGroupsAndStatuses();
    },
    drop(event,column) {
      const task_id = this.drag.task_id
      if(!this.response.tasks[task_id]) return;
      let activity = {
        task_id: task_id,
        type: '',
        prev_id: '',
        next_id: column.filter?.key || null,
      }
      if(this.response.tasks[task_id].space_id!=column.space?.id) return;
      if(this.filter.tasks.group_by==group_by.groups.value) activity.type='group'
      else if(this.filter.tasks.group_by==group_by.priorities.value) activity.type='priority'
      else activity.type='status'
      if(activity.type=='group') activity.prev_id = this.response.tasks[task_id]?.group_id ?? null
      else if(activity.type=='priority') activity.prev_id = this.response.tasks[task_id]?.priority
      else activity.prev_id = this.response.tasks[task_id]?.status_id
      if(activity.next_id==activity.prev_id) return;
      if(activity.type=='group') {
        this.$set(this.response.tasks[task_id],'group',this.response.groups[activity.next_id] ? JSON.parse(JSON.stringify(this.response.groups[activity.next_id])) : null)
        this.$set(this.response.tasks[task_id],'group_id',activity.next_id)
      } else if(activity.type=='priority') {
        this.$set(this.response.tasks[task_id],'priority',activity.next_id)
      } else {
        this.$set(this.response.tasks[task_id],'status',JSON.parse(JSON.stringify(this.response.statuses[activity.next_id])))
        this.$set(this.response.tasks[task_id],'status_id',activity.next_id)
      }
      this.forms.sync_task_updates.activity.push(activity)
    },
    // tasks
    syncTaskUpdatesDebounce: _.debounce(async function(){
      if(this.api.sync_task_updates.send) return;
      try {
        const activity = JSON.parse(JSON.stringify(this.forms.sync_task_updates.activity))
        if(activity.length<1) return;
        this.api.sync_task_updates.status=0
        this.api.sync_task_updates.error_message=''
        this.api.sync_task_updates.send=true
        const group_by = _.groupBy(activity,'type')
        const updates = {
          status: _.mapValues(_.keyBy(group_by?.status??[],'task_id'),'next_id'),
          group: _.mapValues(_.keyBy(group_by?.group??[],'task_id'),'next_id'),
          priority: _.mapValues(_.keyBy(group_by?.priority??[],'task_id'),'next_id'),
        }
        await VOIP_API.endpoints.tasklist.syncBoard({
          updates: updates,
          activity: activity,
        })
        this.forms.sync_task_updates.activity.splice(0,activity.length)
        this.api.sync_task_updates.status=1
      } catch (ex) {
        this.api.sync_task_updates.status=2
        this.api.sync_task_updates.error_message=ex.own_message || ex.message
      } finally {
        this.api.sync_task_updates.send=false
        this.syncTaskUpdatesDebounce()
      }
    },5*1000),
    getTasksDebounce: _.debounce(function (next){
      this.getTasks(next?false:true)
    }, 2*1000),
    async getTasks() {
      const request_againt_error = "Requesting again"
      this.api.tasks.source?.cancel?.(request_againt_error);
      this.api.tasks.source = require("axios").default.CancelToken.source()
      try {
        this.api.tasks.send=true
        const { data } = await VOIP_API.endpoints.tasklist.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          flag: this.flag,
          space_id: this.spaceIds,
          search: this.filter.tasks.search || null,
          assignee: this.getTasksFilters.assignees,
          status: this.getTasksFilters.statuses,
          groups: this.getTasksFilters.groups,
          priority: this.getTasksFilters.priorities,
          page: this.paginations.tasks.page,
        },this.api.tasks.source.token)
        const tasks = _.keyBy(data ?? [],'id')
        this.response.tasks = tasks
      } catch (ex) {
        if(ex.cancel) return;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.tasks.send=false
      }
    },
    getFurthurTasks(event){
      const height = event.target.scrollHeight;
      const top = event.target.scrollTop;
      const offset_height = event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 25) {
        this.getTasksDebounce(true);
        event.target.scrollTop=top-25
      }
    },
    async exportTasks(){
      if(this.api.export_tasks.send) return;
      try {
        this.api.export_tasks.send=true
        const { data } = await VOIP_API.endpoints.tasklist.export({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          flag: "export",
          space_ids: this.spaceIds,
        })
        const blobUrl = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = `tasks - ${moment().format('DD MMM YYYY hh_mm_ss a')}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.export_tasks.send=false
      }
    },
    async importTasks(file){
      if (this.api.import_tasks.send || !file) return;
      try {
        this.api.import_tasks.send = true;
        const form_data = new FormData();
        form_data.append("accountcode", this.getCurrentUser?.account);
        form_data.append("uid", this.getCurrentUser?.uid);
        form_data.append("space_ids", this.spaceIds);
        form_data.append("flag", 'import');
        form_data.append("file", file);
        await VOIP_API.endpoints.tasklist.import(form_data,this.api.import_tasks.upload_progress.onProgress.bind(this.api.import_tasks.upload_progress));
        this.appNotify({
          message: "Successfully Uploaded",
          type: "success",
        });
        this.fetchGroupsAndStatuses()
        this.getTasks(true)
        this.$emit('imported')
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: "error",
        });
      } finally {
        this.$refs['upload_file'].value=''
        this.api.import_tasks.send = false;
      }
    },
    // statuses and groups
    async fetchGroupsAndStatuses(){
      const request_againt_error = "Requesting again"
      this.api.tasks.source?.cancel?.(request_againt_error);
      this.api.tasks.source = require("axios").default.CancelToken.source()
      try {
        this.api.get_group_statuses.send=true
        const { data } = await VOIP_API.endpoints.taskgroups.getSpaceTaskData({
          space_id: this.spaceIds,
        })
        this.response.users=data?.members ?? []
        this.response.statuses=_.keyBy(data?.status ?? [],'id')
        this.response.groups=_.keyBy(data?.group ?? [],'id')
      } catch (ex) {
        if(ex.cancel) return;
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_group_statuses.send=false
      }
    },
    // filters
    async getFilters(filter_id){
      this.api.fetch_filters.source?.cancel?.("Requesting again");
      this.api.fetch_filters.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_filters.send=true
        const { data } = await VOIP_API.endpoints.tasklist.filters.list({
          accountcode: this.getCurrentUser.account,
          space_ids: JSON.parse(JSON.stringify(this.spaceIds)).sort().join(','),
        },this.api.fetch_filters.source.token)
        const filters = data ?? []
        const default_filter = filters.find(i=>i.status==1)
        this.response.filters=filters
        const selected_filter = filters.find(i=>i.id==this.filter.tasks.filter_id)
        if(filter_id){
          this.filter.tasks.filter_id=filter_id
        } else if(!selected_filter && default_filter) {
          this.filter.tasks.filter_id=default_filter.id ?? ''
        }
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.getTasks(true);
        this.api.fetch_filters.send=false
      }
    },
    async deleteFilter(option){
      const option_id = option?.id ?? ''
      if(!option && this.api.delete_filter.send.includes(option_id)) return;
      try {
        this.api.delete_filter.send.push(option_id)
        await VOIP_API.endpoints.tasklist.filters.remove(option_id)
        this.getFilters()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.delete_filter.send.includes(option_id)){
          this.api.delete_filter.send.splice(this.api.delete_filter.send.indexOf(option_id),1)
        }
      }
    },
    async markDefaultFilter(option){
      const option_id = option?.id ?? ''
      if(!option && this.api.toggle_default_filter.send.includes(option_id)) return;
      try {
        this.api.toggle_default_filter.send.push(option_id)
        await VOIP_API.endpoints.tasklist.filters.makeDefault(option_id,{
          accountcode: this.getCurrentUser.account,
          space_ids: JSON.parse(JSON.stringify(this.spaceIds)).sort().join(','),
          status: '1',
        })
        this.getFilters()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.toggle_default_filter.send.includes(option_id)){
          this.api.toggle_default_filter.send.splice(this.api.toggle_default_filter.send.indexOf(option_id),1)
        }
      }
    },
  },
  created(){
    this.syncTaskUpdatesDebounce()
  },
  mounted() {
    this.load();
  },
  activated(){
    this.load();
  },
}
</script>

<style lang="scss" scoped>
.darkModeV2.dialer-parent {
  &.v2 {
    .bill-task-container {
      .bill-task-list {
        background-color: #232124;
        .collapse {
          .bill-board {
            .bill-bill-board-group {
              background-color: #2f2d30; 
            }
          }
          .bill-task-list-view {
            :deep(.TODO-taksTable-bodyRow) {
              background-color: #2f2d30;
            }
          }
          .TODO-taksTable-titleRow {
            background-color: #2f2d30;
          }
        }
      }
    }
  }
}
.bill-task-container {
  height: auto;
  max-height: calc(100vh - 60px - 50px - 180px);
  overflow-y: auto;
  // border: 8px solid #f3f3f3;
  // border-top: 8px solid white;
  // background-color: #f3f3f3; 
  margin-top: 8px;
  .bill-task-list {
    margin-bottom: 10px; 
    background-color: #f3f3f3; 
    padding: 10px; 
    border-radius: 10px;
    margin-right: 8px;
    .bill-space-container {
      padding-left: 20px;
      .bill-button {
        &.collapsed {
          .bill-up {
            display: none;
          }
        }
        &.not-collapsed {
          .bill-down {
            display: none;
          }
        }
      }
    }
    .collapse {
      .bill-board {
        display: flex; 
        width: inherit; 
        overflow-y: auto;
        .bill-bill-board-group {
          height: inherit; 
          width: 250px; 
          display: flex; 
          flex-direction: column; 
          margin-right: 15px; 
          border-radius: 10px; 
          background-color: #ffffff; 
          padding: 5px;
          .statusDevider {
            padding: 10px; 
            display: flex; 
            justify-content: center; 
            border-radius: 10px; 
            margin-bottom: 0px; 
            width: inherit;
          }
          .bill-board-group-container {
            height: 100%; 
            position: relative; 
            margin-top: 16px; 
            margin-bottom: 16px; 
            overflow: hidden; 
            width: 240px;
            .drop-here {
              display: none; 
              position: absolute; 
              z-index: 2; 
              background-color: rgba(0, 0, 0, 0.2); 
              height: inherit; 
              width: inherit; 
              justify-content: center; 
              align-items: center; 
              border: dashed; 
              border-radius: 10px;
            }
          }
        }
      }
      .bill-task-list-view {
        height: auto !important;
        max-height: none !important;
        :deep(.TODO-taksTable-bodyRow) {
          background-color: #ffffff;
        }
        .bill-column {
          .statusDevider {
            .bill-button {
              &.collapsed {
                .bill-up {
                  display: none;
                }
              }
              &.not-collapsed {
                .bill-down {
                  display: none;
                }
              }
            }
          }
        }
      }
      .TODO-taksTable-titleRow {
        background-color: #ffffff;
      }
    }
  }
}
</style>