<template>
  <modal
    :name="modalName"
    class="dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal linkCallModal bigSquareCheckbox"
    width="50%"
    height="auto"
    :scrollable="true"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <div>
      <div class="dialer-edit-header flex-column align-items-start">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="dialer-settings-title newer d-flex align-items-center">
              <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" :disabled="api.link_call.send" >
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </a>
              Link call
            </h2>
          </div>
          <div class="dialer-edit-actions">
            <b-button @click="linkCall()" :disabled="api.link_call.send" class="newDoneButton">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">Done</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px hasScrollBarInsideIt" style="max-height: calc(100vh - 150px);overflow-y: auto;" @scroll="onScroll($event)">
        <b-form-checkbox 
          :disabled="api.link_call.send"
          v-for="call in response.call_logs" 
          :key="call.id" 
          :checked="selected.cdr_ids.includes(call.call_id)" 
          @change="selected.cdr_ids.includes(call.call_id)?selected.cdr_ids.splice(selected.cdr_ids.indexOf(call.call_id),1):selected.cdr_ids.push(call.call_id)"
        >
          <CallActivityTestItem :call="call" :conditions="{ actions: false }" />
        </b-form-checkbox>
        <vb-no-record v-if="response.call_logs.length==0" :design="3" :text="api.get_call_logs.send?'':'No Calls'">
          <div class="w-100" v-if="api.get_call_logs.send" slot="loading">
            <div class="w-100" v-for="n in 6" :key="n.id">
              <div :class="`latestShimmerDesign w-75 mt-20px ${n%2 == 1 ? 'ml-auto' : 'mr-auto'}`" style="height:75px;"></div>
            </div>
          </div>
        </vb-no-record>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import CallActivityTestItem from '@/components/Home/CallActivity/TestItem/index.vue'
import _ from 'lodash'
export default {
  name: 'LinkedCallModal',
  components: { 
    CallActivityTestItem 
  },
  props: {
    modalName: {
      type: String,
      default: 'LinkedCall'
    },
  },
  data() {
    return {
      api: {
        get_call_logs: this.$helperFunction.apiInstance(),
        link_call: this.$helperFunction.apiInstance(),
      },
      response: {
        call_logs: [],
      },
      data: {
        task_id: '',
      },
      selected: {
        cdr_ids: [],
      },
      paginations: {
        call_logs: {
          page: 1,
          completed: false,
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  methods: {
    onBeforeOpen(event) {
      this.data.task_id=event?.params?.task_id ?? ''
      this.selected.cdr_ids=_.cloneDeep(event?.params?.cdr_ids ?? [])
      this.getCallLogs()
    },
    onBeforeClose() {
      this.api.get_call_logs.reset()
      this.api.link_call.reset()
      this.response.call_logs=[]
      this.data.task_id=''
      this.selected.cdr_ids=[]
      this.paginations.call_logs.page= 1
      this.paginations.call_logs.completed= false
    },
    onScroll(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 2) {
        this.getCallLogs();
      }
    },
    async getCallLogs() {
      if(this.api.get_call_logs.send || this.paginations.call_logs.completed) return;
      try {
        this.api.get_call_logs.send=true
        const { data: { total, data: logs } } = await VOIP_API.endpoints.call_activity.newList({
          accountcode: this.getCurrentUser.account,
          page: this.paginations.call_logs.page,
          clean_data: 'yes',
          start_date: parseInt(this.response.call_logs[this.response.call_logs.length-1]?.date ?? '') || '',
          sip_logs: 'yes',
        })
        const array = _.isArray(logs) ? logs : Object.values(logs);
        this.response.call_logs=_.concat(this.response.call_logs,array)
        this.paginations.call_logs.page=this.paginations.call_logs.page+1
        this.paginations.call_logs.completed=total==this.response.call_logs.length
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_call_logs.send=false
      }
    },
    async linkCall(){
      if(this.api.link_call.send) return;
      try {
        this.api.link_call.send=true
        await VOIP_API.endpoints.tasklist.call_log.updateCallLog({
          task_id: this.data.task_id,
          cdr_ids: this.selected.cdr_ids,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
        })
        this.$emit('updated',{
          cdr_ids: this.selected.cdr_ids
        })
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully Changed',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.link_call.send=false
      }
    },
  },
}
</script>

<style>

</style>




<style lang="scss">
  .squreRadioButtons{
    &.withCallLogInIt{
      &.custom-control.custom-radio{
        padding: 0px;
        margin-bottom: 16px;
        position: relative;
        .custom-control-input:checked ~ .custom-control-label::before{
          background-color: white;
        }
        .custom-control-input:checked ~ .custom-control-label::after{
          background-image: none;
          opacity: 1;
        }
        & > .custom-control-label{
          position: relative;
          width: 100%;
          padding-left: 40px;
          opacity: 0;
          &:has(.visibleTransition){
            opacity: 1;
          }
          &::before{
            left: 0px;
            top: 8px;
            @include border-radius(3px);
            height: 24px;
            width: 24px;
            border: 1px solid black;
          }
          &::after{
            left: 0px;
            top: 8px;
            @include border-radius(3px);
            width: 1.1rem;
            height: 1.1rem;
            // border: 1px solid black; 
            background-color: #000000;   
            margin-top: 3px;
            margin-left: 3px;
            opacity: 0;
          }
          .callActivity-Item-v2-container{
            width: 100%;
            margin-bottom: 0px;
            &:hover{
              .callActivity-Item-v2-inner-top{
                .last-section{
                  .time{
                    & > span{
                      opacity: 1;
                    }
                  }
                }
              }
            }
          } 
        }
      }
    }
  }
  // .linkCallModal.vm--container{
  //   & > .vm--modal{

  //   }
  // }
</style>