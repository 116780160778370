var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.add ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.remove;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add User")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.add.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.add, "search", $$v);
      },
      expression: "filter.add.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_vm.isNotAddedAllCheckShow ? _c('b-form-checkbox', {
    staticClass: "basicWhiteIOScard-item",
    attrs: {
      "checked": _vm.isNotAddedAllCheck
    },
    on: {
      "change": function ($event) {
        return _vm.onSelectAllNotAdded();
      }
    }
  }, [_vm._v(" Select All ")]) : _vm._e(), _vm._l(_vm.searchedNotAddedList, function (user) {
    return _c('b-form-checkbox', {
      key: user.account,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "disabled": _vm.api.add.send,
        "checked": !!_vm.selected.notadded[user.account]
      },
      on: {
        "change": function ($event) {
          return _vm.onChange(user);
        }
      }
    }, [_vm.type == _vm.types.caller_id_shared ? _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": _vm._f("getExtnByNumberHTML")(user.html)
      }
    }) : _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": user.account || user.extn
      }
    })], 1);
  }), _vm.isEmpty(_vm.searchedNotAddedList) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.list.send ? '' : 'There is no User',
      "design": 3
    }
  }, [_vm.api.list.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), Object.keys(_vm.selected.notadded).length > 0 ? _c('b-button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.add.send
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_vm._v(" Add User ")]) : _vm._e()], 1)] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Remove User")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.add;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.remove.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.remove, "search", $$v);
      },
      expression: "filter.remove.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_vm.isAddedAllCheckShow ? _c('b-form-checkbox', {
    staticClass: "basicWhiteIOScard-item",
    attrs: {
      "checked": _vm.isAddedAllCheck,
      "disabled": _vm.api.remove.send
    },
    on: {
      "change": function ($event) {
        return _vm.onSelectAllAdded();
      }
    }
  }, [_vm._v(" Select All ")]) : _vm._e(), _vm._l(_vm.searchedAddedList, function (user) {
    return _c('b-form-checkbox', {
      key: user.account,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "disabled": _vm.api.remove.send,
        "checked": !!_vm.selected.added[user.account]
      },
      on: {
        "change": function ($event) {
          return _vm.onChange(user);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.type == _vm.types.caller_id_shared ? _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": _vm._f("getExtnByNumberHTML")(user.html)
      }
    }) : _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": user.account || user.extn
      }
    }), _vm.type == _vm.types.assign_call_queue ? _c('div', {
      staticClass: "forceSwitchContainer position-relative"
    }, [_c('label', {
      staticClass: "switch"
    }, [_c('input', {
      attrs: {
        "type": "checkbox",
        "disabled": _vm.api.update_member_force_admin.send
      },
      domProps: {
        "checked": user.force == 'yes'
      },
      on: {
        "change": function ($event) {
          return _vm.updateForceAdmin(user, user.account);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })])]) : _vm._e()], 1)]);
  }), _vm.searchedAddedList.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.list.send ? '' : 'There is no User',
      "design": 3
    }
  }, [_vm.api.list.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), !_vm.isEmpty(_vm.selected.added) ? _c('b-button', {
    staticClass: "IosFullWidthButton forRemove mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.remove.send
    },
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  }, [_vm.api.remove.send ? _c('vb-spinner') : _vm._e(), [_vm._v("Remove User")]], 2) : _vm._e()], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }