var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-700 LinkTaskModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button",
      "disabled": _vm.api.assigned_tasks.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Link task ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.assigned_tasks.send || !_vm.isAnyChange
    },
    on: {
      "click": function ($event) {
        return _vm.assignedTask();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)])]), _c('div', {
    staticClass: "d-flex w-100 mt-12px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px"
  }, [_c('label', [_vm._v("Space")]), _c('vb-select', {
    attrs: {
      "options": _vm.response.spaces,
      "disabled": _vm.api.assigned_tasks.send,
      "textField": "name",
      "valueField": "id"
    },
    on: {
      "change": function ($event) {
        _vm.filters.tasks.status_id = '';
        _vm.filters.tasks.group_id = '';
        _vm.getGroups();
        _vm.getStatus();
        _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref) {
        var option = _ref.option,
          onSelect = _ref.onSelect;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [!option ? _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select space")]) : _c('div', [_c('span', [_vm._v(_vm._s(option.name))]), _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return onSelect('');
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          }
        })], 1)], 1)])];
      }
    }, {
      key: "option",
      fn: function (_ref2) {
        var option = _ref2.option;
        return [_c('span', [_vm._v(_vm._s(option.name))])];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no spaces")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.tasks.space_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tasks, "space_id", $$v);
      },
      expression: "filters.tasks.space_id"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px"
  }, [_c('label', [_vm._v("Group")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.assigned_tasks.send,
      "options": _vm.response.groups,
      "textField": "name",
      "valueField": "id"
    },
    on: {
      "change": function ($event) {
        return _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref3) {
        var option = _ref3.option,
          onSelect = _ref3.onSelect;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [!option ? _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select group")]) : _c('div', [_c('span', [_vm._v(_vm._s(option.name))]), _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return onSelect('');
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          }
        })], 1)], 1)])];
      }
    }, {
      key: "option",
      fn: function (_ref4) {
        var option = _ref4.option;
        return [_c('span', [_vm._v(_vm._s(option.name))])];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no groups")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.tasks.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tasks, "group_id", $$v);
      },
      expression: "filters.tasks.group_id"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px"
  }, [_c('label', [_vm._v("Priority")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.assigned_tasks.send,
      "options": Object.values(_vm.priorities)
    },
    on: {
      "change": function ($event) {
        return _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref5) {
        var option = _ref5.option,
          onSelect = _ref5.onSelect;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [!option ? _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select priority")]) : _c('div', [_c('span', [_vm._v(_vm._s(option.text))]), _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return onSelect('');
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          }
        })], 1)], 1)])];
      }
    }, {
      key: "option",
      fn: function (_ref6) {
        var option = _ref6.option;
        return [_c('span', [_vm._v(_vm._s(option.text))])];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no prioritys")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.tasks.priority,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tasks, "priority", $$v);
      },
      expression: "filters.tasks.priority"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px"
  }, [_c('label', [_vm._v("Status")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.assigned_tasks.send,
      "options": _vm.response.statuses,
      "textField": "title",
      "valueField": "id"
    },
    on: {
      "change": function ($event) {
        return _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function (_ref7) {
        var option = _ref7.option,
          onSelect = _ref7.onSelect;
        return [_c('div', {
          staticClass: "d-flex align-content-center justify-content-between w-100 h-100"
        }, [!option ? _c('span', {
          staticClass: "p-0"
        }, [_vm._v("Select status")]) : _c('div', [_c('span', [_vm._v(_vm._s(option.title))]), _c('b-button', {
          staticClass: "align-content-center justify-content-center p-0 m-0 mr-2",
          staticStyle: {
            "line-height": "0"
          },
          attrs: {
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              return onSelect('');
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x",
            "scale": "1.5"
          }
        })], 1)], 1)])];
      }
    }, {
      key: "option",
      fn: function (_ref8) {
        var option = _ref8.option;
        return [_c('span', [_vm._v(_vm._s(option.title))])];
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_vm._v("There is no statuses")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.tasks.status_id,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tasks, "status_id", $$v);
      },
      expression: "filters.tasks.status_id"
    }
  })], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_vm.response.tasks.length == 0 ? _c('vb-no-record', {
    attrs: {
      "design": 3,
      "text": _vm.api.fetch_tasks.send ? '' : 'No Task'
    }
  }, [_vm.api.fetch_tasks.send ? _c('div', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    })]);
  }), 0)]) : _vm._e()]) : [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.tasks.search,
      expression: "filters.tasks.search"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search tasks"
    },
    domProps: {
      "value": _vm.filters.tasks.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.tasks, "search", $event.target.value);
      }, function ($event) {
        return _vm.getTasksDebounce();
      }]
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "w-100 tasks-insideLinkedCallModal-container pr-3"
  }, _vm._l(_vm.response.tasks, function (task) {
    return _c('b-button', {
      key: task.id,
      attrs: {
        "disabled": _vm.api.assigned_tasks.send,
        "variant": "link"
      },
      on: {
        "click": function ($event) {
          _vm.selected.task_ids.includes(task.id) ? _vm.selected.task_ids.splice(_vm.selected.task_ids.indexOf(task.id), 1) : _vm.selected.task_ids.push(task.id);
        }
      }
    }, [_c('TaskItem', {
      class: _vm.selected.task_ids.includes(task.id) ? 'checked' : '',
      attrs: {
        "task": task,
        "isMore": false,
        "type": 'call-info'
      }
    })], 1);
  }), 1)])])]], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }