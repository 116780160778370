var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "connection",
    attrs: {
      "id": _vm.el_id
    }
  }, [_vm.isConnectionEstablished ? _c('Conference', {
    ref: "conference",
    attrs: {
      "JitsiConnection": _vm.JitsiConnection,
      "payload": _vm.videoCall.payload,
      "videoCall": _vm.videoCall,
      "listIndex": _vm.listIndex,
      "el_id": _vm.el_id
    },
    on: {
      "to-dashboard": function ($event) {
        return _vm.$emit('to-dashboard');
      },
      "calls-list": function ($event) {
        return _vm.$emit('calls-list');
      },
      "onDestroyed": _vm.onConferenceDestroyed,
      "addNewConference": function ($event) {
        return _vm.addNewConference($event);
      },
      "toggleIsPlay": _vm.toggleIsPlay,
      "showModal": function ($event) {
        return _vm.$modal.show('AddMembersInVideoCall');
      }
    }
  }) : [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "VideoCallBeforeConnect"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-topBar"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-inner"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-centerPart"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-name"
  }, [_vm._v(_vm._s(_vm.videoCall.name))]), _c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-ringingPart"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-ringingPart-imgSection"
  }, [_c('img', {
    staticStyle: {
      "cursor": "default"
    },
    attrs: {
      "src": require('@/assets/images/Top-icon-for-dialing/latest-VB-Logo.svg'),
      "alt": "VOIP"
    }
  })]), _c('div', {
    staticClass: "VideoCallBeforeConnect-topBar-ringingPart-textPart"
  }, [_vm._v("Ringing")])])])])]), _c('div', {
    staticClass: "VideoCallBeforeConnect-bodySection"
  }, [_c('video', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "autoplay": "",
      "id": `${_vm._uid}-testerrrrrr`
    }
  })]), _c('div', {
    staticClass: "VideoCallBeforeConnect-bottomBar"
  }, [_c('div', {
    staticClass: "VideoCallBeforeConnect-bottomBar-inner"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": true ? 'mobileVideoCall-videoDisabled-icon' : 'mobileVideoCall-video-icon',
      "width": "54px",
      "height": "54px"
    }
  }), _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": true ? 'mobileVideoCall-microphoneDisabled-icon' : 'mobileVideoCall-microphone-icon',
      "width": "54px",
      "height": "54px"
    }
  }), _c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": true ? 'mobileVideoCall-hangUp-icon' : 'mobileVideoCall-hangUp-icon',
      "width": "54px",
      "height": "54px",
      "id": "video-icon-hold"
    }
  })], 1)])]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('div', {
    staticStyle: {
      "background-color": "#2f2d30",
      "height": "100%",
      "width": "100%",
      "border-radius": "24px"
    }
  }, [_c('video', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "autoplay": "",
      "id": `${_vm._uid}-testerrrrrr`
    }
  })]) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }