var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [false ? _c('div', {
    staticClass: "dialer-chatBox-inVideo"
  }, [_c('div', {
    staticClass: "dialer-videoCall-addMember"
  }, [_c('span', {
    staticClass: "close-popup cursor_pointer",
    on: {
      "click": function ($event) {
        _vm.$store.state.calls.videoCalls[_vm.listIndex].isMemberOpen = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x-circle",
      "scale": "1.3"
    }
  })], 1), _c('h2', [_vm._v("Add Members")]), _vm.loading ? _c('div', [_vm._v("loading...")]) : [_c('div', {
    staticClass: "dialer-searchBox my-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "ml-4 form-control w-75",
    attrs: {
      "type": "text",
      "placeholder": "search user, Extension"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "ul-items-container"
  }, [_c('ul', {
    staticClass: "items h-auto"
  }, [_vm._l(_vm.membersUsers, function (user) {
    return _c('li', {
      key: user.account,
      staticClass: "item"
    }, [_c('Info', {
      attrs: {
        "id": user.account
      }
    })], 1);
  }), _vm._l(_vm.requestSendedUsers, function (user) {
    return _c('li', {
      key: user.account,
      staticClass: "item"
    }, [_c('Info', {
      attrs: {
        "id": user.account
      }
    })], 1);
  })], 2), _c('ul', {
    staticClass: "items"
  }, _vm._l(_vm.filterUsers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: user.account != _vm.$store.getters.getCurrentUser.account,
        expression: "user.account != $store.getters.getCurrentUser.account"
      }],
      key: user.account,
      staticClass: "item"
    }, [_c('Info', {
      attrs: {
        "id": user.account
      }
    }), _c('div', {
      staticClass: "actions"
    }, [user.isRequestSended ? _c('p', [_vm._v("Time left: " + _vm._s(user.time))]) : _vm._e(), user.isMember ? _c('p', [_vm._v("Already member")]) : [!user.isRequestSended ? _c('div', {
      staticClass: "success",
      on: {
        "click": function ($event) {
          return _vm.call(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone-fill"
      }
    })], 1) : _c('div', {
      staticClass: "danger",
      on: {
        "click": function ($event) {
          return _vm.cancel(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone-fill"
      }
    })], 1)]], 2)], 1);
  }), 0)])]], 2)]) : _vm._e(), _c('modal', {
    staticClass: "CreateChanneFromUserslModal DuringVideoCall",
    attrs: {
      "name": `${_vm.room}-CreateChannelModal`
    },
    on: {
      "before-close": function ($event) {
        return _vm.$emit('closeModal');
      }
    }
  }, [_c('div', {
    staticClass: "topBar-withCloseIcon mt-0"
  }, [_c('h3', {
    staticClass: "dialer-box-header w-100 mb-0"
  }, [_vm._v("Add Members")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.room}-CreateChannelModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "searchBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search user, extension"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer searchIcon",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "addable-memberLisit"
  }, [_c('ul', {
    staticClass: "d-flex list-unstyled"
  }, [_vm._l(_vm.membersUsers, function (user) {
    return _c('li', {
      key: user.account,
      staticClass: "d-inline-flex flex-column align-items-center pt-1 px-2"
    }, [_c('div', {
      staticClass: "user-avatar d-block avatar rounded-circle position-relative"
    }, [_c('img', {
      attrs: {
        "src": user.profile_img
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.userDPMale;
        }
      }
    })]), _c('small', {
      staticClass: "member-name mt-1"
    }, [_vm._v(_vm._s(_vm._f("get_first_name")(user.name)))])]);
  }), _vm._l(_vm.requestSendedUsers, function (user) {
    return _c('li', {
      key: user.account,
      staticClass: "d-inline-flex flex-column align-items-center pt-1 px-2"
    }, [_c('div', {
      staticClass: "user-avatar d-block avatar rounded-circle position-relative"
    }, [_c('span', {
      staticClass: "member-close position-absolute right-0 top-0",
      on: {
        "click": function ($event) {
          return _vm.cancel(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "x-circle-fill"
      }
    })], 1), _c('img', {
      attrs: {
        "src": user.profile_img
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.userDPMale;
        }
      }
    })]), _c('small', {
      staticClass: "member-name mt-1"
    }, [_vm._v(_vm._s(_vm._f("get_first_name")(user.name)))])]);
  })], 2)]), _c('ul', {
    staticClass: "AvailableUsersList"
  }, _vm._l(_vm.filterUsers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: user.account != _vm.$store.getters.getCurrentUser.account,
        expression: "user.account != $store.getters.getCurrentUser.account"
      }],
      key: user.account,
      staticClass: "AvailableUsersList-item",
      on: {
        "click": function ($event) {
          _vm.getProperty(user, 'user.user_chat_id') ? _vm.$set(_vm.selected_users, user.user.user_chat_id, true) : '';
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.account,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "actions d-flex align-items-center"
    }, [!_vm.$store.getters.getIsMobile ? [user.isRequestSended ? _c('p', {
      staticClass: "mb-0 mr-2"
    }, [_vm._v("Time left: " + _vm._s(user.time))]) : _vm._e(), user.isMember ? _c('p', {
      staticClass: "mb-0 mr-2"
    }, [_vm._v("Already member")]) : [!user.isRequestSended ? _c('div', {
      staticClass: "success",
      on: {
        "click": function ($event) {
          return _vm.call(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "plus-circle-fill",
        "font-scale": "1.75",
        "variant": "primary"
      }
    })], 1) : _c('div', {
      staticClass: "danger",
      on: {
        "click": function ($event) {
          return _vm.cancel(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "dash-circle-fill",
        "font-scale": "1.75",
        "variant": "danger"
      }
    })], 1)]] : [user.isRequestSended ? _c('p', {
      staticClass: "mb-0 mr-2"
    }, [_vm._v("Time left: " + _vm._s(user.time))]) : _vm._e(), user.isMember ? _c('p', {
      staticClass: "mb-0"
    }, [_vm._v("Already member")]) : [!user.isRequestSended ? _c('div', {
      staticClass: "success",
      on: {
        "click": function ($event) {
          return _vm.call(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "plus",
        "font-scale": "1.75",
        "variant": "primary"
      }
    })], 1) : _c('div', {
      staticClass: "danger",
      on: {
        "click": function ($event) {
          return _vm.cancel(user.account);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "dash",
        "font-scale": "1.75",
        "variant": "danger"
      }
    })], 1)]]], 2)], 1);
  }), 0)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }