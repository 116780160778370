<template>
  <div class="callActivity-Item-v2-container itsSMS" :class="call.sms_type=='inbound' ? 'Left' : 'Right'">
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-if="call.sms_type=='inbound'" v-b-popover.hover.top="'Recieved SMS'" icon="CallActivityV2-message-icon" height="55.922" width="55.922"/>
        <vb-icon v-else v-b-popover.hover.top="'Send SMS'" icon="CallActivityV2-message-icon" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <div v-if="call.sms_type=='custom'" class="user-name">{{ call.user | number_formater }}</div>
        <template v-if="call.sms_type=='inbound'">
          <div class="user-name">{{ call.user | number_formater }}</div>
          <div v-if="!getIsMobile" class="via-text">to <b>{{ call.receiver | number_formater }}</b></div>
        </template>
        <template v-else>
          <div class="user-name">{{ call.receiver | number_formater }}</div>
          <div v-if="!getIsMobile" class="via-text">via <b>{{ call.user | number_formater }}</b></div>
        </template>
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          
        </span>
      </div>
      <div class="callLogIcons-container">
        <template v-if="conditions.actions">
          <template v-if="type!='todo'">
            <span class="mr-8px">
              <vb-icon v-b-tooltip.hover title="Audio call" icon="call-callLog-icon" width="35px" height="35px" @click="$emit('call',{ type: 'sip', dialable: call.dialable })"/>
            </span>
          </template>
          <span class="mr-8px" v-if="type!='todo'">
            <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px" @click="$emit('info')" v-b-tooltip.hover title="Message info" />
          </span>
        </template>
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ call.date | filter_date_current('h:mm a', { unix: true }) }}</span>
          <b-button v-if="conditions.assign && type!='todo'" @click="$emit('assign')">Assign</b-button>
          <slot name="more">
            <b-dropdown v-if="conditions.actions" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template v-slot:button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <template>
                <b-dropdown-item @click="$emit('call',{ type: 'sip', dialable: call.dialable })">
                  <b-icon icon="telephone" font-scale="0.96"/>
                  <span class="ml-3">Call</span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item @click="$emit('info')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Message info</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
      </div>
    </div>
    <div class="callActivity-Item-v2-inner-2nd">
      <span v-if="conditions.message" class="dialer-call-prefix messageText">{{ call.body || "" }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "SMSLog",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
  },
};
</script>

<style>

</style>