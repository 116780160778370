<template>
  <modal
    :name="modalName"
    class="dialer_animation right_side_popup m-w-700 LinkTaskModal"
    width="50%"
    height="auto"
    :scrollable="true"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <div>
      <div class="dialer-edit-header flex-column align-items-start">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="dialer-settings-title newer d-flex align-items-center">
              <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" :disabled="api.assigned_tasks.send" >
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </a>
              Link task
            </h2>
          </div>
          <div class="dialer-edit-actions">
            <b-button @click="assignedTask()" :disabled="api.assigned_tasks.send || !isAnyChange" class="newDoneButton">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- filters -->
      <div class="d-flex w-100 mt-12px">
        <!-- space -->
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Space</label>
          <vb-select 
            v-model="filters.tasks.space_id"
            :options="response.spaces"
            :disabled="api.assigned_tasks.send"
            @change="filters.tasks.status_id='';filters.tasks.group_id='';getGroups();getStatus();getTasks(true);"
            textField="name"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select space</span>
                <div v-else>
                  <span>{{option.name}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.name}}</span>
            </template>
            <template #no-record>There is no spaces</template>
          </vb-select>
        </div>
        <!-- group -->
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Group</label>
          <vb-select 
            v-model="filters.tasks.group_id"
            :disabled="api.assigned_tasks.send"
            :options="response.groups"
            @change="getTasks(true);"
            textField="name"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select group</span>
                <div v-else>
                  <span>{{option.name}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.name}}</span>
            </template>
            <template #no-record>There is no groups</template>
          </vb-select>
        </div>
        <!-- priority -->
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Priority</label>
          <vb-select 
            v-model="filters.tasks.priority"
            :disabled="api.assigned_tasks.send"
            :options="Object.values(priorities)"
            @change="getTasks(true);"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select priority</span>
                <div v-else>
                  <span>{{option.text}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.text}}</span>
            </template>
            <template #no-record>There is no prioritys</template>
          </vb-select>
        </div>
        <!-- status -->
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Status</label>
          <vb-select 
            v-model="filters.tasks.status_id"
            :disabled="api.assigned_tasks.send"
            :options="response.statuses"
            @change="getTasks(true);"
            textField="title"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select status</span>
                <div v-else>
                  <span>{{option.title}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.title}}</span>
            </template>
            <template #no-record>There is no statuses</template>
          </vb-select>
        </div>
      </div>
      <!-- tasks -->
      <div class="latestGreyBox-9-9-2023 mt-12px">
        <vb-no-record v-if="response.tasks.length==0" :design="3" :text="api.fetch_tasks.send?'':'No Task'">
          <div v-if="api.fetch_tasks.send" slot="loading">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 6" :key="n.id">
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              </div>
            </div>
          </div>
        </vb-no-record>
        <template v-else>
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <input placeholder="Search tasks" v-model="filters.tasks.search" class="w-100" @input="getTasksDebounce()" />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="w-100 tasks-insideLinkedCallModal-container pr-3">
                <b-button 
                  v-for="task in response.tasks" 
                  :key="task.id"
                  :disabled="api.assigned_tasks.send"
                  variant="link"
                  @click="selected.task_ids.includes(task.id) ? selected.task_ids.splice(selected.task_ids.indexOf(task.id),1) : selected.task_ids.push(task.id)"
                >
                  <TaskItem :class="selected.task_ids.includes(task.id) ? 'checked' : ''"
                    :task="task"
                    :isMore="false"
                    :type="'call-info'"
                  >
                    <!-- <template #start>
                      <b-button 
                        :disabled="api.assigned_tasks.send"
                        variant="link"
                        @click="selected.task_ids.includes(task.id) ? selected.task_ids.splice(selected.task_ids.indexOf(task.id),1) : selected.task_ids.push(task.id)"
                      >
                        <div :class="`actLikeSquareRadio checkboxVersion ${selected.task_ids.includes(task.id) ? 'checked' : ''}`" ></div>
                      </b-button>
                    </template> -->
                  </TaskItem>
                </b-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import TaskItem from '@/components/Todo/TaskItem.vue'
import _ from 'lodash';
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: 'AssignTaskToCallLogModal',
  components: {
    TaskItem,
  },
  props: {
    modalName: {
      type: String,
      default: 'AssignTaskToCallLog'
    },
  },
  inject: [
    'appNotify',
  ],
  data() {
    return {
      api: {
        fetch_tasks: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_spaces: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_groups: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_statuses: this.$helperFunction.apiInstance({
          source: true,
        }),
        assigned_tasks: this.$helperFunction.apiInstance(),
      },
      response: {
        spaces: [],
        groups: [],
        statuses: [],
        tasks: [],
      },
      data: {
        call_id: '',
        assigned_tasks: [],
      },
      selected: {
        task_ids: [],
      },
      filters: {
        tasks: {
          search: '',
          space_id: '',
          group_id: '',
          priority: '',
          status_id: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    priorities(){ return priorities },
    isAnyChange(){ return _.orderBy(this.data.assigned_tasks).join(',')!=_.orderBy(this.selected.task_ids).join(',') },
  },
  filters: {
    priorityColor(priority){
      if(priority=='high') return '#ff403b'
      else if(priority=='mid') return '#ffba39'
      else return '#8ad129'
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.call_id=event?.params?.call_id ?? ''
      this.data.assigned_tasks=event?.params?.assigned_tasks ?? []
      this.selected.task_ids=_.cloneDeep(this.data.assigned_tasks)
      this.getTasks(true)
      this.getSpaces()
    },
    onBeforeClose() {
      this.api.fetch_tasks.reset()
      this.api.fetch_spaces.reset()
      this.api.fetch_groups.reset()
      this.api.fetch_statuses.reset()
      this.api.assigned_tasks.reset()
      this.response.tasks=[]
      this.response.spaces=[]
      this.response.groups=[]
      this.response.statuses=[]
      this.data.call_id=''
      this.data.assigned_tasks=[]
      this.selected.task_ids=[]
      this.filters.tasks.group_id=''
      this.filters.tasks.priority=''
      this.filters.tasks.space_id=''
      this.filters.tasks.status_id=''
    },
    async getSpaces() {
      this.response.spaces=[]
      this.api.fetch_spaces.source?.cancel?.("Requesting Again");
      this.api.fetch_spaces.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_spaces.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        },this.api.fetch_spaces.source.token)
        this.response.spaces=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_spaces.send=false  
      }
    },
    async getGroups(){
      this.response.groups = []
      this.api.fetch_groups.source?.cancel?.("Requesting Again");
      this.api.fetch_groups.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_groups.send=true
        const { data: { data } } = await VOIP_API.endpoints.taskgroups.list({
          uid: this.getCurrentUser.uid,
          space_id: this.filters.tasks.space_id,
        },this.api.fetch_spaces.source.token)
        this.response.groups = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_groups.send=false
      }
    },
    async getStatus(){
      this.response.statuses = []
      this.api.fetch_statuses.source?.cancel?.("Requesting Again");
      this.api.fetch_statuses.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_statuses.send=true
        const { data } = await VOIP_API.endpoints.task_status.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.filters.tasks.space_id,
        },this.api.fetch_statuses.source.token)
        this.response.statuses = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_statuses.send=false
      }
    },
    getTasksDebounce: _.debounce(function (){
      this.getTasks()
    }, 2*1000),
    async getTasks() {
      this.api.fetch_tasks.source?.cancel?.("Requesting Again");
      this.api.fetch_tasks.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_tasks.send=true
        const { data } = await VOIP_API.endpoints.tasklist.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          status: this.filters.tasks.status_id ? [this.filters.tasks.status_id] : [],
          groups: this.filters.tasks.group_id ? [this.filters.tasks.group_id] : [],
          priority: this.filters.tasks.priority ? [this.filters.tasks.priority] : [],
          space_id: this.filters.tasks.space_id ? [this.filters.tasks.space_id] : [],
          search: this.filters.tasks.search || null,
        },this.api.fetch_tasks.source.token)
        this.response.tasks = data
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_tasks.send=false
      }
    },
    async assignedTask(){
      if(this.api.assigned_tasks.send) return;
      try {
        this.api.assigned_tasks.send=true
        await VOIP_API.endpoints.tasklist.assignedTasksToCallLog({
          call_id: this.data.call_id,
          task_ids: this.selected.task_ids,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
        })
        this.$emit('updated',{
          task_ids: this.selected.task_ids
        })
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully Changed',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.assigned_tasks.send=false
      }
    },
  },
}
</script>

<style>

</style>