<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.add">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.remove">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add User</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
        <div class="innerModalIos">
          <div class="mobileInputIOS iconOnLeft mt-32px">
            <b-input type="text" placeholder="Search" v-model="filter.add.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
          <div class="basicWhiteIOScard mt-32px">
            <b-form-checkbox 
              v-if="isNotAddedAllCheckShow" 
              class="basicWhiteIOScard-item" 
              :checked="isNotAddedAllCheck" 
              @change="onSelectAllNotAdded()"
            >
              Select All
            </b-form-checkbox>
            <b-form-checkbox 
              class="basicWhiteIOScard-item" 
              v-for="user in searchedNotAddedList" 
              :key="user.account" 
              :disabled="api.add.send" 
              :checked="!!selected.notadded[user.account]" 
              @change="onChange(user)"
            >
              <Info v-if="type==types.caller_id_shared" class="InfoComponentInsideAnroidIOSApp" :id="user.html | getExtnByNumberHTML" />
              <Info v-else class="InfoComponentInsideAnroidIOSApp" :id="user.account || user.extn" />
            </b-form-checkbox>
            <vb-no-record v-if="isEmpty(searchedNotAddedList)" :text="api.list.send?'':'There is no User'" :design="3">
              <vb-loading v-if="api.list.send" slot="loading" />
            </vb-no-record>
          </div>
          <b-button 
            class="IosFullWidthButton forDone mt-16px mb-16px" 
            v-if="Object.keys(selected.notadded).length > 0" 
            :disabled="api.add.send" 
            @click="add()"
          >
            Add User
          </b-button>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Remove User</div>
          <div class="plus-IOSIcon-container">
            <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" @click="conditions.screen=screens.add" />
          </div>
        </div>

        <div class="innerModalIos">

          <div class="mobileInputIOS iconOnLeft mt-32px">
            <b-input type="text" placeholder="Search" v-model="filter.remove.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
          
          <div class="basicWhiteIOScard mt-32px">
            <b-form-checkbox 
              class="basicWhiteIOScard-item" 
              v-if="isAddedAllCheckShow" 
              :checked="isAddedAllCheck" 
              :disabled="api.remove.send" 
              @change="onSelectAllAdded()"
            >
              Select All
            </b-form-checkbox>
            <b-form-checkbox 
              class="basicWhiteIOScard-item" 
              v-for="user in searchedAddedList" 
              :key="user.account" 
              :disabled="api.remove.send" 
              :checked="!!selected.added[user.account]" 
              @change="onChange(user)"
            >
              <div class="d-flex align-items-center">
                <Info v-if="type==types.caller_id_shared" class="InfoComponentInsideAnroidIOSApp" :id="user.html | getExtnByNumberHTML" />
                <Info v-else class="InfoComponentInsideAnroidIOSApp" :id="user.account || user.extn" />
                <div v-if="type==types.assign_call_queue" class="forceSwitchContainer position-relative">
                  <label class="switch"> 
                    <input 
                      type="checkbox" 
                      :checked="user.force=='yes'" 
                      :disabled="api.update_member_force_admin.send" 
                      @change="updateForceAdmin(user,user.account)"
                    >
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </b-form-checkbox>
            <vb-no-record v-if="searchedAddedList.length==0" :text="api.list.send?'':'There is no User'" :design="3">
              <vb-loading v-if="api.list.send" slot="loading" />
            </vb-no-record>
          </div>
          <b-button 
            class="IosFullWidthButton forRemove mt-16px mb-16px" 
            v-if="!isEmpty(selected.added)" 
            :disabled="api.remove.send" 
            @click="remove()"
          >
            <vb-spinner v-if="api.remove.send" />
            <template>Remove User</template>
          </b-button>

        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { LOGGER, VOIP_API } from "@/utils";
import Info from "../../../Lists/Info.vue";
import { mapGetters } from 'vuex';
let types = {
  share_voice_mail_box: 'share_voice_mail_box',
  assign_team: 'assign_team',
  assign_call_queue: 'assign_call_queue',
  caller_id_shared: 'caller_id_shared',
}
let screens = {
  add: 'add',
  remove: 'remove',
}
export default {
  name: "AddRemoveUsersModal",
  components: { 
    Info,
  },
  inject:['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: "AddRemoveUsers",
    },
    accountcode: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        return !!types[value]
      }
    },
  },
  data() {
    return {
      response: {
        notadded_list: {},
        added_list: {},
      },
      selected: {
        notadded: {},
        added: {},
      },
      api: {
        add: this.$helperFunction.apiInstance({  }),
        remove: this.$helperFunction.apiInstance({  }),
        list: this.$helperFunction.apiInstance({  }),
        update_member_force_admin: this.$helperFunction.apiInstance({ send: '' }),
      },
      filter: {
        add: {
          search: "",
        },
        remove: {
          search: "",
        },
      },
      data: {},
      conditions: {
        screen: screens.remove,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getCurrentUser',
    ]),
    screens(){ return screens },
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser.account] },
    types() { return types; },
    searchedNotAddedList() {
      const search = this.filter.add.search.trim().toLowerCase();
      if (this.type === types.share_voice_mail_box) {
        const available_users = Object.values(this.response.notadded_list || {})
          .filter((item) => `${item?.display_name} ${item?.extn} ${item?.name}`.trim().toLowerCase().includes(search))
        return _.orderBy(available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.assign_team) {
        const available_users = Object.values(this.response.notadded_list || {}).filter((item) => `${item?.display_name} ${item?.extn}`.trim().toLowerCase().includes(search))
        return _.orderBy(available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.assign_call_queue) {
        const available_users = Object.values(this.response.notadded_list || {}).filter((item) => `${item?.display_name} ${item?.extn}`.trim().toLowerCase().includes(search))
        return _.orderBy(available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.caller_id_shared) {
        const available_users = Object.values(this.response.notadded_list || {}).filter((item) => `${item.html}`.trim().toLowerCase().includes(search))
        return _.orderBy(available_users, ["html"], ["asc"]);
      } else {
        return [];
      }
    },
    keysSearchedNotAddedList() {
      const array = _.isArray(this.searchedNotAddedList) ? this.searchedNotAddedList : Object.values(this.searchedNotAddedList);
      if (this.type === types.share_voice_mail_box) return array.map((item) => item.account); 
      else if (this.type === types.assign_team) return array.map((item) => item.account); 
      else if (this.type === types.assign_call_queue) return array.map((item) => item.account); 
      else if (this.type === types.caller_id_shared) return array.map((item) => item.account); 
      else return [];
    },
    searchedAddedList() {
      const search = this.filter.remove.search.trim().toLowerCase();
      if (this.type === types.share_voice_mail_box) {
        const not_available_users = (_.isArray(this.response.added_list) ? this.response.added_list : Object.values(this.response.added_list || {}))
          .filter((item) => `${item?.display_name} ${item?.extn} ${item?.name}`.trim().toLowerCase().includes(search))
        return _.orderBy(not_available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.assign_team) {
        const not_available_users = (_.isArray(this.response.added_list) ? this.response.added_list : Object.values(this.response.added_list || {}))
          .filter((item) => `${item?.display_name} ${item?.extn}`.trim().toLowerCase().includes(search))
        return _.orderBy(not_available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.assign_call_queue) {
        const not_available_users = (_.isArray(this.response.added_list) ? this.response.added_list : Object.values(this.response.added_list || {}))
          .filter((item) => `${item?.display_name} ${item?.extn}`.trim().toLowerCase().includes(search))
        return _.orderBy(not_available_users, ["display_name"], ["asc"]);
      } else if (this.type === types.caller_id_shared) {
        const not_available_users = (_.isArray(this.response.added_list) ? this.response.added_list : Object.values(this.response.added_list || {}))
          .filter((item) => `${item.html}`.trim().toLowerCase().includes(search))
        return _.orderBy(not_available_users, ["html"], ["asc"]);
      } else {
        return [];
      }
    },
    keysSearchedAddedList() {
      const array = _.isArray(this.searchedAddedList) ? this.searchedAddedList : Object.values(this.searchedAddedList);
      if (this.type === types.share_voice_mail_box)  return array.map((item) => item.account);
      else if (this.type === types.assign_team)  return array.map((item) => item.account);
      else if (this.type === types.assign_call_queue)  return array.map((item) => item.account);
      else if (this.type === types.caller_id_shared)  return array.map((item) => item.account);
      else return [];
    },
    isAddedAllCheck() {
      const array = _.isArray(this.selected.added) ? this.selected.added : Object.keys(this.selected.added);
      return !_.isEmpty(this.keysSearchedAddedList) ? this.keysSearchedAddedList.every((el) => array.includes(el)) : false;
    },
    isAddedAllCheckShow() {
      return (!_.isEmpty(this.keysSearchedAddedList) && this.keysSearchedAddedList.length > 1);
    },
    isNotAddedAllCheck() {
      const array = _.isArray(this.selected.notadded) ? this.selected.notadded : Object.keys(this.selected.notadded);
      return !_.isEmpty(this.keysSearchedNotAddedList) ? this.keysSearchedNotAddedList.every((el) => array.includes(el)) : false;
    },
    isNotAddedAllCheckShow() {
      return (!_.isEmpty(this.keysSearchedNotAddedList) && this.keysSearchedNotAddedList.length > 1);
    },
  },
  filters: {
    getExtnByNumberHTML(value){ return value?.replace?.(/[^0-9]/g,'')?.substr?.(0,4) ?? value },
  },
  methods: {
    onBeforeOpen(event) {
      this.data=event?.params ?? {}
      this.load();
    },
    onBeforeClose(){
      this.response.added_list={}
      this.response.notadded_list={}
      this.selected.added={}
      this.selected.notadded={}
      this.api.add.reset()
      this.api.remove.reset()
      this.api.list.reset()
      this.api.update_member_force_admin.reset()
      this.filter.add.search=''
      this.filter.remove.search=''
      this.data={}
      this.conditions.screen=screens.remove
    },
    async load() {
      if (this.api.list.send) return;
      try {
        this.api.list.send=true
        if (this.type === types.share_voice_mail_box){
          const { data } = await VOIP_API.endpoints.voicemail.list.servicevoicemail(this.data.accountcode,{
            fid: 12,
            id: this.data.accountcode
          })
          this.response.added_list = _.isPlainObject(data.detail.members.selected) ? data.detail.members.selected : {};
          this.response.notadded_list = _.isPlainObject(data.detail.members.available) ? data.detail.members.available : {};
        } else if(this.type === types.assign_team){
          const { data } = await VOIP_API.endpoints.teams.teammembers(this.data.real_id, {
            fid: 12,
          })
          if (data.selected != undefined && Object.keys(data.selected).length != 0) {
            let obj = {};
            for (let key in data.selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...data.selected[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.added_list = obj;
          } else {
            this.response.added_list = {};
          }
          if (data.available != undefined && Object.keys(data.available).length != 0) {
            let obj = {};
            for (let key in data.available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...data.available[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.notadded_list = obj;
          } else {
            this.response.notadded_list = {};
          }
        } else if(this.type === types.assign_call_queue){
          const { data } = await VOIP_API.endpoints.call_queues.members(this.data.accountcode)
          if (data.selectedMemebers != undefined && Object.keys(data.selectedMemebers).length != 0) {
            let obj = {};
            for (let key in data.selectedMemebers) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...data.selectedMemebers[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.added_list = obj;
          } else {
            this.response.added_list = {};
          }
          if (data.availableMembers != undefined && Object.keys(data.availableMembers).length != 0) {
            let obj = {};
            for (let key in data.availableMembers) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  ...data.availableMembers[key],
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.notadded_list = obj;
          } else {
            this.response.notadded_list = {};
          }
        } else if(this.type === types.caller_id_shared){
          const { data } = await VOIP_API.endpoints.callerid.extensions({
            callerid: this.data.number.object === "TelephoneNumbers" ? this.data.number.did : this.data.number.number,
            type: this.data.number.object === "TelephoneNumbers" ? "did" : "callerid",
          })
          if (data.selected != undefined && Object.keys(data.selected).length != 0) {
            let obj = {};
            for (let key in data.selected) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  html: data.selected[key].html,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.added_list = obj;
          } else {
            this.response.added_list = {};
          }
          if (data.available != undefined && Object.keys(data.available).length != 0) {
            LOGGER.log("run", data.available);
            let obj = {};
            for (let key in data.available) {
              Object.defineProperty(obj, key, {
                value: {
                  account: key,
                  html: data.available[key].html,
                },
                configurable: true,
                enumerable: true,
                writable: true,
              });
            }
            this.response.notadded_list = obj;
          } else {
            this.response.notadded_list = {};
          }
        }
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.list.send=false
      }
    },
    remove() {
      let vm = this
      if (this.api.remove.send) return;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async ({confirm}) => {
          // console.log(confirm,vm.api)
          if (confirm) {
            vm.api.remove.send=true
            try {
              if (vm.type === types.share_voice_mail_box) {
                let selected = Object.keys(vm.selected.added);
                let array = Object.keys(vm.response.added_list).filter((key) => selected.indexOf(key) == -1);
                await VOIP_API.endpoints.voicemail.updatesetting.updatesharedaccounts(vm.user?.voipaccount, {
                  members: array.join(","),
                })
                vm.appNotify({
                  message: "successfully updated!",
                  type: "success",
                })
                Object.keys(vm.selected.added).forEach((key) => {
                  vm.$set(vm.response.notadded_list, key, vm.response.added_list[key]);
                  vm.$delete(vm.response.added_list, key);
                });
              } else if (vm.type === types.assign_team) {
                let selected = Object.keys(vm.selected.added);
                let array = Object.keys(vm.response.added_list).filter((key) => selected.indexOf(key) == -1);
                let obj = {};
                for (let index = 0; index < array.length; index++) {
                  const element = array[index];
                  Object.defineProperty(obj, element, {
                    value: element,
                    configurable: true,
                    enumerable: true,
                    writable: true,
                  });
                }
                await VOIP_API.endpoints.teams.updateteammmembers({
                  id: vm.data.real_id,
                  fid: 12,
                  selected: obj,
                  new_selected: '',
                  old_deleted: Object.keys(vm.selected.added).join(','),
                })
                vm.appNotify({
                  message: "Team members has been updated",
                  type: "success",
                  duration: 15000,
                })
                Object.keys(vm.selected.added).forEach((key) => {
                  vm.$set(vm.response.notadded_list, key, vm.response.added_list[key]);
                  vm.$delete(vm.response.added_list, key);
                });
              } else if (vm.type === types.assign_call_queue) {
                await VOIP_API.endpoints.call_queues.addMembers(vm.data.accountcode,{
                  id: vm.data.accountcode,
                  fid: 12,
                  new_selected: '',
                  old_deleted: Object.keys(vm.selected.added).join(','),
                })
                vm.appNotify({
                  message: "Call Queue members has been updated",
                  type: "success",
                  duration: 15000,
                })
                Object.keys(vm.selected.added).forEach((key) => {
                  vm.$set(vm.response.notadded_list, key, vm.response.added_list[key]);
                  vm.$delete(vm.response.added_list, key);
                });
                vm.$emit("update-list");
              } else if (vm.type === types.caller_id_shared) {
                let selected = Object.keys(vm.selected.added);
                let array = Object.keys(vm.response.added_list).filter((key) => selected.indexOf(key) == -1);
                let obj = {};
                for (let index = 0; index < array.length; index++) {
                  const element = array[index];
                  Object.defineProperty(obj, element, {
                    value: element,
                    configurable: true,
                    enumerable: true,
                    writable: true,
                  });
                }
                await VOIP_API.endpoints.callerid.updateextensions({
                  values: obj,
                  callerid: vm.data.number.object === "TelephoneNumbers" ? vm.data.number.did : vm.data.number.number,
                  type: vm.data.number.object === "TelephoneNumbers" ? "did" : "callerid",
                })
                vm.appNotify({
                  message: "Users Updated Successfully!",
                  type: "success",
                  duration: 15000,
                })
                Object.keys(vm.selected.added).forEach((key) => {
                  vm.$set(vm.response.notadded_list, key, vm.response.added_list[key]);
                  vm.$delete(vm.response.added_list, key);
                });
              }
              vm.$emit("update-list",vm.response.added_list);
              vm.selected.notadded = {};
              vm.selected.added = {};
            } catch(ex) {
              vm.appNotify({
                message: ex.own_message,
                type: "danger",
              })
            } finally {
              vm.api.remove.send=false
            }
          }
        },
      });
    },
    async add() {
      if (this.api.add.send || _.isEmpty(this.selected.notadded)) return;
      try {
        this.api.add.send=true
        if (this.type === types.share_voice_mail_box) {
          await VOIP_API.endpoints.voicemail.updatesetting.updatesharedaccounts(this.user?.voipaccount, {
            members: Object.keys(this.selected.notadded).join(",") + "," + Object.keys(this.response.added_list).join(","),
          })
          this.appNotify({
            message: "successfully updated!",
            type: "success",
          })
          Object.keys(this.selected.notadded).forEach((key) => {
            this.$set(this.response.added_list, key, this.response.notadded_list[key]);
            this.$delete(this.response.notadded_list, key);
          });
        } else if (this.type === types.assign_team) {
          const array = [...Object.keys(this.selected.notadded),...Object.keys(this.response.added_list),];
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.teams.updateteammmembers({
            id: this.data.real_id,
            fid: 12,
            selected: obj,
            new_selected: Object.keys(this.selected.notadded).join(','),
            old_deleted: '',
          })
          this.appNotify({
            message: "successfully updated!",
            type: "success",
          })
          Object.keys(this.selected.notadded).forEach((key) => {
            this.$set(this.response.added_list, key, this.response.notadded_list[key]);
            this.$delete(this.response.notadded_list, key);
          });
        } else if (this.type === types.assign_call_queue) {
          const array = [...Object.keys(this.selected.notadded),...Object.keys(this.response.added_list),];
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          await VOIP_API.endpoints.call_queues.addMembers(this.data.accountcode,{
            id: this.data.accountcode,
            fid: 12,
            new_selected: Object.keys(this.selected.notadded).join(','),
            old_deleted: '',
          })
          this.appNotify({
            message: "successfully updated!",
            type: "success",
          })
          Object.keys(this.selected.notadded).forEach((key) => {
            this.$set(this.response.added_list, key, this.response.notadded_list[key]);
            this.$delete(this.response.notadded_list, key);
          });
        } else if (this.type === types.caller_id_shared) {
          const array = [...Object.keys(this.selected.notadded),...Object.keys(this.response.added_list),];
          let obj = {};
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            Object.defineProperty(obj, element, {
              value: element,
              configurable: true,
              enumerable: true,
              writable: true,
            });
          }
          VOIP_API.endpoints.callerid.updateextensions({
            values: obj,
            callerid: this.data.number.object === "TelephoneNumbers" ? this.data.number.did : this.data.number.number,
            type: this.data.number.object === "TelephoneNumbers" ? "did" : "callerid",
          })
          Object.keys(this.selected.notadded).forEach((key) => {
            this.$set(this.response.added_list, key, this.response.notadded_list[key]);
            this.$delete(this.response.notadded_list, key);
          });
          this.appNotify({
            message: "Users Updated Successfully!",
            type: "success",
          })
        }
        this.selected.notadded = {};
        this.selected.added = {};
        this.$emit("update-list",this.response.added_list);
        this.conditions.screen=screens.remove
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: "danger",
        })
      } finally {
        this.api.add.send = false;
      }
    },
    updateForceAdmin(member,accountcode){
      let vm = this
      // console.log(member,accountcode)
      if(vm.api.update_member_force_admin.send || !accountcode) return;
      vm.api.update_member_force_admin.send=accountcode
      VOIP_API.endpoints.call_queues.memberForceAdmin(accountcode,{
        id: vm.data.accountcode,
        account: accountcode,
        value: member.force=='no'?'yes':'no',
      })
      .then(()=>{
        this.appNotify({
          message: 'Successfully Change',
          type: 'success',
        })
        vm.$emit('update-list')
      })
      .catch((ex)=>{
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_member_force_admin.send=''
        vm.load()
      })
      // console.log("tun")
    },
    onSelectAllAdded() {
      const array = _.isArray(this.searchedAddedList) ? this.searchedAddedList : Object.values(this.searchedAddedList);
      if (!this.isAddedAllCheck) {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = "";
          if (this.type === types.share_voice_mail_box) key = data.account;
          else if (this.type === types.assign_team) key = data.account;
          else if (this.type === types.assign_call_queue) key = data.account;
          else if (this.type === types.caller_id_shared) key = data.account;
          this.$set(this.selected.added, key, data);
        }
      } else {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = "";
          if (this.type === types.share_voice_mail_box) key = data.account;
          else if (this.type === types.assign_team) key = data.account;
          else if (this.type === types.assign_call_queue) key = data.account;
          else if (this.type === types.caller_id_shared) key = data.account;
          this.$delete(this.selected.added, key);
        }
      }
    },
    onSelectAllNotAdded() {
      const array = _.isArray(this.searchedNotAddedList) ? this.searchedNotAddedList : Object.values(this.searchedNotAddedList);
      if (!this.isNotAddedAllCheck) {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = "";
          if (this.type === types.share_voice_mail_box) key = data.account;
          else if (this.type === types.assign_team) key = data.account;
          else if (this.type === types.assign_call_queue) key = data.account;
          else if (this.type === types.caller_id_shared) key = data.account;
          this.$set(this.selected.notadded, key, data);
        }
      } else {
        for (let index = 0; index < array.length; index++) {
          const data = array[index];
          let key = "";
          if (this.type === types.share_voice_mail_box) key = data.account;
          else if (this.type === types.assign_team) key = data.account;
          else if (this.type === types.assign_call_queue) key = data.account;
          else if (this.type === types.caller_id_shared) key = data.account;
          this.$delete(this.selected.notadded, key);
        }
      }
    },
    onChange(data) {
      if (this.api.add.send || this.api.remove.send) return;
      var key = "";
      if (this.type === types.share_voice_mail_box) key = data.account;
      else if (this.type === types.assign_team) key = data.account;
      else if (this.type === types.assign_call_queue) key = data.account;
      else if (this.type === types.caller_id_shared) key = data.account;
      if (!key) return;
      if (this.conditions.screen==screens.add) {
        if (this.selected.notadded[key]) {
          this.$delete(this.selected.notadded, key, data);
        } else {
          this.$set(this.selected.notadded, key, data);
        }
      } else {
        if (this.selected.added[key]) {
          this.$delete(this.selected.added, key, data);
        } else {
          this.$set(this.selected.added, key, data);
        }
      }
    },
  },
};
</script>

