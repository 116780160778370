<template>
  <div v-if="true" class="chat-actions pt-3">
    
    <input type="file" @change="filesUpload($event.target.files)" ref="file_upload_in_chat" multiple style="display: none"/>
    <!-- chat box v2 design -->
    <template>
      <div class="dialer-chatBox-typingMessage-area v2" :class="{'bg-transparent': is_file_uploading_error,'messageIsBeingTyped': typingMessage,}">
        <!-- info -->
        <div class="position-relative">
          <!-- mentions -->
          <div class="dialer-tags" v-if="channelData.type !== 'user' && !isEmpty(afterSearchMembers)">
            <ul :id="`${channel_id}-mentions-items`">
              <li v-for="(user, index) in afterSearchMembers" :key="user.user.user_chat_id">
                <a @click="selectUser(user)" @mouseover="selectedUserIndex = index" 
                  class="d-flex align-items-center" :style="{'background-color': selectedUserIndex === index ? '#eee' : '',}">
                  <span class="avatar d-flex align-items-center justify-content-center p-1">
                    <img :src="user.profile_img" :alt="user.user.display_name" style="height: 40px; width: 40px"/>
                  </span>
                  <div class="name">
                    <h5 class="m-0 text-dotted">{{ user.user.display_name }}</h5>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- typing -->
          <div class="messageIsBeingTyped-text" v-show="typingMessage">
            <p class="pl-2 mb-1 isTypingText">
              <span>{{ typingMessage }}</span> is typing ...
            </p>
          </div>
          <!-- ------ -->
          <!-- reply -->
          <!-- <div v-if="!isEmpty(channelData.actions.reply_post)" class="d-flex justify-content-between w-100 pb-2 mb-2 border-bottom border-secondary QuotedText-container">
            <p class="pl-2 mb-1 d-flex flex-column align-items-start">
              <img :src="commaIcon" alt />
              <span class="pl-3 QuotedText">{{ channelData.actions.reply_post.message }}</span>
            </p>
            <b-icon class="ml-3 cursor_pointer" icon="x-circle" @click="$store.state.mattermost.channels[channel_id].actions.reply_post = {}" />
          </div> -->
          <!-- ----- -->
          <!-- uploading error -->
          <div v-if="is_file_uploading_error">Uploading in process</div>
          <!-- --------------- -->
          <!-- upload file -->
          <div class="dialer-uploadableFiles-showCase">
            <File
              v-for="file in $store.state.mattermost.channels[channel_id].actions.files"
              :key="file.uid"
              :file="file"
              :channel_id="channel_id"
              :random_id="random_id"
            />
            <div v-if="filesLength>0" class="bill-add-file ml-2 mt-2 cursor_pointer" @click="$refs.file_upload_in_chat.click()">
              <b-icon icon="plus" scale="3" />
            </div>
          </div>
          <!-- ----------- -->
        </div>
        <div v-if="update"></div>
        <!-- ---- -->
        <!-- Actions -->
        <div v-if="$store.getters.getIsMobile" :class="`dialer-chatBox-actionIcon-area ${channelData.actions.is_important ? 'important_message_bill': ''}`">
          <ul  class="list-unstyled">
            <!-- Emoji -->
            <li class="d-inline-block px-1" style="position: relative">
              <div style="position: absolute; bottom: 40px" v-if="is_emoji_modal">
                <v-emoji-picker @select="$store.state.mattermost.channels[channel_id].actions.message = $store.state.mattermost.channels[channel_id].actions.message + $event.data" class="mobileChatEmojiContainer" />
              </div>
              <a class="dialer-icon" @click="is_emoji_modal = !is_emoji_modal">
                <vb-icon width="48" class="shush" height="48" :icon="is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'" />
              </a>
            </li>
            <!-- Close -->
            <li class="d-inline-block px-1" v-if="!isEmpty(channelData.actions.edit_post)"
              @click="
                $store.state.mattermost.channels[channel_id].actions.edit_post ={};
                $store.state.mattermost.channels[channel_id].actions.message = '';
                $store.state.mattermost.channels[channel_id].actions.is_important = false;">
              <a href="#" class="ico-chat dialer-icon red-icon">
                <b-icon icon="x" variant="danger" />
              </a>
            </li>
            <!-- ------- -->
          </ul>
          <!-- input div -->
          <div class="dialer-textArea d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center px-2">
            <div class="file_uploading_msg" v-if="is_submitted && !isEmpty(filesUploadings)">Uploading in process</div>
            <!-- reply -->
            <div v-if="!isEmpty(channelData.actions.reply_post)" class="d-flex repliedText-container justify-content-between w-100 pb-2 mb-2 border-bottom border-secondary">
              <p class="pl-2 mb-1 d-flex flex-column align-items-start">
                <img :src="commaIcon" alt />
                <span class="pl-3 repliedText">{{ channelData.actions.reply_post.message }}</span>
              </p>
              <b-icon class="ml-3 cursor_pointer" icon="x-circle" @click="$store.state.mattermost.channels[channel_id].actions.reply_post = {}" />
            </div>
            <b-form @submit.prevent="submit" class="w-100">
              <!-- <div v-if="channelData.actions.is_important" class="important_message danger">
                <small class="font-weight-bold">IMPORTANT!</small>
              </div> -->
              <textarea
                style="height:auto"
                :class="[channelData.actions.is_important? 'important_message dialer-textArea w-100': 'dialer-textArea  w-100',]"
                v-model="$store.state.mattermost.channels[channel_id].actions.message"
                placeholder="Write your message"
                rows="1"
                :id="`${channel_id}-text-area`"
                @keypress="onKeyPress"
                @keydown="onKeyDown"
                @paste="onPaste">
              </textarea>
              
            </b-form>
            <!-- ------- -->
          </div>
          <ul v-if="!$store.state.mattermost.channels[channel_id].actions.message" class="list-unstyled mb-0">
            <!-- Audio recording -->
            <li 
              v-if="permissions.mic!='denied'"
              class="d-inline-flex px-1 justify-content-between  audio-record-area"
              :class="!recorded.audio.is_show ? 'align-items-center' : 'align-items-end open'"
            >
              <a v-if="!recorded.audio.is_show" v-b-tooltip.hover title="Record a message" class="dialer-icon" 
                    @click="recorded.audio.is_show = true;recorded.audio.is_start = true; ">
                <vb-icon icon="chat-micLineIcon" width="48" height="48" />
              </a>
              <template v-else>
                <a class="dialer-icon" @click="recorded.audio.is_cancelled = true;recorded.audio.is_start = false;">
                  <b-icon icon="x" variant="danger" font-scale="1.5"/>
                </a>
                <div class="recording-timer">
                  <div class="recording-timer-inner">
                    <b-icon icon="circle-fill" variant="danger" animation="throb" scale="0.8" font-scale="1.5"></b-icon>
                    <span class="timer">
                      {{ recorded.audio.time | time_audio}}
                    </span>
                  </div>
                  <a class="dialer-icon" v-b-tooltip.hover title="send audio message" @click="recorded.audio.is_cancelled = false;recorded.audio.is_start = false;">
                    <b-icon icon="check" font-scale="1.5"/>
                  </a>
                </div>
              </template>
            </li>
            <!-- -------- -->
          </ul>
          <b-dropdown v-if="!$store.state.mattermost.channels[channel_id].actions.message" varient="link" offset="25" no-caret class="ico-filter chat-actions-more-dots">
            <template v-slot:button-content>
              <div class="dialer-icon ">
                <vb-icon icon="chat-viewMoreDotsIcon" width="5" height="40" />
              </div>
            </template>
            <!-- Important -->
            <b-dropdown-item @click="$store.state.mattermost.channels[channel_id].actions.is_important = !channelData.actions.is_important" >
              <a  class="dialer-icon d-flex align-items-center">
                <!-- v-b-tooltip.hover title="Important message" -->
                <vb-icon icon="chat-importantIcon" width="48" class="chat-importantIcon" height="48" />
                <span class="ml-2">Important message</span>
              </a>
            </b-dropdown-item>
            <!-- video recording -->
            <b-dropdown-item @click="$modal.show(`${random_id}_RecordedVideoModal`)">
              <a  class="dialer-icon d-flex align-items-center">
                <!-- v-b-tooltip.hover title="Record a message" -->
                <vb-icon icon="chat-videoCameraLineIcon" width="48" height="48" />
                <span class="ml-2">Record a message</span>
              </a>
            </b-dropdown-item>
            <!-- File -->
            <b-dropdown-item v-if="isEmpty(channelData.actions.edit_post)" @click="$refs.file_upload_in_chat.click()">
              <a  href="#" class="dialer-icon d-flex align-items-center">
                <!-- v-b-tooltip.hover title="Attach file" -->
                <vb-icon icon="chat-paperclip" width="48" height="48" />
                <span class="ml-2">Attach file</span>
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div v-else :class="`dialer-chatBox-actionIcon-area ${channelData.actions.is_important ? 'important_message_bill': ''}`">
          <!-- left actions list -->
          <ul class="list-unstyled">
            <!-- Emoji -->
            <li class="d-inline-block px-1" style="position: relative">
              <div style="position: absolute; bottom: 85px" class="vEmojiPicker" v-if="is_emoji_modal">
                <v-emoji-picker @select="$store.state.mattermost.channels[channel_id].actions.message = $store.state.mattermost.channels[channel_id].actions.message + $event.data" />
              </div>
              <a class="dialer-icon" @click="is_emoji_modal = !is_emoji_modal">
                <vb-icon width="48" class="shush" height="48" :icon="is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'" />
              </a>
            </li>
            <!-- ------- -->
            
            <!-- Close -->
            <!-- <li
              class="d-inline-block px-1"
              v-if="!isEmpty(channelData.actions.edit_post)"
              @click="
                $store.state.mattermost.channels[channel_id].actions.edit_post =
                  {};
                $store.state.mattermost.channels[channel_id].actions.message = '';
                $store.state.mattermost.channels[
                  channel_id
                ].actions.is_important = false;
              "
            >
              <a href="#" class="ico-chat dialer-icon red-icon">
                <b-icon icon="x" variant="danger" />
              </a>
            </li> -->
            <!-- ------- -->
          </ul>
          <!-- ------------ -->
          <!-- input div -->
          <div :class="`dialer-textArea d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center px-2 ${channelData.actions.reply_post.message? 'QuotedText-present':''}`">
            <div class="file_uploading_msg" v-if="is_submitted && !isEmpty(filesUploadings)">Uploading in process</div>
            <!-- reply -->
            <div v-if="!isEmpty(channelData.actions.reply_post)" class="d-flex justify-content-between w-100 pb-2 mb-2 border-bottom border-secondary QuotedText-container">
              <p class="pl-2 mb-1 d-flex flex-column align-items-start">
                <img :src="commaIcon" alt />
                <span class="pl-3 QuotedText">{{ channelData.actions.reply_post.message }}</span>
                <small class="dialer-user-msg-time ml-2" style="margin: 0">{{ channelData.actions.reply_post.create_at | time }}</small>
              </p>
              <b-icon class="ml-3 cursor_pointer" icon="x-circle" @click="$store.state.mattermost.channels[channel_id].actions.reply_post = {}" />
            </div>
            <!-- ----- -->
            <!-- input -->
            
            <b-form @submit.prevent="submit" class="w-100">
              <!-- <div v-if="channelData.actions.is_important" class="important_message danger">
                <small class="font-weight-bold">IMPORTANT!</small>
              </div> -->
              
                <!-- :class="{'important_message':channelData.actions.is_important}" -->
              <textarea
                style="height:auto"
                class="dialer-textArea w-100"
                v-model="$store.state.mattermost.channels[channel_id].actions.message"
                placeholder="Write your message"
                :id="`${channel_id}-text-area`"
                @keypress="onKeyPress"
                @keydown="onKeyDown"
                @paste="onPaste"
                @input="onInput"
              ></textarea>
              
            </b-form>
            <!-- ------- -->
          </div>
          <!-- --------- -->
          <!-- right actions list -->
          <ul class="list-unstyled mb-0">
            <!-- Close -->
            <li
              class="d-inline-block px-1 "
              v-if="!isEmpty(channelData.actions.edit_post)"
              @click="
                $store.state.mattermost.channels[channel_id].actions.edit_post =
                  {};
                $store.state.mattermost.channels[channel_id].actions.message = '';
                $store.state.mattermost.channels[
                  channel_id
                ].actions.is_important = false;
              "
            >
              <a href="#" class="ico-chat dialer-icon red-icon">
                <b-icon icon="x" variant="danger" class="newChatdesignIcon-close" />
              </a>
            </li>
            <!-- ------- -->
            <template v-else>
              <!-- Important -->
              <li class="d-inline-block px-1">
                <a v-b-tooltip.hover title="Important message" @click="$store.state.mattermost.channels[channel_id].actions.is_important = !channelData.actions.is_important">
                  <vb-icon icon="chat-importantIcon-v2" class="chat-importantIcon-v2" width="16" height="26" />
                </a>
              </li>
              <!-- Audio recording -->
              <li
                v-if="permissions.mic!='denied'"
                :class="
                  !recorded.audio.is_show
                    ? 'd-inline-flex px-1 justify-content-between align-items-center audio-record-area'
                    : 'd-inline-flex px-1 justify-content-between align-items-end audio-record-area open'
                "
              >
                <a
                  v-if="!recorded.audio.is_show"
                  v-b-tooltip.hover
                  title="Record audio message"
                  class="dialer-icon"
                  @click="
                    recorded.audio.is_show = true;
                    recorded.audio.is_start = true;
                  "
                >
                  <vb-icon icon="chat-micLineIcon" class="chat-micLineIcon" width="48" height="48" />
                </a>
                <template v-else>
                  <a
                    class="dialer-icon"
                    @click="
                      recorded.audio.is_cancelled = true;
                      recorded.audio.is_start = false;
                    "
                  >
                    <b-icon icon="x" variant="danger" font-scale="1.5"/>
                  </a>
                  <div class="recording-timer">
                    <div class="recording-timer-inner">
                      <b-icon
                        icon="circle-fill"
                        variant="danger"
                        animation="throb"
                        scale="0.8" font-scale="1.5"
                      ></b-icon>
                      <span class="timer">{{
                        recorded.audio.time | time_audio
                      }}</span>
                    </div>
                    <a
                      class="dialer-icon"
                      v-b-tooltip.hover
                      title="send audio message"
                      @click="
                        recorded.audio.is_cancelled = false;
                        recorded.audio.is_start = false;
                      "
                    >
                      <b-icon icon="check" font-scale="1.5"/>
                    </a>
                  </div>
                </template>
              </li>
              <!-- -------- -->
              <!-- video recording -->
              <li class="d-inline-block pr-0 pl-2">
                <a
                  v-b-tooltip.hover
                  title="Record video message"
                  class="dialer-icon "
                  @click="$modal.show(`${random_id}_RecordedVideoModal`)"
                >
                  <vb-icon icon="chat-videoCameraLineIcon" class="chat-videoCameraLineIcon" width="48" height="48" />
                </a>
              </li>
              <!-- ------- -->
              <!-- File -->
              <li
                class="d-inline-block pr-1 pl-0"
                v-if="isEmpty(channelData.actions.edit_post)"
                @click="$refs.file_upload_in_chat.click()"
              >
                <a
                  v-b-tooltip.hover
                  title="Attach file"
                  href="#"
                  class="dialer-icon "
                >
                  <vb-icon icon="chat-paperclip" class="chat-paperclip" width="48" height="48" />
                </a>
              </li>
              <!-- ---------- -->
            </template>
          </ul>
          <!-- --------- -->
        </div>
        <!-- ------- -->
      </div>
      <!-- Submit -->
      <div class="chat-actions-sendIcon md-mar-left">
        <a
          v-b-tooltip.hover
          title="Send"
          class="submit-icon dialer-icon"
          @click="submit"
        >
          <vb-icon icon="chat-sendMsgIcon" width="66" height="66"></vb-icon>
        </a>
      </div>
    </template>
    <!-- ------- -->
  </div>
  <div v-else class="bill-1122-chat-action">
    <input type="file" @change="filesUpload($event.target.files)" ref="file_upload_in_chat" multiple style="display: none"/>
    <!-- input bar -->
    <div class="bill-1122-input-bar">
      <!-- user typing -->
      <div class="bill-1122-user-typing" v-if="typingMessage">{{ typingMessage }} is typing ...</div>
      <!-- uploading error -->
      <div class="bill-1122-uploading-error" v-if="is_file_uploading_error">Uploading in process</div>
      <!-- --------------- -->
      <div class="bill-1122-input-bar-main" :class="{'important': channelData.actions.is_important, 'uploading-error': is_file_uploading_error}">
        <!-- reply -->
        <div class="bill-1122-reply" v-if="!isEmpty(channelData.actions.reply_post)">
          <div class="bill-1122-reply-quote">
            <img :src="commaIcon" alt />
          </div>
          <div class="bill-1122-reply-message">
            {{ channelData.actions.reply_post.message }}
          </div>
          <div class="bill-1122-reply-close">
            <b-icon class="cursor_pointer" icon="x-circle" @click="$store.state.mattermost.channels[channel_id].actions.reply_post = {}" />
          </div>
        </div>
        <!-- ----------- -->
        <!-- emoji panel --> 
        <div class="bill-1122-emoji-panel" v-if="is_emoji_modal">
          <v-emoji-picker @select="$store.state.mattermost.channels[channel_id].actions.message = channelData.actions.message + $event.data" />
        </div>
        <!-- ----------- -->
        <!-- upload file -->
        <div class="bill-1122-files" v-if="filesLength>0">
          <File
            v-for="file in $store.state.mattermost.channels[channel_id].actions.files"
            :key="file.uid"
            :file="file"
            :channel_id="channel_id"
            :random_id="random_id"
          />
          <div class="bill-1122-file-add ml-2 cursor_pointer" @click="$refs.file_upload_in_chat.click()">
            <b-icon icon="plus" scale="3" />
          </div>
        </div>
        <!-- ----------- -->
        <div class="bill-1122-input-bar-sub-main">
          <!-- emoji -->
          <div class="bill-1122-emoji">
            <vb-icon 
              @click="is_emoji_modal = !is_emoji_modal" 
              width="48" 
              class="cursor_pointer" 
              height="48" 
              :icon="is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'" 
            />
          </div>
          <!-- ------ -->
          <!-- editor -->
          <div class="bill-1122-editor">
            <!-- mention -->
            <div class="bill-1122-mention" :id="`${channel_id}-mentions`" v-show="channelData.type !== 'user' && !isEmpty(afterSearchMembers)">
              <div 
                class="bill-1122-mention-item" 
                v-for="(user, index) in afterSearchMembers" 
                :key="user.user.user_chat_id" 
                @click="selectUser(user)" 
                @mouseover="selectedUserIndex = index" 
                :class="{'hoverd':selectedUserIndex == index}"
              >
                <Info :id="user.user.user_chat_id" />
              </div>
            </div>
            <!-- ----------- -->
            <!-- input -->
            <div class="bill-1122-input">
              <b-form @submit.prevent="submit">
                  <!-- :rows="channelData.actions.message | filterRow(`${channel_id}-text-area`)" -->
                <textarea
                  v-model="$store.state.mattermost.channels[channel_id].actions.message"
                  placeholder="Write your message"
                  :id="`${channel_id}-text-area`"
                  @keypress="onKeyPress"
                  @keydown="onKeyDown"
                  @paste="onPaste"
                  @input="onInput"
                />
                  <!-- @keyup="onKeyUp" -->
              </b-form>
            </div>
            <!-- ----------- -->
          </div>
          <!-- ------- -->
          <!-- actions -->
          <div class="bill-1122-actions">
            <div>
              <!-- Close -->
              <div 
                v-if="!isEmpty(channelData.actions.edit_post)"
                class="bill-1122-close cursor_pointer" 
                @click="
                  $store.state.mattermost.channels[channel_id].actions.edit_post = {};
                  $store.state.mattermost.channels[channel_id].actions.message = '';
                  $store.state.mattermost.channels[channel_id].actions.is_important = false;
                "
              >
                <b-icon icon="x" scale="2" variant="danger" />
              </div>
              <!-- ------- -->
              <!-- recording -->
              <div v-else-if="recorded.audio.is_show" class="bill-1122-recording-process">
                <b-icon class="cursor_pointer" @click="recorded.audio.is_cancelled = true;recorded.audio.is_start = false;" icon="x" variant="danger" font-scale="1.5"/>
                <b-icon icon="circle-fill" variant="danger" animation="throb" scale="0.8" font-scale="1.5" />
                <span class="timer">{{ recorded.audio.time | time_audio }}</span>
                <b-icon class="cursor_pointer" @click="recorded.audio.is_cancelled = false;recorded.audio.is_start = false;" icon="check" variant="success" font-scale="1.5"/>
              </div>
              <!-- --------- -->
              <template v-else>
                <div class="plus" id="plus" onmouseenter="this.classList.add('plus--active')" onmouseleave="this.classList.remove('plus--active')">
                  <div class="plus__line plus__line--v">
                    <a href="#" class="plus__link" @click="$store.state.mattermost.channels[channel_id].actions.is_important = !channelData.actions.is_important">
                      <vb-icon icon="chat-importantIcon-v2" class="chat-importantIcon-v2" width="16" height="26" />
                    </a>
                    <a 
                      href="#" 
                      class="plus__link" 
                      v-if="permissions.mic!='denied'" 
                      :class="{ 'recording-process': !recorded.audio.is_show }" 
                      @click="recorded.audio.is_show = true;recorded.audio.is_start = true;" 
                    >
                      <vb-icon icon="chat-micLineIcon" width="48" height="48" />
                    </a>
                    <a href="#" @click="$modal.show(`${random_id}_RecordedVideoModal`)" class="plus__link">
                      <vb-icon icon="chat-videoCameraLineIcon" width="48" height="48" />
                    </a>
                    <a href="#" class="plus__link" v-if="isEmpty(channelData.actions.edit_post)" @click="$refs.file_upload_in_chat.click()">
                      <vb-icon icon="chat-paperclip" width="48" height="48" />
                    </a>
                  </div>
                  <!-- <div class="plus__line plus__line--h"></div> -->
                  <vb-icon icon="chat-videoCameraLineIcon" width="48" height="48" />
                </div>
                <!-- Important -->
                <!-- <div class="bill-1122-important cursor_pointer" v-b-tooltip.hover title="Important message" @click="$store.state.mattermost.channels[channel_id].actions.is_important = !channelData.actions.is_important">
                  <vb-icon icon="chat-importantIcon-v2" class="chat-importantIcon-v2" width="16" height="26" />
                </div> -->
                <!-- Audio recording -->
                <!-- <div class="bill-1122-recording" v-if="permissions.mic!='denied'" :class="{ 'recording-process': !recorded.audio.is_show }">
                  <vb-icon 
                    v-b-tooltip.hover 
                    title="Record a message" 
                    icon="chat-micLineIcon" 
                    width="48" 
                    height="48" 
                    class="cursor_pointer"
                    @click="recorded.audio.is_show = true;recorded.audio.is_start = true;" 
                  />
                </div> -->
                <!-- -------- -->
                <!-- video recording -->
                <!-- <div class="bill-1122-video-recording cursor_pointer" v-b-tooltip.hover title="Record a message" @click="$modal.show(`${random_id}_RecordedVideoModal`)">
                  <vb-icon icon="chat-videoCameraLineIcon" width="48" height="48" />
                </div> -->
                <!-- ------- -->
                <!-- File -->
                <!-- <div class="bill-1122-attach-file cursor_pointer" v-b-tooltip.hover title="Attach file" v-if="isEmpty(channelData.actions.edit_post)" @click="$refs.file_upload_in_chat.click()">
                  <vb-icon icon="chat-paperclip" width="48" height="48" />
                </div> -->
                <!-- ---------- -->
              </template>
            </div> 
          </div>
          <!-- ------- -->
        </div>
      </div>
    </div>
    <!-- -------- -->
    <!-- right icons -->
    <div class="bill-1122-right-icons">
      <!-- submit -->
      <div class="bill-1122-submit cursor_pointer" @click="submit()">
        <vb-icon icon="chat-sendMsgIcon" width="66" height="66" />
      </div>
      <!-- ------ -->
    </div>
    <!-- -------- -->
  </div>
</template>

<script>
import { LOGGER, events as app_events, $fn, permissions, } from "@/utils";
import _ from "lodash";
import { mattermost } from "@/MatterMost";
import { local_files } from "@/mixin";
import File from "./File.vue";
import Info from '@/components/Lists/Info.vue';
let audio_recording_interval_id;
let uploading_error_timeout_id;
export default {
  name: "Actions",
  mixins: [
    local_files
  ],
  components: {
    File,
    Info,
  },
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    channel_id: {
      type: String,
      default: "",
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    voipMembers: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      deault: "",
    },
    image: {
      type: String,
      deault: "",
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      update: false,
      selectedUserIndex: 0,
      editor: null,
      is_important: false,
      files: {},
      el: null,
      is_file_uploading_error: false,
      is_emoji_modal: false,
      is_submitted: false,
      commaIcon: require("@/assets/images/commas-black.svg"),
      userlist: [],
      matcheduser: [],
      is_mentions_drop_down: false,
      recorded: {
        audio: {
          is_show: false,
          is_start: false,
          time: 0,
          is_cancelled: false,
          recorder: null,
          audio: {},
        },
      },
      permissions: {},
    };
  },
  computed: {
    typingMessage(){
      const users = Object.values(this.channelData?.typing_users ?? {})
      if(users.length>2) return `${users.length} people`
      else if(users.length==1) return `${this.$store.getters.getVoipUsersAlisesDisplayName[users[0]]}`
      else if(users.length==2) return `${this.$store.getters.getVoipUsersAlisesDisplayName[users[0]]} and ${this.$store.getters.getVoipUsersAlisesDisplayName[users[1]]}`
      else return ''
    },
    fileIds() {
      return Object.values(this.channelData.actions.files)
        .map((file) => file.file_id)
        .filter((id) => id !== undefined);
    },
    filesUploadings() {
      return Object.values(this.channelData.actions.files).filter(
        (file) => file.progress.is_process
      );
    },
    afterSearchMembers() {
      if (this.filterSearch === null) return [];
      return this.voipMembers.filter((user) =>
        user.user.display_name.includes(this.filterSearch)
      );
    },
    filterSearch() {
      if (
        this.channelData.actions.message
          .toLocaleLowerCase()
          .lastIndexOf("@") === -1
      )
        return null;
      return this.channelData.actions.message.slice(
        this.channelData.actions.message.toLocaleLowerCase().lastIndexOf("@") +
          1
      );
    },
    filesLength() {
      return Object.values(this.channelData?.actions?.files ?? {}).length;
    },
  },
  watch: {
    afterSearchMembers(members) {
      if (!_.isEmpty(members)) return;
      this.selectedUserIndex = 0;
    },
    // "channelData.actions.message": {
    //   immediate: true,
    //   handler: function (msg) {
    //     if (this.el) {
    //       this.el[0].emojioneArea.setText(msg);
    //     }
    //     const regEx = /^[ ]*@[a-zA-Z0-9]*$/g;
    //     if (
    //       msg.slice(-2) === " @" ||
    //       msg.slice(msg.toLocaleLowerCase().lastIndexOf(" @")).match(regEx) ||
    //       msg.slice(msg.toLocaleLowerCase().lastIndexOf("@")).match(regEx)
    //     ) {
    //       this.is_mentions_drop_down = true;
    //     } else {
    //       this.is_mentions_drop_down = false;
    //     }
    //   },
    // },
    async "recorded.audio.is_start"(is_start) {
      let vm = this;
      if (is_start) {
        this.recorded.audio.recorder = await $fn.recordAudio();
        this.recorded.audio.time = 0;
        this.recorded.audio.recorder.start();
        audio_recording_interval_id = setInterval(async () => {
          if(vm.recorded.audio.time < 300){
            vm.recorded.audio.time = vm.recorded.audio.time + 1;
          } else {
            vm.recorded.audio.is_cancelled = false;
            vm.recorded.audio.is_start = false;
            const audio = await this.recorded.audio.recorder.stop();
            clearInterval(audio_recording_interval_id);
            if (!this.recorded.audio.is_cancelled) {
              this.uploadFile(audio, "recorded audio");
            }
            this.recorded.audio.is_show = false;
            setTimeout(()=>{
              vm.update=!vm.update
            },0.5*1000)
          }
        }, 1 * 1000);
      } else {
        const audio = await this.recorded.audio.recorder.stop();
        clearInterval(audio_recording_interval_id);
        if (!this.recorded.audio.is_cancelled) {
          this.uploadFile(audio, "recorded audio");
        }
        this.recorded.audio.is_show = false;
        setTimeout(()=>{
          vm.update=!vm.update
        },0.5*1000)
      }
    },
    "channelData.actions.edit_post"(edit_post) {
      if (!_.isEmpty(edit_post)) {
        const textarea = document.getElementById(`${this.channel_id}-text-area`);
        LOGGER.log({ textarea });
        textarea.focus();
      }
    },
    "channelData.actions.reply_post"(reply_post) {
      LOGGER.log(!_.isEmpty(reply_post));
      if (!_.isEmpty(reply_post)) {
        document.getElementById(`${this.channel_id}-text-area`).focus();
      }
    },
  },
  methods: {
    onInput() {
      this.userTyping()
    },
    onKeyPress(Event) {
      let vm = this
      const { shiftKey, code, altKey, ctrlKey } = Event;
      if (code === "Enter" && !shiftKey && !altKey && !ctrlKey) {
        Event.preventDefault();
        if (!_.isEmpty(vm.afterSearchMembers)) {
          vm.selectUser(vm.afterSearchMembers[vm.selectedUserIndex]);
        } else {
          vm.submit();
        }
      }
    },
    onKeyDown(Event) {
      let vm = this
      const { shiftKey, code, altKey, ctrlKey } = Event;
      if (code === "ArrowUp" && !shiftKey && !altKey && !ctrlKey) {
        if (!_.isEmpty(vm.afterSearchMembers)) {
          Event.preventDefault();
          if (vm.selectedUserIndex === 0) {
            vm.selectedUserIndex = vm.afterSearchMembers.length - 1;
          } else {
            vm.selectedUserIndex = vm.selectedUserIndex - 1;
          }
          const mention_items = document.getElementById(
            `${vm.channel_id}-mentions-items`
          );
          const mention_item = mention_items.childNodes[vm.selectedUserIndex];
          mention_items.scrollTop =
            mention_item.offsetTop -
            mention_items.offsetHeight +
            mention_item.offsetHeight;
        }
      }
      if (code === "ArrowDown" && !shiftKey && !altKey && !ctrlKey) {
        if (!_.isEmpty(vm.afterSearchMembers)) {
          Event.preventDefault();
          if (vm.selectedUserIndex === vm.afterSearchMembers.length - 1) {
            vm.selectedUserIndex = 0;
          } else {
            vm.selectedUserIndex = vm.selectedUserIndex + 1;
          }
          const mention_items = document.getElementById(
            `${vm.channel_id}-mentions-items`
          );
          const mention_item = mention_items.childNodes[vm.selectedUserIndex];
          mention_items.scrollTop =
            mention_item.offsetTop -
            mention_items.offsetHeight +
            mention_item.offsetHeight;
        }
      }
      if (code === "KeyI" && !shiftKey && !altKey && ctrlKey) {
        Event.preventDefault();
        vm.$store.state.mattermost.channels[
          vm.channel_id
        ].actions.is_important = !vm.channelData.actions.is_important;
      }
      if (code === "Tab" && !shiftKey && !altKey && !ctrlKey) {
        Event.preventDefault();
        vm.is_emoji_modal = !vm.is_emoji_modal;
      }
      if (code === "KeyF" && !shiftKey && !altKey && ctrlKey) {
        Event.preventDefault();
        vm.$parent.$refs.header.toggleSearch();
      }
      if (code === "KeyF" && shiftKey && !altKey && ctrlKey) {
        Event.preventDefault();
        vm.$refs.file_upload_in_chat.click();
      }
      if (code === "Escape" && !shiftKey && !altKey && !ctrlKey) {
        if (!_.isEmpty(vm.channelData.actions.edit_post)) {
          Event.preventDefault();
          vm.$store.state.mattermost.channels[vm.channel_id].actions.edit_post =
            {};
          vm.$store.state.mattermost.channels[vm.channel_id].actions.message =
            "";
          vm.$store.state.mattermost.channels[
            vm.channel_id
          ].actions.is_important = false;
        } else if (!_.isEmpty(vm.channelData.actions.reply_post)) {
          Event.preventDefault();
          vm.$store.state.mattermost.channels[
            vm.channel_id
          ].actions.reply_post = {};
        }
      }
      if (code === "Slash" && !shiftKey && !altKey && ctrlKey) {
        this.$root.$emit(app_events.ctrl_backslash, Event);
      }
    },
    onPaste(pasteEvent,callback) {
      if(pasteEvent.clipboardData == false){
        if(typeof(callback) == "function"){
          // console.log('Undefined ')
          callback(undefined);
        }
      }
      var items = pasteEvent.clipboardData.items;
      if(items == undefined){
        if(typeof(callback) == "function"){
          callback(undefined);
          // console.log('Undefined 2')
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") == -1) continue;
        var blob = items[i].getAsFile();
        this.uploadFile(blob)
      }
    },
    selectUser(user) {
      this.$store.state.mattermost.channels[this.channel_id].actions.message =
        this.channelData.actions.message.slice(
          0,
          this.channelData.actions.message.toLocaleLowerCase().lastIndexOf("@")
        ) + `@${user.user.display_name} `;
    },
    tooManyFiles(){
      this.$appConfirmation({
        title: "Too many files selected",
        message: `You have picked too many files. Limit is 5`,
        variant: 'danger',
        button: {
          yes: "OK",
        },
        ok: true,
      });
    },
    filetoolarge(){
      this.$appConfirmation({
        title: "file too large",
        message: `You have picked large file`,
        variant: 'danger',
        button: {
          yes: "OK",
        },
        ok: true,
      });
    },
    dimensionstoolarge(){
      this.$appConfirmation({
        title: "dimensions too large",
        message: `Please Pick Images With Dimension 5000 X 4320.`,
        variant: 'danger',
        button: {
          yes: "OK",
        },
        ok: true,
      });
    },
    async filesUpload(files) {
      let vm = this
      const files_length = Object.values(this.channelData?.actions?.files ?? {}).length
      const added_files_length = files.length
      if ((files_length+added_files_length) > 5) {
        this.tooManyFiles()
        return;
      }
      let promises = []
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        promises.push(new Promise((res,rej)=>{
          if(file?.size > 100000000){
            rej(new Error('toolarge'))
          } else if(file?.type?.split("/")[0] === "image") {
            $fn.getImageDimensions(file)
            .then(({ width, height })=>{
              if(width<5000 && height<4320) res(file)
              else rej(new Error('dimension'))
            })
            .catch(()=>{
              rej(new Error('dimension'))
            })
          } else {
            res(file)
          }
        }))
      }
      Promise.all(promises).then((file_list)=>{
        for (let x = 0; x < file_list.length; x++) {
          vm.uploadFile(file_list[x]);
        }
      }).catch((ex)=>{
        if(ex.message=='toolarge') {
          vm.filetoolarge()
        } else if(ex.message=='dimension') {
          vm.dimensionstoolarge()
        } else {
          vm.appNotify({
            message: ex.message,
            type: 'danger',
          })
        }
      }).finally(()=>{
        vm.$refs.file_upload_in_chat.value=''
      })
    },
    uploadFile(file, type = "file") {
      if (Object.values(this.channelData?.actions?.files ?? {}).length >= 5) {
        this.tooManyFiles()
        return;
      }
      this.$root.$emit(app_events.uploading_file_on_channel, {
        channel_id: this.channel_id,
        file,
        type,
      });
    },
    async submit() {
      let vm = this;
      try {
        // const message = this.el[0].emojioneArea.getText();
        const message = this.channelData.actions.message;
        if (!_.isEmpty(this.filesUploadings)) throw new Error("File is on uploading");
        if (((!message.trim() || !message) && _.isEmpty(this.fileIds))) return;
        this.is_submitted = true;
        var pending_post_id = $fn.makeid(15);
        if (!_.isEmpty(this.channelData.actions.edit_post)) {
          if (this.channelData.actions.edit_post.message === message) return;
          const edit_post = this.channelData.actions.edit_post
          this.$set(this.$store.state.mattermost.channels[this.channel_id].posts.posts,edit_post.id,{
            ...edit_post,
            message: message,
            pending_post_id: pending_post_id,
            edit: true,
            props: {
              ...edit_post.props,
              isEdited: true,
              is_important: this.channelData.actions.is_important,
            },
          })
          this.$store.state.mattermost.channels[this.channel_id].actions.edit_post = {};
        } else {
          this.$store.state.mattermost.channels[this.channel_id].posts.order.push(pending_post_id)
          this.$set(this.$store.state.mattermost.channels[this.channel_id].posts.posts,pending_post_id,{
            channel_id: this.channel_id,
            create_at: new Number(new Date()),
            delete_at:0,
            edit_at:0,
            hashtags:"",
            id: pending_post_id,
            is_pinned: false,
            message: message,
            metadata: {
              files: Object.values(this.channelData.actions.uploaded_files)
            },
            original_id: "",
            parent_id: "",
            pending_post_id:pending_post_id,
            props: {
              isEdited: false,
              reply_post_id: this.channelData.actions.reply_post.id || "",
              is_important: this.channelData.actions.is_important,
              is_forwarded: false,
            },
            reply_count: 0,
            root_id: "",
            type: "",
            update_at: new Number(new Date()),
            user_id: this.$store.getters.getMattermostCurrentUser?.id
          })
          // await MATTERMOST_API.endpoints.posts.createPosts({
          //   channel_id: this.channel_id,
          //   message: message,
          //   file_ids: this.fileIds,
          //   props: {
          //     // message_html: document.querySelector(
          //     //   "#textarea-chat-action-container .emojionearea .emojionearea-editor"
          //     // ).innerHTML,
          //     isEdited: false,
          //     reply_post_id: this.channelData.actions.reply_post.id || "",
          //     is_important: this.channelData.actions.is_important,
          //     is_forwarded: false,
          //   },
          //   parent_id: "",
          //   pending_post_id: pending_post_id,
          //   root_id: "",
          // });
          this.channelData.actions.reply_post = {};
        }
        this.$store.state.mattermost.channels[this.channel_id].actions.is_important = false;
        this.$store.state.mattermost.channels[this.channel_id].actions.message = "";
        const textarea = document.getElementById(`${this.channel_id}-text-area`)
        LOGGER.log('textarea',{textarea})
        textarea.rows=1
        // document.querySelector(
        //   "#textarea-chat-action-container .emojionearea .emojionearea-editor"
        // ).innerHTML = "";
        this.$store.state.mattermost.channels[this.channel_id].actions.files = {};
        this.$store.state.mattermost.channels[this.channel_id].actions.uploaded_files = {};
      } catch (error) {
        LOGGER.danger("error in submit the function", error);
        const { message } = error;
        if (message === "File is on uploading") {
          this.is_file_uploading_error = true;
          uploading_error_timeout_id = setTimeout(() => {
            vm.is_file_uploading_error = false;
          }, 3 * 1000);
        }
      } finally {
        this.is_submitted = false;
      }
    },
    userTyping() {
      mattermost.actions.usertyping(this.channel.id);
    },
    checkImageDimensions(file){
      const image = new Image();
      image.src = URL.createObjectURL(file);
      return new Promise((res, rej) => {
        image.onload = function () {
          const maxWidth = 5000; // Set your maximum width
          const maxHeight = 4320; // Set your maximum height
          if (image.width < maxWidth && image.height < maxHeight) {
            res(true)
          } else {
            res(false)
          }
        };
        image.onerror = function () {
          rej(false); // Image failed to load
        };
      })
    }
  },
  created() {
    this.permissions = permissions
  },
  updated() {
    const text_area_el = document.getElementById(`${this.channel_id}-text-area`)
    if(text_area_el){
      // text_area_el.style.height='30px';
      text_area_el.style.height='24px';
      text_area_el.style.height=`${text_area_el.scrollHeight}px`;
      const mentions_el = document.getElementById(`${this.channel_id}-mentions`)
      if(mentions_el) {
        mentions_el.style.bottom=`${text_area_el.style.height}`
      }
    }
    // setTimeout(() => {
    //   document.getElementById(`${this.channel_id}-text-area`).focus()
    // }, 0);
  },
  destroyed() {
    clearTimeout(uploading_error_timeout_id);
  },
  mounted(){
    // setTimeout(() => {
    //   document.getElementById(`${this.channel_id}-text-area`).focus()
    // }, 0);
  }
};
</script>

<style lang="scss">

.bill-1122-chat-action {
  display: flex;
  .bill-1122-input-bar {
    flex: 1;
    width: calc(100% - 75px);
    .bill-1122-user-typing {
      color: white;
    }
    .bill-1122-uploading-error {
      color: red;
    }
    .bill-1122-input-bar-main {
      display: flex;
      flex-direction: column;
      background-color: #191919;
      width: 100%;
      padding: 10px;
      border-radius: 25px;
      &.important {
        background-color: rgba(255, 0, 0, 0.4);
      }
      &.uploading-error {
        background-color: rgba(255, 0, 0, 1);
        animation: blinkingBackground 0.5s infinite;
      }
      .bill-1122-reply {
        flex: 1;
        display: flex;
        width: 100%;
        max-height: 100px;
        overflow-y: hidden;
        line-break: auto;
        border-bottom: #eeeeee 2px solid;
        margin-bottom: 5px;
        .bill-1122-reply-quote {
          width: 30px;
        }
        .bill-1122-reply-message {
          color: white;
          flex: 1;
          line-break: anywhere;
        }
        .bill-1122-reply-close {
          width: 30px;
          color: white;
          display: flex;
          align-items: flex-start;
        }
      }
      .bill-1122-emoji-panel {
        margin-bottom: 10px;
        border-bottom: white 1px solid;
        .emoji-picker {
          width: 100%;
          height: 200px;
          background-color: transparent;
          border: 0;
          min-height: 150px;
          #Categories {
            border: 0px;
            background-color: #2f2d30;
            .category {
              &.active {
                border-color: $primary;
              }
            }
          }
          #InputSearch {
            .container-search {
              padding: 0px;
              input {
                background-color: #2f2d30;
                color: white;
                border: 0;
              }
            }
          }
          #Emojis {
            height: 120px;
            min-height: 120px;
            .container-emoji {
              container-type: inline-size;
              container-name: test1;
              min-height: inherit;
              height: inherit;
              .grid-emojis {
                grid-template-columns: repeat(20, 5%) !important;
                .emoji {
                  &.border {
                    border: 0px !important;
                  }
                }
              }
              @container test1 (max-width: 600px) {
                .grid-emojis {
                  grid-template-columns: repeat(17, 5.88%) !important;
                }
              }
              @container test1 (max-width: 550px) {
                .grid-emojis {
                  grid-template-columns: repeat(16, 6.25%) !important;
                }
              }
              @container test1 (max-width: 500px) {
                .grid-emojis {
                  grid-template-columns: repeat(15, 6.66%) !important;
                }
              }
              @container test1 (max-width: 450px) {
                .grid-emojis {
                  grid-template-columns: repeat(10, 10%) !important;
                }
              }
              @container test1 (max-width: 350px) {
                .grid-emojis {
                  grid-template-columns: repeat(9, 11.11%) !important;
                }
              }
              @container test1 (max-width: 300px) {
                .grid-emojis {
                  grid-template-columns: repeat(8, 12.5%) !important;
                }
              }
              @container test1 (max-width: 250px) {
                .grid-emojis {
                  grid-template-columns: repeat(7, 14.28%) !important;
                }
              }
              @container test1 (max-width: 200px) {
                .grid-emojis {
                  grid-template-columns: repeat(5, 20%) !important;
                }
              }
              @container test1 (max-width: 150px) {
                .grid-emojis {
                  grid-template-columns: repeat(4, 25%) !important;
                }
              }
              @container test1 (max-width: 125px) {
                .grid-emojis {
                  grid-template-columns: repeat(3, 33.33%) !important;
                }
              }
              @container test1 (max-width: 100px) {
                .grid-emojis {
                  grid-template-columns: repeat(2, 50%) !important;
                }
              }
              @container test1 (max-width: 65px) {
                .grid-emojis {
                  grid-template-columns: repeat(1, 100%) !important;
                }
              }
            }
          }
        }
      }
      .bill-1122-files {
        display: flex;
        background-color: transparent;
        overflow-y: auto;
        margin-bottom: 10px;
        border-bottom: white 1px solid;
        > div {
          margin-top: 0px !important;
        }
        .bill-1122-file-add {
          height: 130px;
          background-color: #2f2d30;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 50px;
          border-radius: 15px;
          color: white;
        }
      }
      .bill-1122-input-bar-sub-main {
        display: flex;
        width: 100%;
        position: relative;
        .bill-1122-emoji {
          display: flex;
          width: 50px;
          align-items: flex-end;
          overflow: visible;
          svg {
            path {
              &:nth-child(1){
                fill: #2f2d30;
              }
              &:nth-child(2){
                fill: white;
              }
            }
          }
        }
        .bill-1122-editor {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: 5px;
          margin-right: 5px;
          .bill-1122-mention {
            height: auto;
            width: max-content;
            background-color: #191919;
            padding: 10px;
            border-radius: 15px;
            position: absolute;
            bottom: 0;
            .bill-1122-mention-item {
              margin-bottom: 5px;
              border-radius: 15px;
              padding: 10px;
              &.hoverd {
                background-color: #2f2d30;
              }
            }
          }
          .bill-1122-input {
            width: 100%;
            // margin-bottom: 10px;
            form {
              textarea {
                width: 100%;
                background-color: transparent;
                border: none;
                color: white;
                resize: none;
                height: 30px;
                max-height: 180px;
                // font-size: 22px;
                &:focus-visible {
                  outline: none;
                }
                // &::placeholder, &::-webkit-input-placeholder, &:-moz-placeholder, &::-moz-placeholder, &:-ms-input-placeholder {
                //   color: red !important;
                //   text-align: center !important;
                // }
                &::placeholder {
                  color: white;
                }
                &::-webkit-input-placeholder {
                  color: white;
                }
                &:-moz-placeholder {
                  color: white;
                }
                &::-moz-placeholder {
                  color: white;
                }
                &:-ms-input-placeholder {
                  color: white;
                }
              }
            }
          }
        }
        .bill-1122-actions {
          width: max-content;
          display: flex;
          align-items: flex-end;
          > div {
            display: flex;
            align-items: center;
            height: max-content;
            .plus {
              width: 50px;
              cursor:pointer;
              transition: all .3s ease 0s;
              height: 50px;
              background: #5576d1;
              border-radius: 50%;
              display: flex;
              position: relative;
              color: white;
              &__line {
                width: 0px;
                height: 15px;
                background: #232124;
                border-radius: 10px;
                position: absolute;
                left: calc(50% - 1.5px);
                top: calc(50% - 7.5px);
                &--h {
                  transform: rotate(90deg);
                } 
                &--v {
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  overflow: hidden;
                  transition: all .4s ease 0s;
                }
              }
              &__link {
                color: #fff;
                font-size: 30px;
                opacity: 0;
                visibility: hidden;
                transition : .3s ease 0s;
                transform:scale(.5);
              }
              &--active {
                // height:32px;
                // border-radius:30px;
                .plus__line--v {
                  height: 68px;
                  top: calc(-100% - 15px);
                  padding:0 5px;
                  box-sizing:border-box;
                  width: 220px; 
                  border-radius: 15px;
                  left: calc(50% - 110px);
                }
                .plus__link{
                  opacity:1;
                  visibility:visible;
                  transform:scale(1);
                  transition-delay: .05s;
                }
              }
            }
            .bill-1122-recording-process {
              display: flex;
              align-items: center;
              color: white;
            }
            .bill-1122-close {
  
            }
            .bill-1122-important {
  
            }
            .bill-1122-recording {
              &.recording-process {
  
              }
            }
            .bill-1122-video-recording {
  
            }
            .bill-1122-attach-file {
  
            }
          }
        }
      }
    }
  }
  .bill-1122-right-icons {
    width: 75px;
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
    .bill-1122-submit {
      svg {
        path {
          &:nth-child(1){
            fill: #191919;
          }
          &:nth-child(2){
            fill: $primary;
          }
        }
      }
    }
  }
}

.dialer-tags {
  ul {
    li {
      a .active {
        background-color: #eeeeee;
      }
    }
  }
}
.emojioneemoji {
  height: 20px;
}
@keyframes blinkingBackground{
  0%, 25% { 
    background-color: rgba(255, 0, 0, 0.25);
  }
  25%, 50% { 
    background-color: rgba(255, 0, 0, 0.4);
  }
  50%, 75% { 
    background-color: rgba(255, 0, 0, 0.25);
  }
  75%, 100% { 
    background-color: rgba(255, 0, 0, 0.4);
  }
}
</style>